import "../../assets/css/clientsAdminView/userControl.css"

import close from "../../assets/images/close.svg"
import trashCan from "../../assets/images/delete.svg"

import { loadingModal } from "../userConfig/utils.js"

import { Modal, Tab, Tabs } from "react-bootstrap";
import { useEffect, useMemo, useState } from "react";
import { checkTheEmail } from "../../utilities/validateEmail";
import Swal from "sweetalert2";
import Axios from "axios"

const {
	REACT_APP_URI_ALL_USERS: URI_USERS,
	REACT_APP_URI_USERS_FILTER_BY_DATA_ID: URI_USERS_BY_DATA_ID,
	REACT_APP_URI_RESET_PASSWORD: URI_RESET_PASSWORD,
} = process.env

function UserRow(props) {
	const {
		user,
		setUsers,
		clientId,
		showDeleteButton,
		setWaitingForMailCheck
	} = props

	const handleNewUsername = (userId, newUsername) => {
		setUsers(prev => {
			return prev.map(prevUser => {
				if (prevUser._id === userId) {
					return { ...prevUser, newUsername}
				}

				return prevUser
			})
		})
	}
	
	const handleResetPassword = (username) => {
		Swal.fire({
			title: "Are you sure you want to reset this password?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			reverseButtons: true,
			confirmButtonText: "Reset password",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					footer: 'Loading, please wait',
					showConfirmButton: false,
					allowOutsideClick: false,
					allowEscapeKey: false,
					didOpen: () => {
						Swal.showLoading()
					},
				})

				Axios.put(URI_RESET_PASSWORD, {
					email: username
				}).then(response => {
					if (response.data.founded) {
						Swal.fire({
							icon: 'success',
							text: 'Password reseted successfully',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						})
					} else {
						Swal.fire({
							icon: 'error',
							text: 'Email does not exist',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						})
					}
				}).catch(err => {
					Swal.fire({
						icon: 'error',
						text: 'Failed to reset the password',
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					})
					console.log("Error al resetear la contraseña del cliente: " + err)
				})
			}
		});
	}
	
	const handleEmailCheck = async (email, userId) => {
		if (!email) return setWaitingForMailCheck(false)
		
		try {
			const res = await checkTheEmail(email)

			if (!res) {
				handleNewUsername(userId, "")
			}

			setWaitingForMailCheck(false)
		} catch (error) {
			console.log("Error checking email", error)
			
			handleNewUsername(userId, "")
			setWaitingForMailCheck(false)
		}
	}

	const handleRemoveUser = async (userId, profileId) => {
		Swal.fire({
			title: "Are you sure you want to remove this user?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			reverseButtons: true,
			confirmButtonText: "Delete user",
		}).then((result) => {
			if (result.isConfirmed) {
				loadingModal()

				const deleteProfile = true

				Axios.patch(URI_USERS + userId, { profileId, deleteProfile }).then(_res => {
					setUsers(prev => prev.filter(prevUser => prevUser._id !== userId))
					
					Swal.fire({
						icon: 'success',
						text: 'User deleted successfully',
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					})
				}).catch(err => {
					console.log(err)

					Swal.fire({
						icon: 'error',
						text: err.response?.data?.message || "Failed to delete user",
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					})
				})
			}
		});
	}

	const profile = useMemo(() => user.profiles?.find(profile => profile.dataID === clientId), [user, clientId])
	
	return (
		<div className="tableRow userRow">
			<span id="userUsername">{user.username}</span>

			<span id="userRole">{profile.rol}</span>
			
			<div className="inputContainer">
				<input 
					type="text" 
					placeholder="New username" 
					value={user?.newUsername ?? ""}
					onChange={(e) => handleNewUsername(user._id, e.target.value)}
					onFocus={() => setWaitingForMailCheck(true)}
					onBlur={() => handleEmailCheck(user.newUsername, user._id)}
				/>
			</div>

			<button className="btnBgBlueTextGradient" onClick={() => handleResetPassword(user.username)}>Reset password</button>
			
			{showDeleteButton && <img src={trashCan} alt="Supplier Prodensa" onClick={() => handleRemoveUser(user._id, profile._id)}/>}
		</div>
	)
}

function TableUsers(props) {
	const {
		clientId,
		clientName,
		users,
		setUsers,
		setWaitingForMailCheck
	} = props

	const usersToShow = useMemo(() => users.filter(user => user.profiles.some(profile => profile.dataID === clientId)), [users, clientId])
	const moreThanOneAdmin = useMemo(() => usersToShow.filter(user => user.profiles.some(profile => profile.rol === "Admin client"))?.length > 1, [usersToShow])
	
	return (
		<div className="usersTable">
			<h4 className="clientNameHeader">{clientName}</h4>

			<div className="tableTitles tableRow">
				<span>Actual username</span>
				<span>Role</span>
				<span>New username</span>
				<span>Reset password</span>
			</div>

			{usersToShow.length === 0 && 
				<div className="noUsers">
					This entity has no registered users
				</div>
			}

			{usersToShow.map(user => {
				const profile = user.profiles?.find(profile => profile.dataID === clientId)
				const showDeleteButton = profile?.rol !== "Admin client" || moreThanOneAdmin
				
				return (
					<UserRow 
						key={user._id}
						user={user}
						setUsers={setUsers}
						clientId={clientId}
						showDeleteButton={showDeleteButton}
						setWaitingForMailCheck={setWaitingForMailCheck}
					/>
				)
			})}
		</div>
	)
}

export default function UsersControlModal(props) {
	const {
		show,
		onHide,
		client,
		darkLightMode
	} = props

	const [key, setKey] = useState("client")
	
	// VARIABLE TO MANAGE THE USERS OF THE CLIENT
	const [users, setUsers] = useState([])
	const [waitingForMailCheck, setWaitingForMailCheck] = useState(false)
	
	const idsToFind = useMemo(() => [client?._id, ...client?.entities?.map(entity => entity._id)].filter(id => id != null), [client])
	const clientName = useMemo(() => client?.generalInformation?.clientName, [client])
	const isNewUsername = useMemo(() => users.some(user => user.newUsername), [users])


	useEffect(() => {
		if (!show) return

		getClientUsers()
	}, [show])

	const handleCloseModal = () => {
		onHide()

		setKey("client")
		setUsers(prev => {
			return prev.map(prevUser => {
				return { ...prevUser, newUsername: ""}
			})
		})
	}

	// FUNCTION TO GET THE CLIENT USERNAMES
	const getClientUsers = async () => {
		try {
			const { data } = await Axios.post(URI_USERS_BY_DATA_ID, { idsToFind: idsToFind })
	
			if (data) {
				setUsers(data)
			}
		} catch (error) {
			console.log(error)
		}
	}

	const handleSaveUsers = () => {
		if (!isNewUsername) return

		Axios.put(URI_USERS, {
			users,
			idsToFind
		}).then(res => {
			handleCloseModal()

			setUsers(res.data?.users || [])

			Swal.fire({
				icon: 'success',
				text: 'Users saved successfully',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
		}).catch(err => {
			console.log(err)

			Swal.fire({
				icon: 'error',
				text: err.response?.data?.message || "Failed to update users",
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
		})
	}

	return (
		<Modal
			show={show}
			onHide={handleCloseModal}
			size="xl"
			centered
			className={`usersControlModal ${darkLightMode ? "darkMode" : ""}`}>

			<img className="closeModal" src={close} onClick={handleCloseModal} alt="Prodensa Supplier" />

			<Modal.Title>
				<h3 className="modalTitle">Client usernames</h3>
				<span>A list of all the usernames belonging to a a specific client</span>
			</Modal.Title>

			<Modal.Body>
				<Tabs
					activeKey={key}
					onSelect={(k) => setKey(k)}
				>
					<Tab
						eventKey={"client"}
						title={`Client users`}
						className="clientTab"
					>
						<TableUsers 
							clientId={client._id}
							clientName={clientName}
							users={users}
							setUsers={setUsers}
							setWaitingForMailCheck={setWaitingForMailCheck}
						/>
					</Tab>

					<Tab
						eventKey={"entity"}
						title={`Entity users`}
					>
						<div className="entitiesTablesSection">
							{client.entities.map(entity => {
								return (
									<TableUsers 
										key={entity._id}
										clientId={entity._id}
										clientName={entity.entityName}
										users={users}
										setUsers={setUsers}
										setWaitingForMailCheck={setWaitingForMailCheck}
									/>
								)
							})}
						</div>
					</Tab>
				</Tabs>
				<div className="actions">
					<button className="btnBgWhiteTextBlack" onClick={handleCloseModal}>Cancel</button>
					<button className="btnBgBlueTextGradient" onClick={handleSaveUsers} disabled={!isNewUsername || waitingForMailCheck}>Save users</button>
				</div>
			</Modal.Body>
		</Modal>
	)
}