import { useNavigate } from "react-router-dom"
import moment from "moment"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

import sortIcon from "../../../assets/images/sort.svg"
import dots from "../../../assets/images/moreDots.svg"

import { PROPERTIES, ACTIONS, getRouteForAlert } from "./utils.js"

export default function ActionNeededTable(props) {
    const {
        state,
        dispatch,
        handleActionCompleted,
        handleMoreOptions,
        darkLightMode
    } = props

    // VARIABLE TO MANAGE THE NAVIGATE TO OTHER COMPONENTS
    const navigate = useNavigate()

    const mainStyle = {
		background: darkLightMode ? "#131E29" : "white",
		color: darkLightMode ? "white" : "inherit"
	}

    // FUNCTION TO SORT TABLE COLUMN
    function handleSort(sortBy) {
        dispatch({ type: ACTIONS.handleSort, payload: sortBy })
    }
    
    let indexOfLastItem = state?.currentPage * state.itemsPerPage;
    let indexOfFirstItem = indexOfLastItem - state.itemsPerPage;

    const handleTypeOfActionElement = (alert) => {
        const property = alert.typeOfAction?.includes("Document") ? "documentName" : "clientName"

        return alert?.[property] ? 
        <OverlayTrigger placement="top"
            overlay={<Tooltip id="validatedTooltip">
                {alert?.[property] ?? "Document name not found"}
            </Tooltip>}>
            <span>{alert.typeOfAction}</span>
        </OverlayTrigger>
        :
        <span>{alert.typeOfAction}</span>
    }

    const handleSupplierClick = (alert) => {
        const routeForAlert = getRouteForAlert(alert)

        navigate(routeForAlert)
    }

    return (
        <table className="tableActionNeeded">
            <thead>
                <tr>
                    <th style={mainStyle}>
                        <span>Name</span>
                        <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => handleSort(PROPERTIES.supplier)} />
                    </th>
                    <th style={mainStyle}>
                        <span>Type of action</span>
                        <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => handleSort(PROPERTIES.typeOfAlert)} />
                    </th>
                    <th style={mainStyle}>
                        <span>Created At</span>
                        <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => handleSort(PROPERTIES.createdAt)} />
                    </th>
                    <th style={mainStyle}>
                        <span>Completed By</span>
                        <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => handleSort(PROPERTIES.completedBy)} />
                    </th>
                </tr>
            </thead>

            <tbody>
                {state?.alerts?.slice(indexOfFirstItem, indexOfLastItem).map((supplier, index) => {
                    const isOptionsOpen = state?.moreOptionsOpen === supplier._id
                    const actionLabel = supplier.completedBy ? "Mark as pending" : "Mark as completed"
                    
                    return (
                        <tr key={index}>
                            <td>
                                <div className="infoContainer">
                                    <span onClick={() => handleSupplierClick(supplier)}>
                                        {supplier.companyName}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    {handleTypeOfActionElement(supplier)}
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{moment(supplier.createdAt).format("DD MMMM YYYY")}</span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <div className="completByColumnContainer">
                                        <span>{supplier.completedBy ?? "Not completed yet"}</span>
                                        {supplier.completedBy && <span style={{ opacity: "0.25" }}>{moment(supplier.updatedAt).format("DD MMMM YYYY hh:mm A")}</span>}
                                    </div>
                                    
                                    <div className="actionsContainer">
                                        <img className="alerts-more-dots" src={dots} alt="Prodensa Supplier" onClick={() => handleMoreOptions(supplier)}/>
                                        
                                        <div className={`optionsContainer ${isOptionsOpen ? "visible" : null}`}>
                                            <span onClick={() => handleSupplierClick(supplier)}>View action</span>
                                            <span onClick={() => handleActionCompleted(supplier)}>{actionLabel}</span>
                                        </div>
                                    </div>
                                    
                                </div>
                            </td>
                        </tr>
                    )
                })} 
            </tbody>
        </table>
    )
}
