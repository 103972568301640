import { useCookies } from "react-cookie";
import { createContext, useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

const AuthContext = createContext();

function AuthProvider(props) {
    const [user, setUser] = useState({
            userProfiles: null,
            selectedProfile: null,
            tempUser: null
        })
    const [cookies] = useCookies(['token']);
    const navigate = useNavigate()

    const url = useLocation();
    const urlPathname = url.pathname;

    useEffect(() => {
        if (!cookies.token && urlPathname.includes("/home")) {
            updateUser({
                userProfiles: null,
                selectedProfile: null,
                tempUser: null
            })
            navigate("/login")
        }
    }, [cookies])

    function updateUser(values) {
        setUser(prev => {
            return {
                ...prev,
                ...values
            }
        });
    }
    
    const value = {
        user,
        updateUser
    }

    return (
        <AuthContext.Provider value={value} {...props} />
    );
}

function useAuth() {
    return useContext(AuthContext);
}

export { AuthProvider, useAuth };