import "../assets/css/pages/home.css"

import profileExample from "../assets/images/profileExample.png"
import logo from "../assets/images/logo.png"
import menuBlue from "../assets/images/menuBlue.svg"

import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, Outlet, useNavigate } from "react-router-dom";
import NavigationMenu from "../components/navigationMenu/navigationMenu.jsx";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useAuth } from "../context/auth-user.jsx";
import Swal from "sweetalert2"

const {
	REACT_APP_URI_SUPPLIERS: URI_SUPPLIER,
	REACT_APP_URI_CLIENT: URI_CLIENT,
	REACT_APP_URI_AUTHENTIFICATE_WITH_TOKEN: URI_USER_TOKEN
} = process.env


export default function Home({ mainStyle }) {
	const [cookie, _setCookie, removeCookie] = useCookies(["token"])

	
	const [supplier, setSupplier] = useState(null)
	const [client, setClient] = useState(null)
	
	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const [showUserOption, setShowUserOption] = useState(false);
	const userOptionRef = useRef();
	const navigate = useNavigate()
	const { user, updateUser } = useAuth()

	const userLogged = user?.selectedProfile
	
	useEffect(() => {
		// Apply styles to body
		document.body.style.backgroundColor = user?.selectedProfile?.darkLightMode ? "#202B34" : "#F5F6F7";
		
		// Cleanup on unmount or mode change
		return () => {
			document.body.style.backgroundColor = "";
		};
	}, [user]);
	
	useEffect(() => {
		if (userLogged) {
			if ((userLogged.rol === "Supplier" || userLogged.rol === "Product manager" || userLogged.rol === "Document manager")) {
				getSupplierByID(userLogged.dataID);
			} else {
				getClientByID(userLogged.dataID);
			}
		}

		document.body.addEventListener("click", closeUserOption)

		return function cleanup() {
			document.body.removeEventListener("click", closeUserOption)
		}
	}, [userLogged])

	useEffect(() => {
		const handleBeforeUnload = () => {
			if (!user.selectedProfile) return localStorage.removeItem("profile")

			localStorage.setItem("profile", JSON.stringify(user));
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [user]);

	useEffect(() => {
		if (cookie.token && !user.selectedProfile) {

			const switchProfile = JSON.parse(localStorage.getItem("switchProfile"))
			if (switchProfile) {
				updateUser({
					selectedProfile: switchProfile.selectedProfile,
					userProfiles: switchProfile.userProfiles
				})

				localStorage.removeItem("switchProfile")
				localStorage.removeItem("profile")

				return
			}
			
			const tempProfile = JSON.parse(localStorage.getItem("profile"))
			if (tempProfile) {
				updateUser({
					selectedProfile: tempProfile.selectedProfile,
					userProfiles: tempProfile.userProfiles
				})

				localStorage.removeItem("profile")

				return
			}

			Swal.fire({
				footer: 'Loading, please wait',
				showConfirmButton: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				didOpen: () => {
					Swal.showLoading()
				},
			})
			
			axios.post(URI_USER_TOKEN, { token: cookie.token }).then(res => {
				if (res.data.authentification) {
					if (res.data.profiles?.length > 1) {
						updateUser({
							tempUser: res.data
						})
	
						navigate("/login")
	
						return
					}
	
					const profile = res.data?.profiles?.[0]
					
					updateUser({
						selectedProfile: {
							...profile,
							darkLightMode: res.data.darkLightMode
						} 
					})
				} else {
					removeCookie("token")
				}

				Swal.close()
			}).catch(err => {
				console.log(err)
				removeCookie("token")
				Swal.close()
			})
		}
	}, [])

	const closeUserOption = (e) => {
		if (!userOptionRef?.current?.contains(e.target)) {
			setShowUserOption(false)
		}
	}

	const getSupplierByID = (id) => {
		axios.get(URI_SUPPLIER + id).then(response => {
			setSupplier(response.data)
		}).catch(err => {
			console.log("Error al obtener el supplier por id:" + err)
		})
	}

	const getClientByID = (id) => {
		axios.post(URI_CLIENT + id, {
			rol: userLogged.rol
		}).then(response => {
			setClient(response.data)
		}).catch(err => {
			console.log("Error al obtener el client por id:" + id)
		})
	}

	const handleLogout = () => {
		removeCookie("token", { path: "/" })
		sessionStorage.removeItem("currentPageSupplier")
		sessionStorage.removeItem("currentPageRequest")
		localStorage.removeItem("switchProfile")
		localStorage.removeItem("profile")
	}

	const handleSwitchProfile = (profile) => {
		const data = {
			selectedProfile: {
				...profile,
				username: user.selectedProfile.username,
				userId: user.selectedProfile.userId,
				darkLightMode: user.selectedProfile.darkLightMode
			},
			userProfiles: user.userProfiles
		}

		localStorage.setItem("switchProfile", JSON.stringify(data))
		window.open("/home", "_blank");
	}

	return (
		<>
			{(supplier && <NavigationMenu firstTime={supplier.firstTime} showMobileMenu={showMobileMenu} setShowMobileMenu={setShowMobileMenu} handleSwitchProfile={handleSwitchProfile} />) || 
			(client && <NavigationMenu showMobileMenu={showMobileMenu} setShowMobileMenu={setShowMobileMenu} handleSwitchProfile={handleSwitchProfile} />)}

			<div className={`container homeClass ${user?.selectedProfile?.darkLightMode ? "darkMode" : null} ${showMobileMenu ? "mobileMenuOpen" : ""}`} style={mainStyle}>
				<Row>
					<Col className="d-none d-lg-block" xs={{ span: 10, offset: 1 }} lg={{ span: 3, offset: 9 }}>
						<div ref={userOptionRef} className={`userLogged ${showUserOption ? "active" : ""}`} onClick={() => setShowUserOption(prevState => !prevState)}>
							<img className="userImage" src={profileExample} alt="Prodensa Supplier" />
							{userLogged && (userLogged.rol === "Master" || userLogged.rol === "Super admin" || userLogged.rol === "Admin client" || userLogged.rol === "Admin Compliance manager" || userLogged.rol === "Admin Security manager") && client && <span>{client.generalInformation.clientName}</span>}
							{userLogged && (userLogged.rol === "Client" || userLogged.rol === "Compliance manager" || userLogged.rol === "Security manager") && client && <span>{client.entities.find(entity => entity._id.toString() === userLogged.dataID.toString()).entityName}</span>}
							{userLogged && (userLogged.rol === "Supplier" || userLogged.rol === "Product manager" || userLogged.rol === "Document manager") && supplier && <span>{supplier.informationCompliance.generalInformation.contactInformation?.name}</span>}
							<div className={`userOptions ${showUserOption ? "show" : ""}`}>
								{user?.profiles?.length > 1 && <span className="header">Swap profile</span>}

								<div className="profilesContainer">
                                    {user.userProfiles?.filter(profile => profile._id !== user.selectedProfile._id)?.map(profile => {
                                        const logo = profile.profileName ? profile.profileName.split(" ").slice(0, 2).map(word => word[0]).join("").toUpperCase() : "N/A"
                                        
                                        return (
                                            <div key={profile._id} className="profileContainer" onClick={() => handleSwitchProfile(profile)}>
                                                <div id="profileLogo">{logo}</div>
                                                <div className="profileInfo">
                                                    <span id="profileName">{profile.profileName}</span>
                                                    <span id="profileRol">{profile.rol}</span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

								<button
									className={`logOut`}
									onClick={handleLogout}>
									Sign Out
								</button>
							</div>

						</div>
					</Col>
					<Col className="d-lg-none" xs={{ span: 12, offset: 0 }} lg={{ span: 3, offset: 9 }}>
						<div className="logoAndMenuMobile">
							<img className="hamburguer" src={menuBlue} alt="Prodensa Supplier" onClick={() => setShowMobileMenu(true)} />
							<img className="logo" src={logo} alt="Prodensa Supplier" />
						</div>
					</Col>
					<Col xs={{ span: 12, offset: 0 }} lg={{ span: 12, offset: 0 }}>
						<Outlet />
					</Col>
				</Row>
			</div >
		</>
	)
}