import "../../assets/css/securityStaff/securityStaffList.css";
import addIcon from "../../assets/images/add.svg";
import exampleProfilePhoto from "../../assets/images/profileExample.png";
import companyIcon from "../../assets/images/companyIcon.svg";
import entityIcon from "../../assets/images/entityIcon.svg";
import searchIcon from "../../assets/images/search.svg";

import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import SingleGuardModal from "./singleGuardModal";
import AddNewSecurityGuardModal from "./addNewSecurityGuardModal";
import { useAuth } from "../../context/auth-user";

export default function SecurityStaffList(props) {
    const { searchText } = props;

    const { user } = useAuth()
    const userLogged = user?.selectedProfile;

    const showCompanyDropdown = userLogged?.rol === 'Master' || userLogged?.rol === 'Super admin';
    const showEntityDropdown = userLogged?.rol === 'Master' || userLogged?.rol === 'Super admin' || userLogged?.rol === 'Admin client' || userLogged?.rol === 'Admin Security manager'

    // Modal Guard Single
    const [showModalView, setShowModalView] = useState(false);
    const [data, setData] = useState({});
    const [guardIndex, setGuardIndex] = useState(null);

    const [staffList, setStaffList] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);

    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [filteredStaffList, setFilteredStaffList] = useState([]);
    const filterRef = useRef(null);
    const staffListRef = useRef(null);  // Ref para el contenedor principal

    const [tempSelectedCompanies, setTempSelectedCompanies] = useState([]);
    const [tempSelectedEntities, setTempSelectedEntities] = useState([]);

    const [companySearchText, setCompanySearchText] = useState("");
    const [entitySearchText, setEntitySearchText] = useState("");

    const [isCompanyFilterOpen, setCompanyFilterOpen] = useState(false);
    const [isEntityFilterOpen, setEntityFilterOpen] = useState(false);

    const [clientEntitiesMap, setClientsEntitiesMap] = useState([]);
    const [companyFilterOptions, setCompanyFilterOptions] = useState([]);
    const [entityFilterOptions, setEntityFilterOptions] = useState([]);

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [filteredEntities, setFilteredEntities] = useState([]);

    // Estado para almacenar los datos de los guardias de seguridad
    const [securityStaff, setSecurityStaff] = useState([]);

    const mainStyle = {
		background: props.darkLightMode ? "#131E29" : "white",
		color: props.darkLightMode ? "white" : "inherit"
	}

    // Efecto para cargar la lista de guardias de seguridad desde el servidor
    useEffect(() => {
        if (!userLogged) return;
        
        fetchSecurityGuards();
    }, [userLogged]);

    useEffect(() => {
        if (tempSelectedCompanies.length > 0) {
            // Crear un conjunto de IDs de las compañías seleccionadas
            const selectedCompanyIds = tempSelectedCompanies.map(company => company.companyId);

            // Filtrar entidades basadas en los IDs de las compañías seleccionadas
            const filteredEntities = entityFilterOptions.filter(entity =>
                selectedCompanyIds.includes(entity.companyId)
            );

            setFilteredEntities(filteredEntities);
        } else {
            // Si no hay compañías seleccionadas, no mostrar entidades
            setFilteredEntities([]);
        }
    }, [tempSelectedCompanies, entityFilterOptions]);


    useEffect(() => {
        const filteredStaff = securityStaff.filter(staff => {
            const companyMatch = selectedCompanies.length === 0 || selectedCompanies.some(company => company.companyName === staff.company);
            const entityMatch = selectedEntities.length === 0 || selectedEntities.some(entity => entity.entityName === staff.entity);
            const searchMatch = staff.name.toLowerCase().includes(searchText.toLowerCase());
            return companyMatch && entityMatch && searchMatch;
        });
        setFilteredStaffList(filteredStaff);
    }, [searchText, selectedCompanies, selectedEntities, securityStaff, tempSelectedCompanies]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            // Verifica si el clic fue fuera del staffListRef y filterRef
            if (
                staffListRef.current &&
                filterRef.current &&
                !staffListRef.current.contains(event.target) &&
                !filterRef.current.contains(event.target)
            ) {
                setCompanyFilterOpen(false);
                setEntityFilterOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const fetchSecurityGuards = async () => {
        const dataID = userLogged?.dataID; // Asegúrate de que `userLogged` esté definido y accesible en el contexto
        const rol = userLogged?.rol

        fetch(process.env.REACT_APP_URI_GET_SECURITY_STAFF, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ dataID, rol })
        })
            .then(response => response.json())
            .then(data => {
                // Estructura para clientes y entidades
                const clients = data.map(client => ({
                    _id: client._id,
                    name: client.generalInformation.clientName,
                    entities: client.entities.map(entity => ({
                        id: entity._id,
                        name: entity.entityName,
                        rfc: entity.entityRfc
                    }))
                }));

                // Transformar el array de clientes en un objeto
                const clientEntitiesMap = clients.reduce((acc, client) => {
                    acc[client._id] = client.entities;
                    return acc;
                }, {});

                // Asignar el resultado a la variable de estado
                setClientsEntitiesMap(clientEntitiesMap);

                // Extraer guardias para mostrar en la tabla
                const guards = data.flatMap(client =>
                    client.entities.flatMap(entity =>
                        entity.entitySecurityGuards.map(guard => ({
                            _id: guard._id, // Aquí asignas el _id del guardia
                            company: client.generalInformation.clientName,
                            entity: entity.entityName,
                            rfc: entity.entityRfc,
                            name: guard.name,
                            username: guard.username,
                            profilePicture: guard.profilePicture?.document,
                            // Otros campos si es necesario
                        }))
                    )
                );


                // Extraer nombres, _id, y rfc de compañías para el filtro de compañía
                const companyDetails = data.map(client => ({
                    id: client._id,
                    name: client.generalInformation.clientName,
                    rfc: client.generalInformation.clientNotificationContacts || '', // Si hay un rfc asociado a la compañía
                    entities: client.entities
                }));

                // Extraer nombres, _id, y rfc de entidades para el filtro de entidad
                const entityDetails = data.flatMap(client =>
                    client.entities.map(entity => ({
                        id: entity._id,
                        name: entity.entityName,
                        rfc: entity.entityRfc || '', // Si hay un rfc asociado a la entidad
                        companyId: client._id
                    }))
                );

                // Setear los estados correspondientes
                setSecurityStaff(guards);
                setCompanyFilterOptions(companyDetails);
                setEntityFilterOptions(entityDetails);
            })
            .catch(error => {
                console.error("Error fetching security staff:", error);
                Swal.fire("Error", "Unable to fetch security staff data.", "error");
            });
    }

    const toggleCompanyFilter = () => {
        if (!isCompanyFilterOpen) {
            setCompanyFilterOpen(true);
            setEntityFilterOpen(false); // Cierra el filtro de entidad si está abierto
        } else {
            setCompanyFilterOpen(false); // Si ya está abierto, ciérralo
        }
    };

    const toggleEntityFilter = () => {
        if (!isEntityFilterOpen) {
            setEntityFilterOpen(true);
            setCompanyFilterOpen(false); // Cierra el filtro de compañía si está abierto
        } else {
            setEntityFilterOpen(false); // Si ya está abierto, ciérralo
        }
    };

    const filterData = () => {
        let filteredData = securityStaff;

        if (tempSelectedCompanies.length > 0) {
            filteredData = filteredData.filter(staff =>
                tempSelectedCompanies.includes(staff.company)
            );
        }

        if (tempSelectedEntities.length > 0) {
            filteredData = filteredData.filter(staff =>
                tempSelectedEntities.includes(staff.entity)
            );
        }

        setStaffList(filteredData);
        setSelectedCompanies(tempSelectedCompanies);
        setSelectedEntities(tempSelectedEntities);
        setCompanyFilterOpen(false); // Cierra el filtro de compañía al aplicar
        setEntityFilterOpen(false); // Cierra el filtro de entidad al aplicar
    };

    const clearFilter = (filterType) => {
        if (filterType === "company") {
            setTempSelectedCompanies([]);
            setTempSelectedEntities([]);
        } else if (filterType === "entity") {
            setTempSelectedEntities([]);
        }
    };

    const handleCompanyChange = (e) => {
        const companyId = e.target.value;  // El ID de la compañía
        const companyName = e.target.dataset.name;  // El nombre de la compañía, si está disponible

        setTempSelectedCompanies(prev =>
            e.target.checked
                ? [...prev, { companyId, companyName }]
                : prev.filter(company => company.companyId !== companyId)
        );
    };

    const handleEntityChange = (e) => {
        const entityId = e.target.value;  // Asumiendo que `value` es el ID de la entidad
        const entityName = e.target.dataset.name;

        setTempSelectedEntities(prev =>
            e.target.checked
                ? [...prev, { entityId, entityName }]
                : prev.filter(entity => entity.entityId !== entityId)
        );
    };

    // Filtrar opciones de compañías basadas en el nombre
    const filteredCompaniesOptions = companyFilterOptions.filter(company =>
        company.name.toLowerCase().includes(companySearchText.toLowerCase())
    );

    // Filtrar opciones de entidades basadas en el nombre
    const filteredEntitiesOptions = filteredEntities.filter(entity =>
        entity.name.toLowerCase().includes(entitySearchText.toLowerCase())
    );

    const openViewModal = (guard, index) => {
        setData(guard);
        setGuardIndex(index);
        setShowModalView(true);
    };

    return (
        <section className={`staffList ${props.darkLightMode ? "darkMode" : null}`} ref={staffListRef}>
            <div className="securityStaffFilters" ref={filterRef}>
                {showCompanyDropdown && (
                    <div className={`locationFilter ${isCompanyFilterOpen ? 'show' : ''} ${selectedCompanies.length > 0 ? 'activeFilter' : ''}`} onClick={toggleCompanyFilter}>
                        <img src={companyIcon} alt="Company icon" />
                        <span className={`filterLabel ${selectedCompanies.length > 0 ? 'filterLabelActive' : ''}`}>Company</span>
                        <div className={`locationFilterOptions ${isCompanyFilterOpen ? 'show' : ''}`} onClick={(e) => e.stopPropagation()}>
                            <div className="locationFilterOptionsInputContainer">
                                <input
                                    className="locationFilterOptionsInput"
                                    type="text"
                                    placeholder="Search company..."
                                    value={companySearchText}
                                    onChange={(e) => setCompanySearchText(e.target.value)}
                                />
                                <img className="searchIcon" src={searchIcon} width={24} height={24} alt="Search icon" />
                            </div>
                            <div className="optionsScroll">
                                {filteredCompaniesOptions.sort((a, b) => a.name?.localeCompare(b.name)).map((company, index) => (
                                    <div key={`company-${index}`} className="option">
                                        <input
                                            id={`company${index}`}
                                            className="supplierCheck"
                                            type="checkbox"
                                            value={company.id}
                                            data-name={company.name}
                                            checked={tempSelectedCompanies.some(companyTemp => companyTemp.companyId === company.id)}
                                            onChange={handleCompanyChange}
                                        />

                                        <label htmlFor={`company${index}`} >{company.name}</label>
                                    </div>
                                ))}
                            </div>
                            <div className="actions">
                                <button onClick={() => clearFilter("company")}>Clear</button>
                                <button onClick={filterData}>Apply</button>
                            </div>
                        </div>
                    </div>
                )}
                {showEntityDropdown && filteredEntities.length > 0 && (
                    <div className={`locationFilter ${isEntityFilterOpen ? 'show' : ''} ${selectedEntities.length > 0 ? 'activeFilter' : ''}`} onClick={toggleEntityFilter}>
                        <img src={entityIcon} alt="Entity icon" />
                        <span className={`filterLabel ${selectedEntities.length > 0 ? 'filterLabelActive' : ''}`}>Entity</span>
                        <div className={`locationFilterOptions ${isEntityFilterOpen ? 'show' : ''}`} onClick={(e) => e.stopPropagation()}>
                            <div className="locationFilterOptionsInputContainer">
                                <input
                                    className="locationFilterOptionsInput"
                                    type="text"
                                    placeholder="Search entity..."
                                    value={entitySearchText}
                                    onChange={(e) => setEntitySearchText(e.target.value)}
                                />
                                <img className="searchIcon" src={searchIcon} width={24} height={24} alt="Search icon" />
                            </div>
                            <div className="optionsScroll">
                                {filteredEntitiesOptions.map((entity, index) => (
                                    <div key={index} className="option">
                                        <input className="supplierCheck" value={entity.id}
                                            id={`supplier${index}`}
                                            data-name={entity.name}
                                            type="checkbox"
                                            checked={tempSelectedEntities.some(entityTemp => entityTemp.entityId === entity.id)}
                                            onChange={handleEntityChange} />
                                        <label htmlFor={`supplier${index}`} >{entity.name}</label>
                                    </div>
                                ))}
                            </div>
                            <div className="actions">
                                <button onClick={() => clearFilter("entity")}>Clear</button>
                                <button onClick={filterData}>Apply</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div style={mainStyle} className="addDocumentHeader">
                <h5>Security staff</h5>
                <button className="btnBgBlueTextGradient" onClick={() => setShowAddModal(true)}>
                    <img className="addIcon" src={addIcon} alt="Add icon" />
                    <div>Add security staff</div>
                </button>
            </div>

            <table className="tableStaff">
                <thead>
                    <tr>
                        <th></th>
                        <th><span>Name</span></th>
                        <th><span>Username</span></th>
                        <th><span>Entity</span></th>
                        {/* <th><span>{countryInformation
							.find(country => country.code.toUpperCase() === supplier.informationCompliance.generalInformation.country.toUpperCase())
							.curpLabel}</span></th> */}
                    </tr>
                </thead>
                <tbody>
                    {filteredStaffList.map((guard, index) => (
                        <tr key={index} onClick={() => openViewModal(guard, index)}>
                            <td>
                                <div className="infoContainer">
                                    <img className="profilePhoto"
                                        src={guard.profilePicture
                                            ? process.env.REACT_APP_URI_LOCALHOST + guard.profilePicture
                                            : exampleProfilePhoto}
                                        alt="Prodensa Supplier"
                                    />
                                </div>
                            </td>
                            <td className="dataSection">
                                <div className="infoContainer">
                                    <span className="guardName">{guard.name}</span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{guard.username}</span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{guard.entity}</span>
                                    <div className="modalIconContainer">
                                        <span className="eyeIcon"
										/*onClick={(e) => { e.stopPropagation(); handleDeleteStaff(index) }}*/ />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="staffsContainer d-lg-none">
                {filteredStaffList.map((guard, index) => (
                    <div key={index} className="staffInformation" onClick={() => openViewModal(guard, index)}>
                        <div className="titleAndPhoto">
                            <h5>{guard.name}</h5>
                            <span>Name</span>
                            <img src={guard.profilePicture
                                ? process.env.REACT_APP_URI_LOCALHOST + guard.profilePicture
                                : exampleProfilePhoto}
                                alt="Prodensa Supplier"
                            />
                        </div>
                        <div className="imssAndRfc">
                            <div>
                                <h5>{guard.username}</h5>
                                <span>Username</span>
                            </div>
                            <div>
                                <h5>{guard.entity}</h5>
                                <span>Entity</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <SingleGuardModal
                show={showModalView}
                data={data}
                onHide={() => setShowModalView(false)}
                fetchSecurityGuards={fetchSecurityGuards}
            />
            <AddNewSecurityGuardModal
                clientEntitiesMap={clientEntitiesMap}
                companies={companyFilterOptions}
                entities={entityFilterOptions}
                show={showAddModal}
                onHide={() => setShowAddModal(false)}
                fetchSecurityGuards={fetchSecurityGuards}
            />
        </section>
    )
}