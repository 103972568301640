import { Accordion, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap"
import "../../assets/css/industries/industryConfiguration.css"

import countryInformation from "../../assets/jsonData/countryInformation.json"

import goBack from "../../assets/images/arrowForward.svg"
import edit from "../../assets/images/editBlue.svg"
import info from "../../assets/images/info.svg"
import deleteImg from "../../assets/images/delete.svg"

import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import Swal from "sweetalert2"
import { useAuth } from "../../context/auth-user"

const URI_INDUSTRY = process.env.REACT_APP_URI_INDUSTRY
const URI_CLIENT = process.env.REACT_APP_URI_CLIENT

export default function IndustryConfiguration({ darkLightMode }) {
	// VARIABLE TO KNOW WHO IS LOGGED
	const { user } = useAuth()
	const userLogged = user?.selectedProfile

	//VARIABLE TO MANAGE THE INDUSTRY
	const [industry, setIndustry] = useState(null)

	//VARIABLE TO MANAGE THE CLIENT
	const [client, setClient] = useState(null)

	// VARIABLE TO MANAGE THE EDIT MODE OF THE TITLE
	const [editMode, setEditMode] = useState(false)

	// VARIABLES TO MANAGE THE ID OF THE INDUSTRY AND NAVIGATION OF THE PAGE
	const { id } = useParams()
	const navigate = useNavigate()

	const mainStyle = {
		background: darkLightMode ? "#131E29" : "white",
		color: darkLightMode ? "white" : "inherit"
	}

	// USE EFFECT TO GET THE INDUSTRY BY ID
	useEffect(() => {
		if (!userLogged) return
		
		getIndustryById(id)

		if (userLogged?.rol === "Admin client" || userLogged?.rol === "Admin Compliance manager") {
			getClientById(userLogged?.dataID)		}
	}, [userLogged])

	// FUNCTION TO GET THE INDUSTRY BY ID
	const getIndustryById = (id) => {
		axios.get(URI_INDUSTRY + id).then(response => {
			setIndustry(response.data)
		}).catch(err => {
			console.log("Error al obtener la industria por id")
		})
	}

	// FUNCTION TO ADD NEW DOCUMENTS TO THE INDUSTRY
	const addNewDocument = () => {
		const industryCopy = [...JSON.parse(JSON.stringify(industry.documentReference)), {
			documentName: "",
			periodicity: 0,
			country: "MEX",
			typePerson: "",
			documentDisclaimer: "",
			notApplicable: false,
			supplierCompliancy: false,
		}]

		setIndustry(prevState => {
			return {
				...prevState,
				documentReference: industryCopy
			}
		})
	}

	// FUNCTION TO HANDLE WHEN A DOCUMENT CHANGES ANY VARIABLE
	const handleDocumentChange = (position, property, value) => {
		const industryCopy = JSON.parse(JSON.stringify(industry.documentReference))

		industryCopy[position][property] = value

		if (property === "country") {
			industryCopy[position]["typePerson"] = ""
		}

		setIndustry(prevState => {
			return {
				...prevState,
				documentReference: industryCopy
			}
		})
	}

	// FUNCTION TO DELETE THE SELECTED DOCUMENT
	const handleDeleteDocument = (position) => {
		const industryCopy = JSON.parse(JSON.stringify(industry.documentReference))

		industryCopy.splice(position, 1)

		setIndustry(prevState => {
			return {
				...prevState,
				documentReference: industryCopy
			}
		})
	}

	// FUNCTION TO HANDLE THE SAVE INFORMATION OF THE INDUSTRY
	const handleSaveChanges = () => {
		Swal.fire({
			footer: 'Loading, please wait',
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			didOpen: () => {
				Swal.showLoading()
			},
		})

		if (userLogged?.rol === "Master" || userLogged?.rol === "Super admin") {
			axios.put(URI_INDUSTRY + id, {
				industry: industry
			}).then(respone => {
				Swal.fire({
					icon: 'success',
					text: 'Information saved successfully',
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})
			}).catch(err => {
				Swal.fire({
					icon: 'error',
					text: 'Failed to save information',
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})
				console.log("Error al guardar la informacion de la industria: " + err)
			})
		} else {
			axios.put(URI_CLIENT + userLogged?.dataID, {
				client: client
			}).then(respone => {
				Swal.fire({
					icon: 'success',
					text: 'Information saved successfully',
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})
			}).catch(err => {
				Swal.fire({
					icon: 'error',
					text: 'Failed to save information',
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})
				console.log("Error al guardar la informacion del cliente: " + err)
			})
		}
	}

	// FUNCTION TO GET THE CLIENT BY ID
	const getClientById = (id) => {
		axios.post(URI_CLIENT + id, {
			rol: userLogged?.rol
		}).then(response => {
			setClient(response.data)
		}).catch(err => {
			console.log("Error al obtener la industria por id")
		})
	}

	// FUNCTION TO ADD NEW DOCUMENTS TO THE INDUSTRY
	const addNewDocumentClient = (entityPosition) => {
		const clientCopy = JSON.parse(JSON.stringify(client))
		if (!clientCopy.entities[entityPosition].entityIndustries.find(entityIndustry => entityIndustry.industryName === industry.name)) {
			clientCopy.entities[entityPosition].entityIndustries = [...clientCopy.entities[entityPosition].entityIndustries, {
				industryName: industry.name,
				industryDocumentReference: []
			}]
		}

		clientCopy.entities[entityPosition].entityIndustries.find(entityIndustry => entityIndustry.industryName === industry.name).industryDocumentReference = [...clientCopy.entities[entityPosition].entityIndustries.find(entityIndustry => entityIndustry.industryName === industry.name).industryDocumentReference, {
			documentName: "",
			periodicity: 0,
			country: "MEX",
			typePerson: "",
			documentDisclaimer: "",
			notApplicable: false,
			supplierCompliancy: false,
		}]

		setClient(clientCopy)
	}

	// FUNCTION TO HANDLE WHEN A DOCUMENT CHANGES ANY VARIABLE
	const handleDocumentChangeClient = (entityPosition, documentPosition, property, value) => {
		const clientCopy = JSON.parse(JSON.stringify(client))

		clientCopy.entities[entityPosition].entityIndustries.find(entityIndustry => entityIndustry.industryName === industry.name).industryDocumentReference[documentPosition][property] = value

		if (property === "country") {
			clientCopy.entities[entityPosition].entityIndustries.find(entityIndustry => entityIndustry.industryName === industry.name).industryDocumentReference[documentPosition]["typePerson"] = ""
		}

		setClient(clientCopy)
	}

	// FUNCTION TO DELETE THE SELECTED DOCUMENT
	const handleDeleteDocumentClient = (entityPosition, documentPosition) => {
		const clientCopy = JSON.parse(JSON.stringify(client))

		clientCopy.entities[entityPosition].entityIndustries.find(entityIndustry => entityIndustry.industryName === industry.name).industryDocumentReference.splice(documentPosition, 1)

		setClient(clientCopy)
	}

	// FUNCTION TO CONSTRUCT THE DESKTOP INDUSTRY DOCUMENT TABLE BY ROL
	const industryDocumentsDesktopView = () => {
		if (userLogged?.rol === "Master" || userLogged?.rol === "Super admin") {
			return (
				<React.Fragment>
					<h5 style={mainStyle} className="titleHeader">
						Legal industry documents
						<button className="btnBgBlueTextGradient d-lg-none" onClick={() => addNewDocument()}></button>
					</h5>
					<div className={`tableTitles ${darkLightMode ? "darkMode" : null}`}>
						<div className="inputContainer">
							<span className="label">Document Name</span>
						</div>
						<div className="inputContainer">
							<span className="label">Periodicity</span>
							<OverlayTrigger placement="top"
								overlay={<Tooltip id="periodicityTooltip" style={{ position: "fixed" }}>
									The periodicity
									<br />
									is managed monthly
									<br />
									<br />
									0 = Document that is
									<br />
									only requested once
								</Tooltip>}>
								<img src={info} alt="Prodensa Supplier" />
							</OverlayTrigger>
						</div>
						<div className="inputContainer">
							<span className="label">Country</span>
						</div>
						<div className="inputContainer">
							<span className="label">Type of person</span>
						</div>
						<div className="inputContainer">
							<span className="label">Document disclaimer</span>
						</div>
						<div className="inputContainer">
							<span className="label">Url to download document</span>
						</div>
						<div className="inputContainer">
							<span className="label">Optional</span>
						</div>
					</div>

					{industry && industry.documentReference.map((document, documentIndex) => (
						<div key={documentIndex} className={`tableDocuments ${darkLightMode ? "darkMode" : null}`}>
							<div className="inputContainer">
								<input
									type="text"
									value={document.documentName}
									onChange={(e) => handleDocumentChange(documentIndex, "documentName", e.target.value)}
								/>
							</div>
							<div className="inputContainer">
								<input
									type="number"
									value={document.periodicity}
									onChange={(e) => handleDocumentChange(documentIndex, "periodicity", e.target.value >= 0 ? Math.round(e.target.value) : 0)}
								/>
								<span className="labelHolder">Month(s)</span>
							</div>
							<div className="inputContainer">
								<Dropdown>
									<Dropdown.Toggle className={`${document.country ? "" : "default"}`}>
										{document.country ? countryInformation.find(countries => {
											return countries.code === document.country
										}).country : "Select country"}
									</Dropdown.Toggle>

									<Dropdown.Menu>
										{countryInformation.map((information, index) => (
											<Dropdown.Item key={index} onClick={() => handleDocumentChange(documentIndex, "country", information.code)}>
												{information.country}
											</Dropdown.Item>
										))}
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div className="inputContainer">
								<Dropdown>
									<Dropdown.Toggle
										className={`${document.typePerson ? "" : "default"}`}>
										{document.typePerson ? document.typePerson : "Select option"}
									</Dropdown.Toggle>

									<Dropdown.Menu>
										{countryInformation.find(countries => countries.code === document.country).typePersonValues.map((person, index) => (
											<Dropdown.Item key={index} onClick={(e) => handleDocumentChange(documentIndex, "typePerson", e.target.innerText)}>
												{person}
											</Dropdown.Item>
										))}
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div className="inputContainer">
								<input
									type="text"
									value={document.documentDisclaimer}
									placeholder="document disclaimer"
									onChange={(e) => handleDocumentChange(documentIndex, "documentDisclaimer", e.target.value)}
								/>
							</div>
							<div className="inputContainer">
								<input
									type="text"
									value={document.urlDownload}
									placeholder="url to download document"
									onChange={(e) => handleDocumentChange(documentIndex, "urlDownload", e.target.value)}
								/>
							</div>
							<div className="inputContainer">
								<div className={`toggleContainer ${document.notApplicable ? "active" : ""}`} onClick={() => handleDocumentChange(documentIndex, "notApplicable", !document.notApplicable)}>
									<div className="toggle"></div>
								</div>
								{industry.name !== "Global" && <img src={deleteImg} onClick={() => handleDeleteDocument(documentIndex)} alt="Prodensa Supplier" />}
							</div>
						</div>
					))}

					<button className="btnBgBlueTextGradient" onClick={() => addNewDocument()}></button>
				</React.Fragment>
			)
		} else {
			return (
				client && client.entities.map((entity, entityIndex) => (
					<React.Fragment key={entityIndex}>
						<h5 className="titleHeader">
							{entity.entityName} industry documents
							<button className="btnBgBlueTextGradient d-lg-none" onClick={() => addNewDocumentClient(entityIndex)}></button>
						</h5>
						<div className="tableTitles">
							<div className="inputContainer">
								<span className="label">Document Name</span>
							</div>
							<div className="inputContainer">
								<span className="label">Periodicity</span>
								<OverlayTrigger placement="top"
									overlay={<Tooltip id="periodicityTooltip" style={{ position: "fixed" }}>
										The periodicity
										<br />
										is managed monthly
										<br />
										<br />
										0 = Document that is
										<br />
										only requested once
									</Tooltip>}>
									<img src={info} alt="Prodensa Supplier" />
								</OverlayTrigger>
							</div>
							<div className="inputContainer">
								<span className="label">Country</span>
							</div>
							<div className="inputContainer">
								<span className="label">Type of person</span>
							</div>
							<div className="inputContainer">
								<span className="label">Document disclaimer</span>
							</div>
							<div className="inputContainer">
								<span className="label">Optional</span>
							</div>
						</div>
						{industry && entity.entityIndustries.find(entityIndustry => entityIndustry.industryName === industry.name)?.industryDocumentReference.map((document, documentIndex) => (
							<div key={documentIndex} className="tableDocuments">
								<div className="inputContainer">
									<input
										type="text"
										value={document.documentName}
										onChange={(e) => handleDocumentChangeClient(entityIndex, documentIndex, "documentName", e.target.value)}
									/>
								</div>
								<div className="inputContainer">
									<input
										type="number"
										value={document.periodicity}
										onChange={(e) => handleDocumentChangeClient(entityIndex, documentIndex, "periodicity", e.target.value >= 0 ? Math.round(e.target.value) : 0)}
									/>
									<span className="labelHolder">Month(s)</span>
								</div>
								<div className="inputContainer">
									<Dropdown>
										<Dropdown.Toggle
											className={`${document.country ? "" : "default"}`}
										>
											{document.country ? countryInformation.find(countries => {
												return countries.code === document.country
											}).country : "Select country"}
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{countryInformation.map((information, index) => (
												<Dropdown.Item key={index} onClick={() => handleDocumentChangeClient(entityIndex, documentIndex, "country", information.code)}>
													{information.country}
												</Dropdown.Item>
											))}
										</Dropdown.Menu>
									</Dropdown>
								</div>
								<div className="inputContainer">
									<Dropdown>
										<Dropdown.Toggle
											className={`${document.typePerson ? "" : "default"}`}
										>
											{document.typePerson ? document.typePerson : "Select option"}
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{countryInformation.find(countries => countries.code === document.country).typePersonValues.map((person, index) => (
												<Dropdown.Item key={index} onClick={(e) => handleDocumentChangeClient(entityIndex, documentIndex, "typePerson", e.target.innerText)}>
													{person}
												</Dropdown.Item>
											))}
										</Dropdown.Menu>
									</Dropdown>
								</div>
								<div className="inputContainer">
									<input
										type="text"
										value={document.documentDisclaimer}
										placeholder="document disclaimer"
										onChange={(e) => handleDocumentChangeClient(entityIndex, documentIndex, "documentDisclaimer", e.target.value)}
									/>
								</div>
								<div className="inputContainer">
									<div className={`toggleContainer ${document.notApplicable ? "active" : ""}`} onClick={() => handleDocumentChangeClient(entityIndex, documentIndex, "notApplicable", !document.notApplicable)}>
										<div className="toggle"></div>
									</div>
									{industry.name !== "Global" && <img src={deleteImg} onClick={() => handleDeleteDocumentClient(entityIndex, documentIndex)} alt="Prodensa Supplier" />}
								</div>
							</div>
						))}

						<button className="btnBgBlueTextGradient" onClick={() => addNewDocumentClient(entityIndex)}></button>
					</React.Fragment>
				))
			)
		}
	}

	// FUNCTION TO CONSTRUCT THE MOBILE INDUSTRY DOCUMENT ACCORDION BY ROL
	const industryDocumentsMobileView = () => {
		if (userLogged?.rol === "Master" || userLogged?.rol === "Super admin") {
			return (
				<Accordion className="d-lg-none">
					{industry && industry.documentReference.map((document, documentIndex) => (
						<Accordion.Item key={documentIndex} eventKey={documentIndex}>
							<Accordion.Header>{document.documentName}</Accordion.Header>
							<Accordion.Body>
								<div className="inputContainer">
									<span className="label">Document Name</span>
									<input
										type="text" value={document.documentName}
										onChange={(e) => handleDocumentChange(documentIndex, "documentName", e.target.value)}
									/>
								</div>
								<div className="inputContainer">
									<span className="label">Periodicity</span>
									<input
										type="number"
										value={document.periodicity} onChange={(e) => handleDocumentChange(documentIndex, "periodicity", e.target.value >= 0 ? Math.round(e.target.value) : 0)}
									/>
									<span className="labelHolder">Month(s)</span>
								</div>
								<div className="inputContainer">
									<span className="label">Country</span>
									<Dropdown>
										<Dropdown.Toggle className={`${document.country ? "" : "default"}`}>
											{document.country ? countryInformation.find(countries => {
												return countries.code === document.country
											}).country : "Select country"}
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{countryInformation.map((information, index) => (
												<Dropdown.Item key={index} onClick={() => handleDocumentChange(documentIndex, "country", information.code)}>
													{information.country}
												</Dropdown.Item>
											))}
										</Dropdown.Menu>
									</Dropdown>
								</div>
								<div className="inputContainer">
									<span className="label">Type of person</span>
									<Dropdown>
										<Dropdown.Toggle
											className={`${document.typePerson ? "" : "default"}`}
										>
											{document.typePerson ? document.typePerson : "Select option"}
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{countryInformation.find(countries => countries.code === document.country).typePersonValues.map((person, index) => (
												<Dropdown.Item key={index} onClick={(e) => handleDocumentChange(documentIndex, "typePerson", e.target.innerText)}>
													{person}
												</Dropdown.Item>
											))}
										</Dropdown.Menu>
									</Dropdown>
								</div>
								<div className="inputContainer">
									<span className="label">Document disclaimer</span>
									<input
										type="text"
										value={document.documentDisclaimer}
										placeholder="document disclaimer"
										onChange={(e) => handleDocumentChange(documentIndex, "documentDisclaimer", e.target.value)}
									/>
								</div>
								<div className="inputContainer">
									<span className="label">Url to download document</span>
									<input
										type="text"
										value={document.urlDownload}
										placeholder="Url to download document"
										onChange={(e) => handleDocumentChange(documentIndex, "urlDownload", e.target.value)}
									/>
								</div>
								<div className="inputContainer">
									<span className="label">Optional</span>
									<div className={`toggleContainer ${document.notApplicable ? "active" : ""}`} onClick={() => handleDocumentChange(documentIndex, "notApplicable", !document.notApplicable)}>
										<div className="toggle"></div>
									</div>
									{industry.name !== "Global" && <img src={deleteImg} onClick={() => handleDeleteDocument(documentIndex)} alt="Prodensa Supplier" />}
								</div>
							</Accordion.Body>
						</Accordion.Item>
					))}
				</Accordion>
			)
		} else {
			return (
				client && client.entities.map((entity, entityIndex) => (
					<React.Fragment key={entityIndex}>
						<h5 className="titleHeaderMobile d-lg-none">
							{entity.entityName} industry documents
							<button className="btnBgBlueTextGradient d-lg-none" onClick={() => addNewDocumentClient(entityIndex)}></button>
						</h5>
						<Accordion className="d-lg-none">
							{industry && entity.entityIndustries.find(entityIndustry => entityIndustry.industryName === industry.name)?.industryDocumentReference.map((document, documentIndex) => (
								<Accordion.Item key={documentIndex} eventKey={documentIndex}>
									<Accordion.Header>{document.documentName}</Accordion.Header>
									<Accordion.Body>
										<div className="inputContainer">
											<span className="label">Document Name</span>
											<input
												type="text" value={document.documentName}
												onChange={(e) => handleDocumentChangeClient(entityIndex, documentIndex, "documentName", e.target.value)}
											/>
										</div>
										<div className="inputContainer">
											<span className="label">Periodicity</span>
											<input
												type="number"
												value={document.periodicity} onChange={(e) => handleDocumentChangeClient(entityIndex, documentIndex, "periodicity", e.target.value >= 0 ? Math.round(e.target.value) : 0)}
											/>
											<span className="labelHolder">Month(s)</span>
										</div>
										<div className="inputContainer">
											<span className="label">Country</span>
											<Dropdown>
												<Dropdown.Toggle
													className={`${document.country ? "" : "default"}`}
												>
													{document.country ? countryInformation.find(countries => {
														return countries.code === document.country
													}).country : "Select country"}
												</Dropdown.Toggle>

												<Dropdown.Menu>
													{countryInformation.map((information, index) => (
														<Dropdown.Item key={index} onClick={(e) => handleDocumentChangeClient(entityIndex, documentIndex, "country", information.code)}>
															{information.country}
														</Dropdown.Item>
													))}
												</Dropdown.Menu>
											</Dropdown>
										</div>
										<div className="inputContainer">
											<span className="label">Type of person</span>
											<Dropdown>
												<Dropdown.Toggle
													className={`${document.typePerson ? "" : "default"}`}
												>
													{document.typePerson ? document.typePerson : "Select option"}
												</Dropdown.Toggle>

												<Dropdown.Menu>
													{countryInformation.find(countries => countries.code === document.country).typePersonValues.map((person, index) => (
														<Dropdown.Item key={index} onClick={(e) => handleDocumentChangeClient(entityIndex, documentIndex, "typePerson", e.target.innerText)}>
															{person}
														</Dropdown.Item>
													))}
												</Dropdown.Menu>
											</Dropdown>
										</div>
										<div className="inputContainer">
											<span className="label">Document disclaimer</span>
											<input
												type="text"
												value={document.documentDisclaimer}
												placeholder="document disclaimer"
												onChange={(e) => handleDocumentChangeClient(entityIndex, documentIndex, "documentDisclaimer", e.target.value)}
											/>
										</div>
										<div className="inputContainer">
											<span className="label">Optional</span>
											<div className={`toggleContainer ${document.notApplicable ? "active" : ""}`} onClick={() => handleDocumentChangeClient(entityIndex, documentIndex, "notApplicable", !document.notApplicable)}>
												<div className="toggle"></div>
											</div>
										</div>
										<div className="inputContainer">
											{industry.name !== "Global" && <img src={deleteImg} onClick={() => handleDeleteDocumentClient(entityIndex, documentIndex)} alt="Prodensa Supplier" />}
										</div>
									</Accordion.Body>
								</Accordion.Item>
							))}
						</Accordion>
					</React.Fragment>
				))
			)
		}
	}

	return (
		<section className="industryConfiguration">
			<div className="titleAndBack">
				<img className="goBack" src={goBack} onClick={() => navigate(-1)} alt="Prodensa Supplier" />
				{!editMode && <h4>{industry && industry.name}</h4>}
				{editMode && <div className="inputContainer">
					<input type="text" value={industry && industry.name} onChange={(e) => {
						setIndustry(prevState => {
							return {
								...prevState,
								name: e.target.value
							}
						})
					}} />
				</div>}
				{(userLogged?.rol === "Master" || userLogged?.rol === "Super admin") && industry?.name !== "Global" &&
					<img className="editImg" src={edit} onClick={() => setEditMode(prevState => !prevState)} alt="Prodensa Supplier" />}
			</div>

			{industryDocumentsDesktopView()}
			{industryDocumentsMobileView()}

			<div className={`actions ${darkLightMode ? "darkMode" : null}`}>
				<button className="btnBgBlueTextGradient" onClick={() => navigate(-1)}>Discard changes</button>
				<button className="btnBgBlueTextGradient" onClick={() => handleSaveChanges()}>Save changes</button>
			</div>
		</section>
	)
}