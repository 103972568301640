import "../../assets/css/clientsAdminView/addEntity.css"
import countryInformation from "../../assets/jsonData/countryInformation.json"

import close from "../../assets/images/close.svg"

import { Dropdown, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { checkTheEmailStruct } from "../../utilities/validateEmail.js"

export default function EditEntityModal(props) {
	// VARIABLES TO MANAGE THE ENTITY INFORMATION
	const [entity, setEntity] = useState("")

	useEffect(() => {
		if (props.show) {
			const entityCopy = JSON.parse(JSON.stringify(props.entityToEdit))

			entityCopy.entityName = entityCopy.entityName ? entityCopy.entityName : props.companyName
			entityCopy.entityRfc = entityCopy.entityRfc ? entityCopy.entityRfc : props.rfc
			entityCopy.entityState = entityCopy.entityState ? entityCopy.entityState : props.state
			entityCopy.entityPostalCode = entityCopy.entityPostalCode ? entityCopy.entityPostalCode : props.postalCode
			entityCopy.entityStreetAndNumber = entityCopy.entityStreetAndNumber ? entityCopy.entityStreetAndNumber : props.streetAndNumber
			entityCopy.entityNeighborhood = entityCopy.entityNeighborhood ? entityCopy.entityNeighborhood : props.neighborhood
			entityCopy.entityMunicipality = entityCopy.entityMunicipality ? entityCopy.entityMunicipality : props.municipality

			setEntity(entityCopy)
		}
	}, [props.show])

	// FUNCTION TO HANDLE THE PROPERTY CHANGE
	const handleEditProperty = (property, value) => {
		setEntity(prevState => {
			return {
				...prevState,
				[property]: value
			}
		})
	}

	const handleCheckEmail = async () => {
		try {
			const res = await checkTheEmailStruct(entity?.entityUsername ?? "")

			setEntity(prevState => {
				return {
					...prevState,
					entityUsername: res
				}
			})
		} catch (error) {
			console.log(error)
			setEntity(prevState => {
				return {
					...prevState,
					entityUsername: ""
				}
			})
		}
	}

	// FUNCTION TO HANDLE THE CLIENT CREATION
	const handleSaveEntity = () => {
		props.handleEntityChange(entity)
		props.onHide()
	}

	// FUNCTION TO CHECK IF THE URL NAME EXIST
	const checkEntityUrlName = async (urlNameToCheck) => {
		const found = await props.checkEntitiesUrlName(urlNameToCheck)

		if (found) {
			Swal.fire({
				icon: 'error',
				text: 'This url name already exist, please try another one',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
			handleEditProperty("entityUrlName", "")
		}
	}

	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			size="xl"
			centered
			className="entityModal">

			<Modal.Body>
				<img className="closeModal" src={close} onClick={() => props.onHide()} alt="Prodensa Supplier" />
				<h3 className="modalTitle">Edit entity</h3>
				<div className="inputSections">
					<div className="inputContainer">
						<span className="label">Name</span>
						<input type="text" placeholder="Entity name" value={entity.entityName}
							onChange={(e) => handleEditProperty("entityName", e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Cattenna Landing Name</span>
						<input type="text" placeholder="Cattenna Landing Name" value={entity.entityUrlName}
							onChange={(e) => handleEditProperty("entityUrlName", e.target.value)}
							onBlur={() => checkEntityUrlName(entity.entityUrlName)}
						/>
					</div>
					<div className={`inputContainer`}>
						<span className="label">
							{countryInformation.find(countries => {
								return countries.country.toUpperCase() === props.country.toUpperCase()
							})?.rfcLabel}
						</span>
						<input id="inputRfc" value={entity.entityRfc}
							onChange={(e) => {
								if (props.country === "México")
									handleEditProperty("entityRfc", e.target.value)
								else if (props.country === "United states of america") {
									const value = e.target.value.replace(/[^\d]/g, "")
									handleEditProperty("entityRfc", value)
								}
							}}
							type="text"
							placeholder={countryInformation.find(countries => {
								return countries.country.toUpperCase() === props.country.toUpperCase()
							})?.rfcPlaceholder} />
					</div>
					<div className="inputContainer">
						<span className="label">State</span>
						<Dropdown>
							<Dropdown.Toggle className={`${entity.entityState ? "" : "default"}`}>
								{entity.entityState ? entity.entityState : "State"}
							</Dropdown.Toggle>

							<Dropdown.Menu>
								{countryInformation.find(countries => countries.country.toUpperCase() === props.country.toUpperCase())?.states.map((state, index) => (
									<Dropdown.Item key={index} onClick={(e) => handleEditProperty("entityState", e.target.innerText)}>{state.name}</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
					</div>
					<div className="inputContainer">
						<span className="label">C.P:</span>
						<input type="text" placeholder="Postal code" value={entity.entityPostalCode}
							onChange={(e) => handleEditProperty("entityPostalCode", e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Street, interior & exterior number</span>
						<input type="text" placeholder="Street, interior & exterior number" value={entity.entityStreetAndNumber}
							onChange={(e) => handleEditProperty("entityStreetAndNumber", e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Neighborhood</span>
						<input type="text" placeholder="Neighborhood" value={entity.entityNeighborhood}
							onChange={(e) => handleEditProperty("entityNeighborhood", e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Municipality</span>
						<input type="text" placeholder="Municipality" value={entity.entityMunicipality}
							onChange={(e) => handleEditProperty("entityMunicipality", e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Notification contacts <bg-grey>(Separate by comma)</bg-grey></span>
						<input type="text" placeholder="example1@example.com, example2@example.com" value={entity.entityNotificationContacts}
							onChange={(e) => handleEditProperty("entityNotificationContacts", e.target.value)}
						/>
					</div>
					{!props.editView && <div className="inputContainer">
						<span className="label">Entity username</span>
						<input type="text" placeholder="example@example.com" value={entity.entityUsername}
							onChange={(e) => handleEditProperty("entityUsername", e.target.value)} onBlur={handleCheckEmail}
						/>
					</div>}
				</div>
				<div className="actions">
					<button className="btnBgWhiteTextBlack" onClick={() => props.onHide()}>Cancel</button>
					<button className="btnBgBlueTextGradient" onClick={() => handleSaveEntity()}>Save entity</button>
				</div>
			</Modal.Body>
		</Modal>
	)
}