import "../../assets/css/clientAlerts/clientAlerts.css"

import { useEffect, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import axios from "axios"

// import HoldPaymentList from "./holdPaymentList.jsx"
// import ContractList from "./contractList.jsx"
import Swal from "sweetalert2"
import SecurityStaffList from "./securityStaffList"
import VisitsList from "./visitsList"
import { useAuth } from "../../context/auth-user"
// import ActionNeededList from "./actionNeededList.jsx"

const URI_ALERTS_LIST = process.env.REACT_APP_URI_ALERTS_LIST

export default function SecurityStaff({ darkLightMode }) {
    //VARIABLE TO MANAGE WHOS LOG IN
    const { user } = useAuth()
    const userLogged = user?.selectedProfile

    // VARIABLE TO MANAGE THE INFORMATION FROM THE 3 TABS
    const [alertsList, setAlertsList] = useState(null)
    const [alertsListCopy, setAlertsListCopy] = useState(null)

    const [searchText, setSearchText] = useState("");

    const [key, setKey] = useState(0)

    useEffect(() => {
        if (!userLogged) return
        
        getAlertListInformation()
    }, [userLogged])

    // RESET THE DATA LIST EVERY TIME A TAB IS CHANGED
    useEffect(() => {
        setAlertsListCopy(alertsList)
        clearSearchFilter()
        setSearchText("")
    }, [key])

    // GET ALL THE INFORMATION NEEDED TO THE 3 TABS
    const getAlertListInformation = () => {
        Swal.fire({
            footer: 'Loading, please wait',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })

        axios.post(URI_ALERTS_LIST, {
            rol: userLogged?.rol,
            dataID: userLogged?.dataID
        }).then(response => {
            setAlertsList(response.data)
            setAlertsListCopy(response.data)
            Swal.close()
        }).catch(err => {
            console.log("Error al obtener la informacion: " + err)
            Swal.close()
        })
    }

    // FUNCTION TO CLEAR THE SEARCH INPUT
    const clearSearchFilter = () => {
        const searchElement = document.getElementById("generalSearch")
        searchElement.value = ""
    }

    return (
        <section className={`alerts ${darkLightMode ? "darkMode" : null}`}>
            <div className="titleAndSearch">
                <h3 className="title">Security</h3>
                <div className="searchAndSort">
                    <div className={`searchContainer ${darkLightMode ? "darkMode" : null}`}>
                        <input
                            id="generalSearch"
                            type="text"
                            placeholder="Search by name..."
                            onChange={(e) => {
                                const searchValue = e.target.value;
                                setSearchText(searchValue);
                            }}
                        />
                    </div>
                </div>
            </div>

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(+k)}>

                <Tab eventKey={0} title="Guards">
                    <SecurityStaffList
                        searchText={searchText}
                        darkLightMode={darkLightMode}
                    />
                </Tab>
                <Tab eventKey={1} title="Visits">
                    <VisitsList
                        searchText={searchText}
                        darkLightMode={darkLightMode}
                    />
                </Tab>
            </Tabs>
        </section>
    )
}