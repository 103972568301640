import "../../assets/css/supplierAlerts/supplierAlerts.css"

import typeOfAlert from "../../assets/images/status.svg"
import sortIcon from "../../assets/images/sort.svg"
import next from "../../assets/images/arrowForward.svg"

import { useEffect, useRef, useState } from "react"
import axios from "axios"
import Swal from "sweetalert2"
import moment from "moment"
import { useAuth } from "../../context/auth-user"

const URI_SUPPLIER_ALERT = process.env.REACT_APP_SUPPLIER_ALERT

export default function ClientAlerts() {
	// VARIABLE TO KNOW WHOS IS LOGGED
	const { user } = useAuth()
	const userLogged = user?.selectedProfile

	// VARIABLE TO CONTROL DATA FROM TABLE
	const [dataTable, setDataTable] = useState([])
	const [dataTableCopy, setDataTableCopy] = useState([])

	const [currentPage, setCurrentPage] = useState(1)

	let totalPages = Math.ceil(dataTableCopy.length / 20);
	let indexOfLastItem = currentPage * 20;
	let indexOfFirstItem = indexOfLastItem - 20;
	let currentItems = dataTableCopy.slice(indexOfFirstItem, indexOfLastItem);

	let paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	// VARAIBLE TO CONTROL THE SORT ORDER
	const [order, setOrder] = useState(true)

	// VARIABLES TO CONTROL SORT MENU SHOW AND HIDE
	const [showSortMenu, setShowSortMenu] = useState(false)

	// VARIABLE TO MANAGE FILTER AND MOBILE SORT MENU
	const sortMenuRef = useRef(null)
	const filterRef = useRef(null)

	// VARIABLES TO FILTER DATA
	const [typeOfAlertFilter, setTypeOfAlertFilter] = useState([])

	// VARIABLES TO KNOW IF THE FILTER IS ON
	const [typeOfAlertIsFiltered, setTypeOfAlertIsFiltered] = useState(false)

	// VARIABLE TO CONTROL THE FILTER DATA AFTER CLEAR A SINGLE FILTER
	const [hardFilterData, setHardFilterReset] = useState(false)

	// VARIABLE TO SHOW RESET ALL FILTERS
	const [resetAll, setResetAll] = useState(false)

	// VARIABLE TO CONTROL WICH ALERTS ARE SELECTED
	const [selectedAlerts, setSelectedAlerts] = useState([])

	// useEffect to add body listener
	useEffect(() => {
		if (!userLogged) return
		
		getSupplierAlerts()

		document.body.addEventListener("click", closeMenusFilters)

		return function cleanUp() {
			document.body.removeEventListener("click", closeMenusFilters)
		}
	}, [userLogged])

	// COMPARATION TO SHOW THE RESET ALL BUTTON
	useEffect(() => {
		if (typeOfAlertIsFiltered) {
			setResetAll(true)
		} else {
			setResetAll(false)
		}
	})

	// ACTION TO EXECUTE WHEN YOU CLEAR A SINGLE FILTER TO RESET THE TABLE
	useEffect(() => {
		if (hardFilterData) {
			filterData()
			setHardFilterReset(false)
		}
	}, [hardFilterData])

	// FUNCTION TO CLOSE MENU FILTERS
	const closeMenusFilters = (e) => {
		let filtersContainer;
		let menuFiltersContainer;

		if (filterRef.current && filterRef.current.lastElementChild && filterRef.current.contains(e.target)) {
			filtersContainer = Array.prototype.slice.call(document.getElementsByClassName("filter"))
			filtersContainer.map(element => {
				if (!filterRef.current.contains(element)) {
					element.classList.remove("show")
				}
			})
			filterRef.current.classList.toggle("show")

			menuFiltersContainer = Array.prototype.slice.call(document.getElementsByClassName("filterOptions"))
			menuFiltersContainer.map(element => {
				if (!filterRef.current.lastElementChild.contains(element)) {
					element.classList.remove("show")
				}
			})
			filterRef.current.lastElementChild.classList.toggle("show")
		} else {
			filtersContainer = Array.prototype.slice.call(document.getElementsByClassName("filter"))
			filtersContainer.map(element => {
				element.classList.remove("show")
			})

			menuFiltersContainer = Array.prototype.slice.call(document.getElementsByClassName("filterOptions"))
			menuFiltersContainer.map(element => {
				element.classList.remove("show")
			})
		}

		if (sortMenuRef.current && !sortMenuRef.current.contains(e.target)) {
			setShowSortMenu(false)
		}
	}

	// FUNCTION TO SORT TABLE COLUMN
	const sortTable = (order, sortBy) => {
		// ORDER FALSE = ASC; ORDER TRUE = DSC 
		switch (sortBy) {
			case "Client":
				setDataTableCopy(dataTableCopy.sort((a, b) => {
					let fa = a.entityName.toLocaleLowerCase();
					let fb = b.entityName.toLocaleLowerCase();

					if (!order) {
						if (fa < fb) {
							return -1
						}

						if (fa > fb) {
							return 1
						}
					} else {
						if (fa > fb) {
							return -1
						}

						if (fa < fb) {
							return 1
						}
					}

					return 0
				}))

				setOrder(!order)
				break

			case "Type":
				setDataTableCopy(dataTableCopy.sort((a, b) => {
					let fa = a.typeOfAlert.toLocaleLowerCase();
					let fb = b.typeOfAlert.toLocaleLowerCase();

					if (!order) {
						if (fa < fb) {
							return -1
						}

						if (fa > fb) {
							return 1
						}
					} else {
						if (fa > fb) {
							return -1
						}

						if (fa < fb) {
							return 1
						}
					}

					return 0
				}))

				setOrder(!order)
				break

			case "Message":
				setDataTableCopy(dataTableCopy.sort((a, b) => {
					let fa = a.alertMessage.toLocaleLowerCase();
					let fb = b.alertMessage.toLocaleLowerCase();

					if (!order) {
						if (fa < fb) {
							return -1
						}

						if (fa > fb) {
							return 1
						}
					} else {
						if (fa > fb) {
							return -1
						}

						if (fa < fb) {
							return 1
						}
					}

					return 0
				}))
				setOrder(!order)
				break

			case "Date":
				setDataTableCopy(dataTableCopy.sort((a, b) => {
					let da = new Date(a.createdAt);
					let db = new Date(b.createdAt);
					if (!order) {
						return da - db
					} else {
						return db - da
					}
				}))
				setOrder(!order)
				break

			default:
				break;
		}
	}

	// FUNCTIONS TO ADD FILTERS
	const addTypeOfAlert = (target) => {
		if (typeOfAlertFilter.some(valueToFind => valueToFind === target.value)) {
			typeOfAlertFilter.splice(typeOfAlertFilter.findIndex(valueToFind => valueToFind === target.value), 1)
			setTypeOfAlertFilter(typeOfAlertFilter)
		} else {
			typeOfAlertFilter.push(target.value)
			setTypeOfAlertFilter(typeOfAlertFilter)
		}
	}

	// FUNCTION TO GET ALL CLIENTS
	const getSupplierAlerts = () => {
		Swal.fire({
			footer: 'Loading, please wait',
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			didOpen: () => {
				Swal.showLoading()
			},
		})

		axios.get(URI_SUPPLIER_ALERT + userLogged.dataID).then(response => {
			setDataTable(response.data.error ? [] : response.data)
			setDataTableCopy(response.data.error ? [] : response.data)
			Swal.close()
		}).catch(err => {
			console.log("Error al obtener los clientes" + err)
			Swal.fire({
				icon: 'error',
				text: 'Failed to fetch the clients',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
		})
	}

	// FUNCTION TO FILTER THE CLIENTS BY INPUT SEARCH
	const searchFilter = (value) => {
		setCurrentPage(1)
		let dataFiltered = []
		dataFiltered = dataTable.filter(prevState => {
			if (prevState.entityName.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
				return prevState
			}
		})

		setDataTableCopy(dataFiltered)
	}

	// FUNCTION TO FILTER THE CLIENTS BY FILTER BUTTONS
	const filterData = () => {
		setSelectedAlerts([])
		const generalSearch = document.getElementById("generalSearch")
		generalSearch.value = ""

		let dataFiltered = JSON.parse(JSON.stringify(dataTable));

		if (typeOfAlertFilter.length > 0) {
			dataFiltered = dataFiltered.filter(prevState => {
				return typeOfAlertFilter.includes(prevState.typeOfAlert.toUpperCase())
			})

			setTypeOfAlertIsFiltered(true)
		}

		setDataTableCopy(dataFiltered)
	}

	// FUNCTION TO CLEAR THE CLIENTS BY FILTER BUTTONS AND RESET ALL ON DEFAULT CASE
	const clearFilter = (filter) => {
		switch (filter) {
			case "typeOfAlert":
				const typeOfAlertsChecks = Array.prototype.slice.call(document.getElementsByClassName("statusCheck"))

				setTypeOfAlertFilter([])
				typeOfAlertsChecks.forEach(element => {
					element.checked = false
				});
				setTypeOfAlertIsFiltered(false)
				setHardFilterReset(true)
				break;

			default:
				clearFilter("typeOfAlert")
				break;
		}
	}

	// FUNCTION TO CREATE THE TYPE OF ALERT FILTER
	const createTypeFilter = () => {
		const typeFilterCopy = new Set(dataTable.flatMap(alert => {
			return alert.typeOfAlert.toUpperCase()
		}))

		return Array.from(typeFilterCopy)
	}

	// FUNCTION TO CONTROL THE SELECTED ALERTS
	const selectAlert = (alertID, checked) => {
		const selectedAlertsCopy = JSON.parse(JSON.stringify(selectedAlerts))

		if (!checked) {
			selectedAlertsCopy.splice(selectedAlertsCopy.findIndex(valueToFind => valueToFind === alertID), 1)
		} else {
			selectedAlertsCopy.push(alertID)
		}
		setSelectedAlerts(selectedAlertsCopy)
	}

	// FUNCTION TO UPDATE THE ALERT STATUS
	const changeAlertStatus = (action) => {
		let titleSweetAlert = null
		let selectedAlertsCopy = null
		let readAlerts = false
		let deleteAlerts = false

		switch (action) {
			case "all":
				titleSweetAlert = "Are you sure you want to read all the alerts?"
				selectedAlertsCopy = dataTableCopy.map(alert => alert._id)
				readAlerts = true

				break;

			case "read":
				titleSweetAlert = "Are you sure you want to read the selected alerts?"
				selectedAlertsCopy = [...selectedAlerts]
				readAlerts = true

				break;

			case "unread":
				titleSweetAlert = "Are you sure you want to unread the selected alerts?"
				selectedAlertsCopy = [...selectedAlerts]

				break;

			case "delete":
				titleSweetAlert = "Are you sure you want to delete the selected alerts?"
				selectedAlertsCopy = [...selectedAlerts]
				deleteAlerts = true

				break;

			default:
				break;
		}

		Swal.fire({
			title: titleSweetAlert,
			icon: "warning",
			showCancelButton: true,
			reverseButtons: true,
			confirmButtonText: "Yes, please",
		}).then(async (result) => {
			if (result.isConfirmed) {
				if (!deleteAlerts) {
					const response = await axios.put(URI_SUPPLIER_ALERT, { selectedAlerts: selectedAlertsCopy, readAlerts })
				} else {
					const response = await axios.delete(URI_SUPPLIER_ALERT, { data: selectedAlertsCopy })
				}

				setSelectedAlerts([])
				getSupplierAlerts()
			}
		});
	}

	return (
		<section className="clientAlerts">
			<div className="titleAndSearch">
				<h3 className="title">My Alerts</h3>
				<div className="searchAndSort">
					<div className="searchContainer">
						<input id="generalSearch" type="text" placeholder="Search by name..."
							onFocus={() => clearFilter()} onChange={(e) => searchFilter(e.target.value)} />
					</div>
					<button ref={sortMenuRef} className="d-lg-none sortMobile" onClick={() => setShowSortMenu(true)}>Sort</button>
					<div className={`d-lg-none sortDataContainer ${showSortMenu ? "show" : ""}`}>
						<h5 className="title">Sort</h5>
						<span onClick={() => { sortTable(false, "Client") }}>Client name (ascending)</span>
						<span onClick={() => { sortTable(true, "Client") }}>Client name (descending)</span>
						<span onClick={() => { sortTable(false, "Type") }}>Type of alert (ascending)</span>
						<span onClick={() => { sortTable(true, "Type") }}>Type of alert (descending)</span>
						<span onClick={() => { sortTable(false, "Message") }}>Message (ascending)</span>
						<span onClick={() => { sortTable(true, "Message") }}>Message (descending)</span>
						<span onClick={() => { sortTable(false, "Date") }}>Date created (ascending)</span>
						<span onClick={() => { sortTable(true, "Date") }}>Date created (descending)</span>
					</div>
				</div>
			</div>
			<div className="filters">
				<div className={`filter ${typeOfAlertIsFiltered ? "active" : undefined}`} onClick={(e) => filterRef.current = e.target}>
					<img src={typeOfAlert} alt="Prodensa Supplier" />
					<span>Type of alert</span>
					<div className="filterOptions" onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
						<span className="title">
							<img className="d-lg-none imgMenu" src={typeOfAlert} alt="Prodensa Supplier" />
							Type of alert
						</span>
						<div className="optionsScroll">
							{createTypeFilter().map((typeFilter, index) => (
								<div key={index} className="option">
									<input className="statusCheck" value={typeFilter} type="checkbox" id={`typeOfAlert${index}`} onClick={(e) => addTypeOfAlert(e.target)} />
									<label htmlFor={`typeOfAlert${index}`}>{typeFilter}</label>
								</div>
							))}
						</div>
						<div className="actions">
							<button onClick={() => clearFilter("typeOfAlert")}>Clear</button>
							<button onClick={() => filterData()}>Apply</button>
						</div>
					</div>
				</div>
				<span className={`resetAll ${resetAll ? "show" : ""}`} onClick={() => clearFilter()}>Reset All</span>
				<div className="alertActions">
					<button className="btnBgBlueTextGradient" onClick={() => changeAlertStatus("all")}>Read all</button>
					<button className={`btnBgBlueTextGradient ${selectedAlerts.length < 1 ? "hide" : ""}`} onClick={() => changeAlertStatus("read")}>Mark as read</button>
					<button className={`btnBgBlueTextGradient ${selectedAlerts.length < 1 ? "hide" : ""}`} onClick={() => changeAlertStatus("unread")}>Mark as unread</button>
					<button className={`btnBgBlueTextGradient ${selectedAlerts.length < 1 ? "hide" : ""}`} onClick={() => changeAlertStatus("delete")}></button>
				</div>
			</div>
			<span className={`resetAll ${resetAll ? "show" : ""} d-lg-none`} onClick={() => clearFilter()}>Reset All</span>

			<table className="tableClientAlerts">
				<thead>
					<tr>
						<th></th>
						<th>
							<span>Client name</span>
							<img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Client")} />
						</th>
						<th>
							<span>Type of alert</span>
							<img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Type")} />
						</th>
						<th>
							<span>Message</span>
							<img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Message")} />
						</th>
						<th>
							<span>Date created</span>
							<img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Date")} />
						</th>
					</tr>
				</thead>
				<tbody>
					{currentItems.map((alert, index) => (
						<tr key={index}>
							<td>
								<div className="infoContainer">
									<input type="checkbox" onChange={(e) => selectAlert(alert._id, e.target.checked)}
										checked={selectedAlerts.includes(alert._id)}
									/>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span className={`${!alert.alertReaded ? "unreaded" : ""}`}>
										{alert.entityName}
									</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span className="pendingAlone">
										{alert.typeOfAlert}
									</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{alert.alertMessage}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{moment(alert.createdAt).format("DD MMMM YYYY")}</span>
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>

			<div className="d-lg-none clientAlertsList">
				{currentItems.map((alert, index) => (
					<div key={index} className="clientRow">
						<input type="checkbox" onChange={(e) => selectAlert(alert._id, e.target.checked)}
							checked={selectedAlerts.includes(alert._id)}
						/>
						<h5 className={`clientName ${!alert.alertReaded ? "unreaded" : ""}`}>{alert.entityName}</h5>
						<div className="clientInformation">
							<span className="clientValue pendingAlone">{alert.typeOfAlert}</span>
							<span className="clientTitle">Type of alert</span>
							<span className="clientValue">{moment(alert.createdAt).format("DD MMMM YYYY")}</span>
							<span className="clientTitle">Date created</span>
							<span className="clientValue">{alert.alertMessage}</span>
							<span className="clientTitle">Message</span>
						</div>
					</div>
				))}
			</div>

			<div className="addAndPagination">
				<div className="pagination">
					{Array.from({ length: totalPages }).map((_, index) => (
						<button key={index} className={`number ${currentPage === index + 1 ? 'active' : ''}`} onClick={() => paginate(index + 1)}>
							{index + 1}
						</button>
					))}
					<img className="nextPage" src={next} alt="Prodensa Supplier" onClick={() => currentPage < totalPages ? setCurrentPage(currentPage + 1) : ""} />
				</div>
			</div>
			<span className="tableCount">{currentItems.length < 19 ? currentItems.length : 20 * currentPage} of {dataTable.length}</span>

			<div className="alertActions d-lg-none">
				<button className="btnBgBlueTextGradient" onClick={() => changeAlertStatus("all")}>Read all</button>
				<button className={`btnBgBlueTextGradient ${selectedAlerts.length < 1 ? "hide" : ""}`} onClick={() => changeAlertStatus("read")}>Mark as read</button>
				<button className={`btnBgBlueTextGradient ${selectedAlerts.length < 1 ? "hide" : ""}`} onClick={() => changeAlertStatus("unread")}>Mark as unread</button>
				<button className={`btnBgBlueTextGradient ${selectedAlerts.length < 1 ? "hide" : ""}`} onClick={() => changeAlertStatus("delete")}></button>
			</div>
		</section>
	)
}