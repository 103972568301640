import "../../assets/css/clientAlerts/contractList.css"

import sortIcon from "../../assets/images/sort.svg"
import person from "../../assets/images/person.svg"
import next from "../../assets/images/arrowForward.svg"
import reviewed from "../../assets/images/viewProfile.svg"

import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import Swal from "sweetalert2"

export default function ContractList(props) {
    // VARIABLE TO CONTROL DATA FROM TABLE
    const [dataTable, setDataTable] = useState([])
    const [dataTableCopy, setDataTableCopy] = useState([])

    const [currentPage, setCurrentPage] = useState(1)

    let totalPages = Math.ceil(dataTableCopy?.length / 8);
    let indexOfLastItem = +currentPage * 8;
    let indexOfFirstItem = indexOfLastItem - 8;
    let currentItems = dataTableCopy?.slice(indexOfFirstItem, indexOfLastItem);

    let paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // VARAIBLE TO CONTROL THE SORT ORDER
    const [order, setOrder] = useState(true)

    // VARIABLE TO MANAGE FILTER AND MOBILE SORT MENU
    const filterRef = useRef(null)

    // VARIABLES TO FILTER DATA
    const [supplierFilter, setSupplierFilter] = useState([])

    // VARIABLES TO KNOW IF THE FILTER IS ON
    const [supplierIsFiltered, setSupplierIsFiltered] = useState(false)

    // VARIABLE TO MANAGE THE NAVIGATE TO OTHER COMPONENTS
    const navigate = useNavigate()

    const mainStyle = {
		background: props.darkLightMode ? "#131E29" : "white",
		color: props.darkLightMode ? "white" : "inherit"
	}

    // useEffect to add body listener
    useEffect(() => {
        document.body.addEventListener("click", closeMenusFilters)

        return function cleanUp() {
            document.body.removeEventListener("click", closeMenusFilters)
        }
    }, [])

    useEffect(() => {
        clearFilter()
        setCurrentPage(1)
        setDataTable(props.suppliersContracts)
        setDataTableCopy(props.suppliersContracts)
    }, [props.suppliersContracts])

    // FUNCTION TO CLOSE MENU FILTERS
    const closeMenusFilters = (e) => {
        let filtersContainer;
        let menuFiltersContainer;

        if (filterRef.current && filterRef.current.lastElementChild && filterRef.current.contains(e.target)) {
            filtersContainer = Array.prototype.slice.call(document.getElementsByClassName("contractsFilter"))
            filtersContainer.map(element => {
                if (!filterRef.current.contains(element)) {
                    element.classList.remove("show")
                }
            })
            filterRef.current.classList.toggle("show")

            menuFiltersContainer = Array.prototype.slice.call(document.getElementsByClassName("contractsFilterOptions"))
            menuFiltersContainer.map(element => {
                if (!filterRef.current.lastElementChild.contains(element)) {
                    element.classList.remove("show")
                }
            })
            filterRef.current.lastElementChild.classList.toggle("show")
        } else {
            filtersContainer = Array.prototype.slice.call(document.getElementsByClassName("contractsFilter"))
            filtersContainer.map(element => {
                element.classList.remove("show")
            })

            menuFiltersContainer = Array.prototype.slice.call(document.getElementsByClassName("contractsFilterOptions"))
            menuFiltersContainer.map(element => {
                element.classList.remove("show")
            })
        }
    }

    // FUNCTIONS TO ADD FILTERS
    const addSupplier = (target) => {
        if (supplierFilter.some(valueToFind => valueToFind === target.value)) {
            supplierFilter.splice(supplierFilter.findIndex(valueToFind => valueToFind === target.value), 1)
            setSupplierFilter(supplierFilter)
        } else {
            supplierFilter.push(target.value)
            setSupplierFilter(supplierFilter)
        }
    }

    // FUNCTION TO FILTER THE SUPPLIER DIRECTORY BY FILTER BUTTONS
    const filterData = () => {
        let dataFiltered = JSON.parse(JSON.stringify(dataTable));

        if (supplierFilter.length > 0) {
            dataFiltered = dataFiltered.filter(prevState => {
                return supplierFilter.includes(prevState.companyName)
            })

            setSupplierIsFiltered(true)
        }

        setDataTableCopy(dataFiltered)
    }

    // FUNCTION TO CLEAR THE SUPPLIER DIRECTORY BY FILTER BUTTONS AND RESET ALL ON DEFAULT CASE
    const clearFilter = (filter) => {
        switch (filter) {
            case "supplier":
                const supplierChecks = Array.prototype.slice.call(document.getElementsByClassName("supplierCheck"))

                setSupplierFilter([])
                supplierChecks.forEach(element => {
                    element.checked = false
                });
                setSupplierIsFiltered(false)
                break;

            default:
                clearFilter("supplier")
                setDataTableCopy(dataTable)
                break;
        }
    }

    // FUNCTION TO CREATE THE INDUSTRY LIST TO SHOW ON FILTER BUTTON
    const createSupplierFilter = () => {
        const filterSupplier = new Set(dataTable?.flatMap(supplier => {
            return supplier.companyName
        }))

        return Array.from(filterSupplier).sort()
    }

    // FUNCTION TO SORT TABLE COLUMN
    const sortTable = (order, sortBy) => {
        // ORDER FALSE = ASC; ORDER TRUE = DSC 
        switch (sortBy) {
            case "Name":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.companyName.toLocaleLowerCase();
                    let fb = b.companyName.toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))

                setOrder(!order)
                break

            case "Status":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.status.toLocaleLowerCase();
                    let fb = b.status.toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))

                setOrder(!order)
                break

            case "Client":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.entityName.toLocaleLowerCase();
                    let fb = b.entityName.toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))

                setOrder(!order)
                break

            case "ContractName":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.contractName.toLocaleLowerCase();
                    let fb = b.contractName.toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))

                setOrder(!order)
                break

            case "ContractStatus":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.contractStatus.toLocaleLowerCase();
                    let fb = b.contractStatus.toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))

                setOrder(!order)
                break

            case "Expiration":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let da = new Date(a.contractExpire);
                    let db = new Date(b.contractExpire);
                    if (!order) {
                        return da - db
                    } else {
                        return db - da
                    }
                }))

                setOrder(!order)
                break
            default:
                break;
        }
    }

    // FUNTION TO CONFIRM THE REVIEW CONTRACT
    const confirmReviewContract = (supplierID, contractID) => {
        Swal.fire({
            title: "Are you sure you want to change this contract status to reviewed?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes, please!",
        }).then((result) => {
            if (result.isConfirmed) {
                props.reviewContract(supplierID, contractID)
            }
        });
    }

    return (
        <section className={`contracts ${props.darkLightMode ? "darkMode" : null}`}>
            <div className="filters">
                <div className={`contractsFilter ${supplierIsFiltered ? "active" : undefined}`} onClick={(e) => filterRef.current = e.target}>
                    <img src={person} alt="Prodensa Supplier" />
                    <span>Supplier</span>
                    <div className="contractsFilterOptions" onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
                        <span className="title">
                            <img className="d-lg-none imgMenu" src={person} alt="Prodensa Supplier" />
                            Supplier
                        </span>
                        <div className="optionsScroll">
                            {createSupplierFilter().map((supplier, index) => {
                                return (
                                    <div key={index} className="option">
                                        <input className="supplierCheck" value={supplier} type="checkbox" id={`supplier${index}`} onClick={(e) => addSupplier(e.target)} />
                                        <label htmlFor={`supplier${index}`} >{supplier}</label>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="actions">
                            <button onClick={() => clearFilter("supplier")}>Clear</button>
                            <button onClick={() => filterData()}>Apply</button>
                        </div>
                    </div>
                </div>
            </div>

            <table className="tableContracts">
                <thead>
                    <tr>
                        <th style={mainStyle}>
                            <span>Name</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Name")} />
                        </th>
                        <th style={mainStyle}>
                            <span>Status</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Status")} />
                        </th>
                        <th style={mainStyle}>
                            <span>Client</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Client")} />
                        </th>
                        <th style={mainStyle}>
                            <span>Contract Name</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "ContractName")} />
                        </th>
                        <th style={mainStyle}>
                            <span>Contract Status</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "ContractStatus")} />
                        </th>
                        <th style={mainStyle}>
                            <span>Expiration</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Expiration")} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems?.map((supplier, index) => (
                        <tr key={index}>
                            <td>
                                <div className="infoContainer">
                                    <span onClick={() => navigate("/home/suppliers/supplierProfile/" + supplier.supplierID)}>
                                        {supplier.companyName}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span className={`${supplier.status === "Active" ? "success" : ""} ${supplier.status === "Hold payment" ? "failedAlone" : ""} ${supplier.status === "Ready for review" ? "success" : ""} ${supplier.status === "Pre-authorized" || supplier.status === "Review requested" ? "pending" : ""}`}>
                                        {supplier.status}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{supplier.entityName}</span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{supplier.contractName}</span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span className={`${supplier.contractStatus === "Active" ? "successAlone" : "failedAlone"}`}>
                                        {supplier.contractStatus}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{moment(supplier.contractExpire).format("DD MMMM YYYY")}</span>
                                    {supplier.contractStatus === "Expired" && <img className="reviewed" src={reviewed} alt="Prodensa Supplier" onClick={() => confirmReviewContract(supplier.supplierID, supplier.contractID)} />}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="d-lg-none contractList">
                {currentItems?.map((supplier, index) => (
                    <div key={index} className="contractRow" onClick={() => navigate("/home/suppliers/supplierProfile/" + supplier.supplierID)}>
                        <h5 className="contractName">
                            {supplier.companyName}
                            {supplier.contractStatus === "Expired" && <img className="reviewed" src={reviewed} alt="Prodensa Supplier" onClick={(e) => { e.stopPropagation(); confirmReviewContract(supplier.supplierID, supplier.contractID) }} />}
                        </h5>
                        <div className="contractInformation">
                            <span className={`contractValue ${supplier.status === "Active" ? "success" : ""} ${supplier.status === "Hold payment" ? "failedAlone" : ""} ${supplier.status === "Ready for review" ? "success" : ""} ${supplier.status === "Pre-authorized" || supplier.status === "Review requested" ? "pending" : ""}`}>{supplier.status}</span>
                            <span className="contractTitle">Status</span>
                            <span className="contractValue">{supplier.entityName}</span>
                            <span className="contractTitle">Client</span>
                            <span className="contractValue">{supplier.contractName}</span>
                            <span className="contractTitle">Contract Name</span>
                            <span className={`contractValue ${supplier.contractStatus === "Active" ? "successAlone" : "failedAlone"}`}>{supplier.contractStatus}</span>
                            <span className="contractTitle">Contract Status</span>
                            <span className="contractValue">{moment(supplier.contractExpire).format("DD MMMM YYYY")}</span>
                            <span className="contractTitle">Expiration</span>
                        </div>
                    </div>
                ))}
            </div>

            <div className="addAndPagination">
                <div className="pagination">
                    {Array.from({ length: totalPages }).map((_, index) => (
                        <button key={index} className={`number ${+currentPage === index + 1 ? 'active' : ''}`} onClick={() => paginate(index + 1)}>
                            {index + 1}
                        </button>
                    ))}
                    <img className="nextPage" src={next} alt="Prodensa Supplier" onClick={() => +currentPage < totalPages ? paginate(+currentPage + 1) : undefined} />
                </div>
            </div>
            <span className="tableCount">{currentItems?.length < 7 ? currentItems?.length : 8 * +currentPage} of {dataTable?.length}</span>
        </section>
    )
}