import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import "../../assets/css/securityStaff/addNewSecurityGuardModal.css";
import close from "../../assets/images/close.svg";
import clipIcon from "../../assets/images/attachFile.svg";
import { useAuth } from "../../context/auth-user";

function AddNewSecurityGuardModal({
    clientEntitiesMap,
    companies,
    show,
    onHide,
    fetchSecurityGuards
}) {
    const { user } = useAuth()
    const userLogged = user?.selectedProfile;

    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [company, setCompany] = useState("");
    const [entity, setEntity] = useState("");
    const [profilePicture, setProfilePicture] = useState("");
    const [isChanged, setIsChanged] = useState(false);
    const [fileName, setFileName] = useState("");
    const profilePhotoRef = useRef(null);

    const [isOpenCompanyDropdown, setIsOpenCompanyDropdown] = useState(false);
    const [companyName, setCompanyName] = useState('Select a company');

    const [isOpenEntityDropdown, setIsOpenEntityDropdown] = useState(false);
    const [entityName, setEntityName] = useState('Select an entity');
    const [entityRfc, setEntityRfc] = useState(null);

    useEffect(() => {
        if (show) {
            // Lógica para rol "Admin client"
            if (userLogged?.rol === "Admin client" || userLogged?.rol === "Admin Security manager") {
                if (companies.length === 1) {
                    const defaultCompany = companies[0];
                    setCompany(defaultCompany.id);
                    setCompanyName(defaultCompany.name);
                }
            }

            // Lógica para rol "Client"
            if (userLogged?.rol === "Client" || userLogged?.rol === "Security manager") {
                if (companies.length === 1) {
                    const defaultCompany = companies[0];
                    setCompany(defaultCompany.id);
                    setCompanyName(defaultCompany.name);

                    const defaultEntities = clientEntitiesMap[defaultCompany.id] || [];
                    if (defaultEntities.length === 1) {
                        const defaultEntity = defaultEntities[0];
                        setEntity(defaultEntity.id);
                        setEntityName(defaultEntity.name);
                        setEntityRfc(defaultEntity.rfc);
                    }
                }
            }
        }
    }, [show, userLogged?.rol, companies, clientEntitiesMap]);

    const toggleCompanyDropdown = () => {
        setIsOpenCompanyDropdown(!isOpenCompanyDropdown);
    };

    const toggleEntityDropdown = () => {
        setIsOpenEntityDropdown(!isOpenEntityDropdown);
    };

    const handleCompanySelect = (value, name) => {
        setCompany(value);
        setCompanyName(name);
        setEntity(""); // Resetea la entidad seleccionada
        setEntityName("Select an entity"); // Resetea el nombre de la entidad
        setIsOpenCompanyDropdown(false);
    };

    const handleEntitySelect = (value, name, rfc) => {
        setEntity(value);
        setEntityName(name);
        setEntityRfc(rfc);
        setIsOpenEntityDropdown(false);
    };

    const handleOpenFileExplorer = () => {
        if (!profilePhotoRef.current) return;
        profilePhotoRef.current.click();
    };

    const handlePhotoChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onload = () => {
                setProfilePicture(reader.result);
                setFileName(file.name); // Guarda el nombre del archivo
                setIsChanged(true);
            };
            reader.readAsDataURL(file);
        } else {
            Swal.fire({
                icon: 'error',
                text: "Please select a valid image file.",
                showConfirmButton: false,
                timer: 3000,
            });
            setFileName(""); // Resetea el nombre del archivo en caso de error
        }
    };


    const addEmployee = () => {
        if (!name) {
            Swal.fire({
                icon: 'warning',
                text: "Please enter the name of the security guard.",
                showConfirmButton: false,
                timer: 3000,
            });
            return;
        }

        // Formatear el nombre: todas las letras minúsculas, la primera letra de cada palabra en mayúsculas
        const formattedName = name
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        if (!username) {
            Swal.fire({
                icon: 'warning',
                text: "Please enter a username.",
                showConfirmButton: false,
                timer: 3000,
            });
            return;
        }

        // Asegurar que el username esté en minúsculas
        const formattedUsername = username.toLowerCase();

        if (!company) {
            Swal.fire({
                icon: 'warning',
                text: "Please select a company.",
                showConfirmButton: false,
                timer: 3000,
            });
            return;
        }

        if (!entity) {
            Swal.fire({
                icon: 'warning',
                text: "Please select an entity.",
                showConfirmButton: false,
                timer: 3000,
            });
            return;
        }

        const dataID = userLogged.dataID;
        const rol = userLogged.rol;

        const securityGuard = {
            name: formattedName,
            username: formattedUsername,
            clientId: company,
            entityId: entity,
            profilePicture,
        };

        const formData = new FormData();
        formData.append("dataID", dataID);
        formData.append("rol", rol);
        formData.append("rfc", entityRfc);
        formData.append("securityGuard", JSON.stringify(securityGuard));
        if (profilePhotoRef.current?.files[0]) {
            formData.append("document", profilePhotoRef.current.files[0]);
        }

        fetch(process.env.REACT_APP_URI_CREATE_SECURITY_STAFF, {
            method: "POST",
            body: formData
        })
            .then(response => response.json())
            .then(result => {
                if (result.error) {
                    Swal.fire({
                        icon: 'error',
                        text: result.error,
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                } else {
                    fetchSecurityGuards();
                    cancelEmployee();
                }
            })
            .catch(error => {
                console.error("Error creating security guard:", error);
                Swal.fire({
                    icon: 'error',
                    text: "Failed to save security guard information.",
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                });
            });
    };


    const cancelEmployee = () => {
        setName("");
        setUsername("");
        setCompany("");
        setEntity("");
        setCompanyName("Select a company"); // Reiniciar el nombre de la empresa
        setEntityName("Select an entity");  // Reiniciar el nombre de la entidad
        setFileName("");
        setProfilePicture("");
        setIsChanged(false);
        onHide();
    };


    const handleModalHide = () => {
        cancelEmployee();
        onHide();
    };

    const filteredEntities = clientEntitiesMap[company] || [];

    return (
        <Modal
            className="staffModal"
            size="lg"
            centered
            show={show}
            onHide={handleModalHide}
        >
            <Modal.Body>
                <div className="addStaff">
                    <img className="closeModal" src={close} onClick={handleModalHide} alt="Close" />
                    <h4 className="title">Add a new security guard</h4>

                    <div className="inputContainer">
                        <span className="label">Name</span>
                        <input value={name} type="text" placeholder="Name of security guard" onChange={(e) => setName(e.target.value)} />
                    </div>

                    <div className="inputContainer">
                        <span className="label">Username</span>
                        <input value={username} type="text" placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
                    </div>

                    {(userLogged?.rol === "Master" || userLogged?.rol === "Super admin") && (
                        <div className="inputContainer">
                            <span className="label">Company</span>
                            <div className="custom-select-wrapper">
                                <div className={`custom-select ${isOpenCompanyDropdown ? 'open' : ''}`} onClick={toggleCompanyDropdown}>
                                    <div className="custom-select-trigger">{companyName}</div>
                                    <div className="custom-options">
                                        {companies.map((comp) => (
                                            <span
                                                key={comp.id}
                                                className="custom-option"
                                                data-value={comp.id}
                                                onClick={() => handleCompanySelect(comp.id, comp.name)}
                                            >
                                                {comp.name}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {(userLogged?.rol !== "Client" && userLogged?.rol !== "Security manager") && (
                        <div className="inputContainer">
                            <span className="label">Entity</span>
                            <div className="custom-select-wrapper">
                                <div className={`custom-select ${isOpenEntityDropdown ? 'open' : ''}`} onClick={toggleEntityDropdown}>
                                    <div className="custom-select-trigger">{entityName}</div>
                                    <div className="custom-options">
                                        {filteredEntities.map((ent) => (
                                            <span
                                                key={ent.id}
                                                className="custom-option"
                                                data-value={ent.id}
                                                onClick={() => handleEntitySelect(ent.id, ent.name, ent.rfc)}
                                            >
                                                {ent.name}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="inputContainer">
                        <span className="label">Profile picture <label>Upload (5MB max)</label></span>
                        <input
                            type="file"
                            ref={profilePhotoRef}
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={handlePhotoChange}
                        />
                        <div className="selectFileContainer">
                            <button className="selectFile" onClick={handleOpenFileExplorer}>
                                Select file
                                <img className="clipIcon" src={clipIcon} alt="Clip icon" />
                            </button>
                            {fileName && <span className="fileName">{fileName}</span>}
                        </div>
                    </div>


                    <div className="actionButtons">
                        <button className="cancelButton" onClick={cancelEmployee}>Cancel</button>
                        <button className={`addButton`} onClick={addEmployee}>
                            Add employee
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AddNewSecurityGuardModal;
