import "../../assets/css/requests/requests.css"

import client from "../../assets/images/clients.svg"
import industry from "../../assets/images/industries.svg"
import status from "../../assets/images/status.svg"
import locationImg from "../../assets/images/location.svg"
import certifications from "../../assets/images/certification.svg"
import sortIcon from "../../assets/images/sort.svg"
import printReport from "../../assets/images/printReport.svg"
import viewProfile from "../../assets/images/viewProfile.svg"
import next from "../../assets/images/arrowForward.svg"
import cvpBadge from "../../assets/images/CVPBagdeTable.png"

import close from "../../assets/images/close.svg"

import { useEffect, useRef, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import NewRequestModal from "./newRequest.jsx"
import axios from "axios"
import moment from "moment"
import Swal from "sweetalert2"
import { useAuth } from "../../context/auth-user.jsx"

const URI = process.env.REACT_APP_URI_SUPPLIERS_FILTER_BY_STATUS_ARRAY
const URI_CLIENT = process.env.REACT_APP_URI_CLIENT
const URI_SUPPLIER_NEW_REQUEST = process.env.REACT_APP_URI_SUPPLIERS_NEW_REQUEST
const URI_REPORT_SUPPLIER_REQUEST = process.env.REACT_APP_REPORT_SUPPLIER_REQUEST
const URI_UPLOADS = process.env.REACT_APP_URI_UPLOADS

export default function Requests({ darkLightMode }) {
    // VARIABLE TO KNOW WHOS IS LOGGED
    const { user } = useAuth()
    const userLogged = user?.selectedProfile

    // Check params
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const paramsCompanyName = searchParams.get("companyName");

    const cleanedUrl = "/home/requests"
    window.history.replaceState(null, "", cleanedUrl);

    // VARIABLE TO CONTROL DATA FROM TABLE
    const [dataTable, setDataTable] = useState([])
    const [dataTableCopy, setDataTableCopy] = useState([])

    const [currentPage, setCurrentPage] = useState(sessionStorage.getItem("currentPageRequest") ?? 1)

    let totalPages = Math.ceil(dataTableCopy.length / 20);
    let indexOfLastItem = +currentPage * 20;
    let indexOfFirstItem = indexOfLastItem - 20;
    let currentItems = dataTableCopy.slice(indexOfFirstItem, indexOfLastItem);

    let paginate = (pageNumber) => {
        sessionStorage.setItem("currentPageRequest", pageNumber)
        setCurrentPage(pageNumber);
    };

    // VARIABLE TO CONTROL THE CLIENTS FROM THE DATABASE
    const [clients, setClients] = useState(null)

    // VARIABLE TO MANAGE THE SUPPLIER NEW REQUEST TO CHANGE
    const [suppliersNewRequest, setSuppliersNewRequest] = useState([])

    // VARAIBLE TO CONTROL THE SORT ORDER
    const [order, setOrder] = useState(true)

    // VARIABLES TO CONTROL SORT MENU SHOW AND HIDE
    const [showSortMenu, setShowSortMenu] = useState(false)

    // VARIABLE TO MANAGE FILTER AND MOBILE SORT MENU
    const sortMenuRef = useRef(null)
    const filterRef = useRef(null)

    // VARIABLE TO CONTROL THE LIST OF LOCATIONS
    const [locationListCopy, setLocationListCopy] = useState([])

    // VARIABLES TO FILTER DATA
    const [clientFilter, setClientFilter] = useState(() => {
        const stored = localStorage.getItem("requestFilters") ?? JSON.stringify({})
        
        return JSON.parse(stored)?.client ?? []
    })
    const [entityFilter, setEntityFilter] = useState(() => {
        const stored = localStorage.getItem("requestFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.entity ?? []
    })
    const [industryFilter, setIndustryFilter] = useState(() => {
        const stored = localStorage.getItem("requestFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.industry ?? []
    })
    const [statusFilter, setStatusFilter] = useState(() => {
        const stored = localStorage.getItem("requestFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.status ?? []
    })
    const [locationFilter, setLocationFilter] = useState(() => {
        const stored = localStorage.getItem("requestFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.location ?? []
    })
    const [cvpFilter, setCvpFilter] = useState(() => {
        const stored = localStorage.getItem("requestFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.cvp ?? false
    })

    // VARIABLES TO KNOW IF THE FILTER IS ON
    const [clientIsFiltered, setClientIsFiltered] = useState(() => {
        const stored = localStorage.getItem("requestFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.client ? true : false
    })
    const [entityIsFiltered, setEntityIsFiltered] = useState(() => {
        const stored = localStorage.getItem("requestFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.entity ? true : false
    })
    const [industryIsFiltered, setIndustryIsFiltered] = useState(() => {
        const stored = localStorage.getItem("requestFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.industry ? true : false
    })
    const [statusIsFiltered, setStatusIsFiltered] = useState(() => {
        const stored = localStorage.getItem("requestFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.status ? true : false
    })
    const [locationIsFiltered, setLocationIsFiltered] = useState(() => {
        const stored = localStorage.getItem("requestFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.location ? true : false
    })
    const [cvpIsFiltered, setCvpIsFiltered] = useState(() => {
        const stored = localStorage.getItem("requestFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.cvp ? true : false
    })

    //VARIABLE TO CONTROL THE FILTER DATA AFTER CLEAR A SINGLE FILTER
    const [hardFilterData, setHardFilterReset] = useState(false)

    //VARIABLE TO SHOW RESET ALL FILTERS
    const [resetAll, setResetAll] = useState(false)

    // VARIABLE TO CONTROL GLOBAL SEARCH
    const [searchValue, setSearchValue] = useState(() => {
        const stored = localStorage.getItem("requestFilters")

        return paramsCompanyName ?? JSON.parse(stored)?.searchValue ?? ""
    })

    const [industrySearch, setIndustrySearch] = useState("")
    const [locationSearch, setLocationSearch] = useState("")

    const [openFilter, setOpenFilter] = useState(null)

    //VARIABLES TO CONTROL MODAL
    const [supplierID, setSupplierID] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [supplierClientID, setSupplierClientID] = useState(null)

    // VARIABLE TO NAVIGATE
    const navigate = useNavigate();

    const mainStyle = {
		background: darkLightMode ? "#131E29" : "white",
		color: darkLightMode ? "white" : "inherit"
	}

    // useEffect to add body listener
    useEffect(() => {
        if (!userLogged) return
        
        getAllNewRequests()
        getAllClients()

        window.onbeforeunload = clearSessionStorage;

        document.body.addEventListener("mousedown", closeMenusFilters)

        return function cleanUp() {
            window.onbeforeunload = null; // Cleanup event listener
            document.body.removeEventListener("mousedown", closeMenusFilters)
        }
    }, [userLogged])

    useEffect(() => {
        const savedFilters = Object.values(JSON.parse(localStorage.getItem("requestFilters")) ?? {}).filter(item => !(typeof item === "string")).length

        if (dataTable.length > 0 && savedFilters > 0) {
            filterData()
        }
    }, [dataTable])

    useEffect(() => {
        const savedSearchValue = JSON.parse(localStorage.getItem("requestFilters") ?? {})?.searchValue ?? ""

        if ((paramsCompanyName || savedSearchValue) && dataTable) {
            searchFilter(paramsCompanyName || savedSearchValue)
        }
    }, [paramsCompanyName, dataTable])

    // COMPARATION TO SHOW THE RESET ALL BUTTON
    useEffect(() => {
        if (clientIsFiltered || entityIsFiltered || industryIsFiltered || statusIsFiltered || locationIsFiltered || cvpIsFiltered) {
            setResetAll(true)
        } else {
            setResetAll(false)
        }
    }, [clientIsFiltered, entityIsFiltered, industryIsFiltered, statusIsFiltered, locationIsFiltered, cvpIsFiltered])

    // ACTION TO EXECUTE WHEN YOU CLEAR A SINGLE FILTER TO RESET THE TABLE
    useEffect(() => {
        if (hardFilterData) {
            filterData()
            setHardFilterReset(false)
        }
    }, [hardFilterData])

    // CLEAR SESSION STORAGE ON PAGE REFRESH
    const clearSessionStorage = () => {
        sessionStorage.removeItem('currentPageRequest');
    };

    // FUNCTION TO CLOSE MENU FILTERS
    const closeMenusFilters = (e) => {
        if (!filterRef?.current?.contains(e.target)) {
            setOpenFilter(null)
        }

        if (!sortMenuRef?.current?.contains(e.target)) {
            setShowSortMenu(false)
        }
    }

    const handleOpenFilter = (e, filterName) => {
        filterRef.current = e.target

        setOpenFilter(prev => {
            if (prev === filterName) return null

            return filterName
        })
    }

    // FUNCTION TO SORT TABLE COLUMN
    const sortTable = (order, sortBy) => {
        // ORDER FALSE = ASC; ORDER TRUE = DSC 
        switch (sortBy) {
            case "Name":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.companyName.toLocaleLowerCase();
                    let fb = b.companyName.toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))

                setOrder(!order)
                break

            case "Industry":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.industries[0].toLocaleLowerCase();
                    let fb = b.industries[0].toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))
                setOrder(!order)
                break

            case "Location":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.location.toLocaleLowerCase();
                    let fb = b.location.toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))
                setOrder(!order)
                break

            case "Status":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.entityStatus.toLocaleLowerCase();
                    let fb = b.entityStatus.toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))

                setOrder(!order)
                break

            case "Date":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let da = new Date(a.updatedAt);
                    let db = new Date(b.updatedAt);
                    if (!order) {
                        return da - db
                    } else {
                        return db - da
                    }
                }))
                setOrder(!order)
                break

            default:
                break;
        }
    }

    // FUNCTIONS TO ADD FILTERS
    const toggleFilterValue = (setFilter) => (target) => {
        setFilter(prev => {
            const existValue = prev.some(valueToFind => valueToFind === target.value);
    
            if (existValue) {
                return prev.filter(valueToFind => valueToFind !== target.value);
            }
    
            return [...prev, target.value];
        });
    };

    const handleCvp = (value) => {
        setCvpFilter(value)
        setCvpIsFiltered(value)
        filterData(value)
    }
    
    // FUNCTIONS TO DISPLAY THE MODAL
    const activateModal = (data, clientID) => {
        setSupplierID(data)
        setShowModal(true)
        setSupplierClientID(clientID)
    }

    const handleCloseModal = () => {
        setShowModal(false)
        getAllNewRequests();
    }

    // FUNCTION TO GET ALL NEW REQUESTS
    const getAllNewRequests = () => {
        Swal.fire({
            footer: 'Loading, please wait',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })

        axios.post(URI, {
            status: ["New request", "Pre-authorized", "Ready for review", "Review requested"],
            rol: userLogged && userLogged.rol,
            clientID: userLogged && userLogged.dataID
        }).then(response => {
            setDataTable(response.data)
            setDataTableCopy(response.data)
            createLocationFilter(response.data)

            Swal.close()
        }).catch(err => {
            console.log("Error al obtener todas las nuevas solicitudes" + err)
        })
    }

    // FUNCTION TO GET ALL CLIENTS
    const getAllClients = () => {
        axios.get(URI_CLIENT).then(response => {
            setClients(response.data)
        }).catch(err => {
            console.log("Error al obtener los clientes")
        })
    }

    // FUNTION TO GET THE TABLE REPORT
    const getTableReport = () => {
        Swal.fire({
            footer: 'Loading, please wait',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })

        axios.post(URI_REPORT_SUPPLIER_REQUEST, {
            dataTable: dataTableCopy,
            filters: {
                clientFilter: clientFilter,
                entityFilter: entityFilter,
                industryFilter: industryFilter,
                statusFilter: statusFilter,
                locationFilter: locationFilter
            }
        }).then(response => {
            Swal.fire({
                icon: 'success',
                text: `${response.data.message}`,
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
            })
            window.open(URI_UPLOADS + response.data.path, "_blank")
        }).catch(err => {
            Swal.fire({
                icon: 'error',
                text: 'Failed to generate the report',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
            })
        })
    }

    // FUNCTION TO FILTER THE SUPPLIER REQUEST BY INPUT SEARCH
    const searchFilter = (value) => {
        setSearchValue(value)

        // SAVE VALUE IN LOCAL STORAGE
        const searchValueToLocal = {
            searchValue: value
        }

        localStorage.setItem("requestFilters", JSON.stringify(searchValueToLocal))
        
        setCurrentPage(1)
        let dataFiltered = JSON.parse(JSON.stringify(dataTable));

        dataFiltered = dataFiltered.filter(prevState => {
            const metCondition = prevState.companyName.toLocaleLowerCase().includes(value.toLocaleLowerCase()) || 
            prevState.description.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
            prevState.contactInformation?.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
            prevState.rfc.toLocaleLowerCase().includes(value.toLocaleLowerCase())

            if (metCondition) {
                return prevState
            }
        })

        setDataTableCopy(dataFiltered)
    }

    // FUNCTION TO FILTER THE SUPPLIER REQUEST BY FILTER BUTTONS
    const filterData = (cvpValue) => {
        const saveFilters = {}

        let dataFiltered = JSON.parse(JSON.stringify(dataTable));

        if (clientFilter.length > 0) {
            const adminClientEntitiesID = clients.filter(client => clientFilter.includes(client._id)).flatMap(client => client.entities.map(entity => entity._id))

            dataFiltered = dataFiltered.filter(supplier => {
                return adminClientEntitiesID.includes(supplier.entityID)
            })

            setClientIsFiltered(true)
            saveFilters.client = clientFilter
        }

        if (entityFilter.length > 0) {
            dataFiltered = dataFiltered.filter(supplier => {
                return entityFilter.includes(supplier.entityID)
            })

            setEntityIsFiltered(true)
            saveFilters.entity = entityFilter
        }
        
        if (industryFilter.length > 0) {
            dataFiltered = dataFiltered.filter(supplier => {
                return supplier.industries.some(category => {
                    return industryFilter.includes(category)
                })
            })
            
            setIndustryIsFiltered(true)
            saveFilters.industry = industryFilter
        }
        
        if (statusFilter.length > 0) {
            dataFiltered = dataFiltered.filter(supplier => {
                return statusFilter.includes(supplier.entityStatus)
            })
            
            setStatusIsFiltered(true)
            saveFilters.status = statusFilter
        }
        
        if (locationFilter.length > 0) {
            dataFiltered = dataFiltered.filter(supplier => {
                return locationFilter.includes(supplier.location)
            })
            
            setLocationIsFiltered(true)
            saveFilters.location = locationFilter
        }

        if (cvpValue || (cvpValue === undefined && cvpFilter)) {
            dataFiltered = dataFiltered.filter(supplier => {
                return supplier?.certified
            })

            saveFilters.cvp = cvpValue || cvpFilter
        }

        setSearchValue("")
        setDataTableCopy(dataFiltered)
        localStorage.setItem("requestFilters", JSON.stringify(saveFilters))
    }

    // FUNCTION TO CLEAR THE SUPPLIER REQUEST BY FILTER BUTTONS AND RESET ALL ON DEFAULT CASE
    const clearFilter = (filter) => {
        setSearchValue("")
        switch (filter) {
            case "client":
                setClientFilter([])
                setClientIsFiltered(false)
                break;

            case "entity":
                setEntityFilter([])
                setEntityIsFiltered(false)
                break;

            case "industry":
                setIndustryFilter([])
                setIndustryIsFiltered(false)
                break;

            case "status":
                setStatusFilter([])
                setStatusIsFiltered(false)
                break;

            case "location":
                setLocationFilter([])
                setLocationIsFiltered(false)
                break;

            default:
                clearFilter("client")
                clearFilter("entity")
                clearFilter("industry")
                clearFilter("status")
                clearFilter("location")
                break;
        }

        setHardFilterReset(true)
    }

    // FUNCTION TO CREATE THE ENTITY LIST TO SHOW ON FILTER BUTTON
    const createEntityFilter = () => {
        let filterEntity

        switch (userLogged?.rol) {
            case "Master":
            case "Super admin":
                filterEntity = new Set(clients.filter(client => {
                    return clientFilter.includes(client._id)
                }).flatMap(client => client.entities.map(entity => entity)))

                return Array.from(filterEntity).sort()

            case "Admin client":
            case "Admin Compliance manager":
                filterEntity = new Set(clients.filter(client => {
                    return userLogged.dataID === client._id
                }).flatMap(client => client.entities.map(entity => entity)))

                return Array.from(filterEntity).sort()

            default:
                break;
        }
    }

    // FUNCTION TO CREATE THE INDUSTRY LIST TO SHOW ON FILTER BUTTON
    const createIndustryFilter = () => {
        const filterIndustry = new Set(dataTable.flatMap(supplier => {
            return supplier.industries.map(category => category)
        }))

        return Array.from(filterIndustry).sort().filter(item => !industrySearch || item.toLocaleLowerCase().includes(industrySearch.toLocaleLowerCase()))
    }

    // FUNCTION TO CREATE THE STATUS LIST TO SHOW ON FILTER BUTTON
    const createStatusFilter = () => {
        let filterStatus = []

        filterStatus = new Set(dataTable.flatMap(supplier => {
            return supplier.entityStatus
        }))

        return Array.from(filterStatus)
    }

    // FUNCTION TO CREATE THE LOCATION LIST TO SHOW ON FILTER BUTTON
    const createLocationFilter = (data) => {
        const filterLocation = new Set(data.map(supplier => {
            return supplier.location
        }))

        const locationList = Array.from(filterLocation).sort((a, b) => {
            if (a.toLocaleLowerCase() > b.toLocaleLowerCase()) {
                return 1
            }

            if (a.toLocaleLowerCase() < b.toLocaleLowerCase()) {
                return -1
            }

            return 0
        })

        setLocationListCopy(locationList)
    }

    // FUNCTION TO ADD THE SUPPLIER NEW REQUEST TO CHANGE STATUS
    const addSuppliersNewRequest = (flagState, supplierID, clientID) => {
        const suppliersToChange = JSON.parse(JSON.stringify(suppliersNewRequest))

        if (flagState) {
            if (suppliersToChange && suppliersToChange.some(supplier => supplier.supplierID === supplierID)) {
                suppliersToChange.find(supplier => supplier.supplierID === supplierID).clientIDs.push(clientID)
            } else {
                suppliersToChange.push({
                    supplierID: supplierID,
                    clientIDs: [clientID]
                })
            }
        } else {
            const clientIndexToRemove = suppliersToChange.find(supplier => supplier.supplierID === supplierID).clientIDs.indexOf(clientID)

            suppliersToChange.find(supplier => supplier.supplierID === supplierID).clientIDs.splice(clientIndexToRemove, 1)

            if (suppliersToChange.find(supplier => supplier.supplierID === supplierID).clientIDs.length === 0) {
                const supplierIndexToRemove = suppliersToChange.findIndex(supplier => supplier.supplierID === supplierID)

                suppliersToChange.splice(supplierIndexToRemove, 1)
            }
        }

        setSuppliersNewRequest(suppliersToChange)
    }

    // FUNCTION TO ADD ALL THE SUPPLIERS NEW REQUEST TO CHANGE STATUS
    const addAllSuppliersNewRequest = () => {
        let dataTable = JSON.parse(JSON.stringify(dataTableCopy))
        let suppliersToChange = []

        dataTable.filter(supplier => supplier.entityStatus === "New request").forEach(supplier => {
            if (suppliersToChange.some(supplierToChange => supplierToChange.supplierID === supplier.supplierID)) {
                suppliersToChange.find(supplierToChange => supplierToChange.supplierID === supplier.supplierID).clientIDs.push(supplier.entityID)
            } else {
                suppliersToChange.push({
                    supplierID: supplier.supplierID,
                    clientIDs: [supplier.entityID]
                })
            }
        })

        setSuppliersNewRequest(suppliersToChange)
    }

    // FUNCTION TO HANDLE THE SUPPLIER NEW REQUEST STATUS CHANGE
    const handleChangeSupplierNewRequest = () => {
        Swal.fire({
            title: `Are you sure you want to update (${suppliersNewRequest.flatMap(supplier => supplier.clientIDs).length}) suppliers selected?`,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes, update",
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    footer: 'Loading, please wait',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                })

                axios.post(URI_SUPPLIER_NEW_REQUEST, {
                    suppliersNewRequest: suppliersNewRequest
                }).then(response => {
                    getAllNewRequests()

                    Swal.fire({
                        icon: 'success',
                        text: 'Information submitted successfully',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    })
                }).catch(err => {
                    Swal.fire({
                        icon: 'error',
                        text: 'Failed to save information',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    })
                    console.log("Error al actualizar los suppliers" + err)
                })
            }
        });
    }

    // FUNCTION TO RETURN THE NUMBER OF CLIENTS FROM THE SUPPLIERS TO CHANGE
    const getSupplierNewRequestQuantity = () => {
        let newRequestQuantity = 0

        newRequestQuantity = dataTableCopy.filter(supplier => supplier.entityStatus === "New request").length

        return newRequestQuantity
    }

    return (
        <section className={`request ${darkLightMode ? "darkMode" : null}`}>
            <div className="titleAndSearch">
                <h3 className="title">Supplier Requests</h3>
                <div className="searchAndSort">
                    <div className={`searchContainer ${darkLightMode ? "darkMode" : null}`}>
                        <input id="generalSearch" type="text" placeholder="Search by name..."
                            onFocus={() => clearFilter()} onChange={(e) => searchFilter(e.target.value)} value={searchValue}/>
                    </div>
                    <button ref={sortMenuRef} className="d-lg-none sortMobile" onClick={() => setShowSortMenu(true)}>Sort</button>
                    <div className={`d-lg-none sortDataContainer ${showSortMenu ? "show" : ""}`}>
                        <h5 className="title">Sort</h5>
                        <span onClick={() => { sortTable(false, "Name") }}>Name (ascending)</span>
                        <span onClick={() => { sortTable(true, "Name") }}>Name (descending)</span>
                        <span onClick={() => { sortTable(false, "Industry") }}>Industry (ascending)</span>
                        <span onClick={() => { sortTable(true, "Industry") }}>Industry (descending)</span>
                        <span onClick={() => { sortTable(false, "Location") }}>Location (ascending)</span>
                        <span onClick={() => { sortTable(true, "Location") }}>Location (descending)</span>
                        <span onClick={() => { sortTable(false, "Status") }}>Status (ascending)</span>
                        <span onClick={() => { sortTable(true, "Status") }}>Status (descending)</span>
                        <span onClick={() => { sortTable(false, "Date") }}>Date (ascending)</span>
                        <span onClick={() => { sortTable(true, "Date") }}>Date (descending)</span>
                    </div>
                </div>
            </div>
            <div className="filters">
                {(userLogged?.rol === "Master" || userLogged?.rol === "Super admin") && 
                <div className={`filter ${openFilter === "client" ? "show" : null} ${darkLightMode ? "darkMode" : null} ${clientIsFiltered ? "active" : undefined}`} onClick={(e) => handleOpenFilter(e, "client")}>
                    <img src={client} alt="Prodensa Supplier" />
                    <span>Client</span>
                    <div className={`filterOptions ${openFilter === "client" ? "show" : null}`} onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
                        <img src={close} alt="Prodensa Supplier" className="closeImg d-lg-none" onClick={() => setOpenFilter(null)} />
                        <span className="title">
                            <img className="d-lg-none imgMenu" src={client} alt="Prodensa Supplier" />
                            Client
                        </span>
                        <div className="optionsScroll">
                            {clients && clients.sort((a, b) => a.generalInformation.clientName?.localeCompare(b.generalInformation.clientName)).map(client => {
                                if (client.generalInformation.clientName !== "Admin Prodensa") {
                                    return (
                                        <div key={`filter-${client._id}`} className="option">
                                            <input checked={clientFilter.includes(client._id?.toString())} className="clientCheck" value={client._id} type="checkbox" id={`item-${client._id}`} onChange={(e) => toggleFilterValue(setClientFilter)(e.target)} />
                                            <label htmlFor={`item-${client._id}`}>{client.generalInformation.clientName}</label>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div className="actions">
                            <button onClick={() => { clearFilter("client"); clearFilter("entity"); }}>Clear</button>
                            <button onClick={() => { filterData(); }}>Apply</button>
                        </div>
                    </div>
                </div>}
                {(clientIsFiltered || userLogged?.rol === "Admin client" || userLogged?.rol === "Admin Compliance manager") && 
                <div className={`filter ${openFilter === "entity" ? "show" : null} ${darkLightMode ? "darkMode" : null} ${entityIsFiltered ? "active" : undefined}`} onClick={(e) => handleOpenFilter(e, "entity")}>
                    <img src={client} alt="Prodensa Supplier" />
                    <span>Entity</span>
                    <div className={`filterOptions ${openFilter === "entity" ? "show" : null}`} onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
                        <img src={close} alt="Prodensa Supplier" className="closeImg d-lg-none" onClick={() => setOpenFilter(null)} />
                        <span className="title">
                            <img className="d-lg-none imgMenu" src={client} alt="Prodensa Supplier" />
                            Entity
                        </span>
                        <div className="optionsScroll">
                            {clients && createEntityFilter().map((entity, index) => {
                                return (
                                    <div key={index} className="option">
                                        <input checked={entityFilter.includes(entity._id?.toString())} className="entityCheck" value={entity._id} type="checkbox" id={`entity${index}`} onChange={(e) => toggleFilterValue(setEntityFilter)(e.target)} />
                                        <label htmlFor={`entity${index}`} >{entity.entityName}</label>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="actions">
                            <button onClick={() => clearFilter("entity")}>Clear</button>
                            <button onClick={() => filterData()}>Apply</button>
                        </div>
                    </div>
                </div>}
                <div className={`filter ${openFilter === "industry" ? "show" : null} ${darkLightMode ? "darkMode" : null} ${industryIsFiltered ? "active" : undefined}`} onClick={(e) => handleOpenFilter(e, "industry")}>
                    <img src={industry} alt="Prodensa Supplier" />
                    <span>Industry</span>
                    <div className={`searchContainer ${openFilter === "industry" ? "show" : null}`} onClick={(e) => e.stopPropagation()}>
                        <input value={industrySearch} type="text" placeholder="Search industries"
                            onChange={(e) => setIndustrySearch(e.target.value)} />
                    </div>
                    <div className={`filterOptions location ${openFilter === "industry" ? "show" : null}`} onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
                        <img src={close} alt="Prodensa Supplier" className="closeImg d-lg-none" onClick={() => setOpenFilter(null)} />
                        <span className="title">
                            <img className="d-lg-none imgMenu" src={industry} alt="Prodensa Supplier" />
                            Industry
                        </span>
                        <div className="optionsScroll">
                            {createIndustryFilter().map((industry, index) => {
                                return (
                                    <div key={index} className="option">
                                        <input checked={industryFilter.includes(industry)} className="industryCheck" value={industry} type="checkbox" id={`industry${index}`} onChange={(e) => toggleFilterValue(setIndustryFilter)(e.target)} />
                                        <label htmlFor={`industry${index}`} >{industry}</label>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="actions">
                            <button onClick={() => clearFilter("industry")}>Clear</button>
                            <button onClick={() => filterData()}>Apply</button>
                        </div>
                    </div>
                </div>
                <div className={`filter ${openFilter === "status" ? "show" : null} ${darkLightMode ? "darkMode" : null} ${statusIsFiltered ? "active" : undefined}`} onClick={(e) => handleOpenFilter(e, "status")}>
                    <img src={status} alt="Prodensa Supplier" />
                    <span>Status</span>
                    <div className={`filterOptions ${openFilter === "status" ? "show" : null}`} onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
                        <img src={close} alt="Prodensa Supplier" className="closeImg d-lg-none" onClick={() => setOpenFilter(null)} />
                        <span className="title">
                            <img className="d-lg-none imgMenu" src={status} alt="Prodensa Supplier" />
                            Status
                        </span>
                        <div className="optionsScroll">
                            {clients && createStatusFilter().map((filter, index) => (
                                <div key={index} className="option">
                                    <input checked={statusFilter.includes(filter)} className="statusCheck" value={filter} type="checkbox" id={`status${index}`} onChange={(e) => toggleFilterValue(setStatusFilter)(e.target)} />
                                    <label htmlFor={`status${index}`}>{filter}</label>
                                </div>
                            ))}
                        </div>
                        <div className="actions">
                            <button onClick={() => clearFilter("status")}>Clear</button>
                            <button onClick={() => filterData()}>Apply</button>
                        </div>
                    </div>
                </div>
                <div className={`filter ${openFilter === "location" ? "show" : null} ${darkLightMode ? "darkMode" : null} location ${locationIsFiltered ? "active" : undefined}`} onClick={(e) => handleOpenFilter(e, "location")}>
                    <img src={locationImg} alt="Prodensa Supplier" />
                    <span>Location</span>
                    <div className={`searchContainer ${openFilter === "location" ? "show" : null}`} onClick={(e) => e.stopPropagation()}>
                        <input value={locationSearch} type="text" placeholder="Search cities"
                            onChange={(e) => setLocationSearch(e.target.value)} />
                    </div>
                    <div className={`filterOptions location ${openFilter === "location" ? "show" : null}`} onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
                        <img src={close} alt="Prodensa Supplier" className="closeImg d-lg-none" onClick={() => setOpenFilter(null)} />
                        <span className="title">
                            <img className="d-lg-none imgMenu" src={locationImg} alt="Prodensa Supplier" />
                            Location
                        </span>
                        <div className="optionsScroll">
                            {locationListCopy.filter(item => !locationSearch || item.toLocaleLowerCase().includes(locationSearch.toLocaleLowerCase())).map((filter, index) => (
                                <div key={index} className="option">
                                    <input checked={locationFilter.includes(filter)} className="locationCheck" value={filter} type="checkbox" id={`location${index}`} onChange={(e) => toggleFilterValue(setLocationFilter)(e.target)} />
                                    <label htmlFor={`location${index}`} >{filter}</label>
                                </div>
                            ))}
                        </div>
                        <div className="actions">
                            <button onClick={() => clearFilter("location")}>Clear</button>
                            <button onClick={() => filterData()}>Apply</button>
                        </div>
                    </div>
                </div>
                <div className={`checkboxFilter ${darkLightMode ? "darkMode" : ""}`}>
                    <img src={certifications} alt="Prodensa Supplier" />

                    <label htmlFor="cvpCheckbox">CVP</label>

                    <input type="checkbox" id="cvpCheckbox" checked={cvpFilter} onChange={() => handleCvp(!cvpFilter)} />
                </div>
                <span className={`resetAll ${resetAll ? "show" : ""}`} onClick={() => clearFilter()}>Reset All</span>
                <button className="btnBgBlueTextGradient ms-lg-auto" onClick={() => addAllSuppliersNewRequest()}>
                    Select all new request ({getSupplierNewRequestQuantity()})
                </button>
                <button className={`btnBgBlueTextGradient ${suppliersNewRequest.length > 0 ? "show" : ""}`} onClick={() => handleChangeSupplierNewRequest()}>
                    Change suppliers
                </button>
            </div>
            <div className="newRequestActions">
                <button className="btnBgBlueTextGradient d-lg-none" onClick={() => addAllSuppliersNewRequest()}>
                    Select all new request ({getSupplierNewRequestQuantity()})
                </button>
                <button className={`btnBgBlueTextGradient d-lg-none ${suppliersNewRequest.length > 0 ? "show" : ""}`} onClick={() => handleChangeSupplierNewRequest()}>
                    Change suppliers
                </button>
            </div>
            <span className={`resetAll ${resetAll ? "show" : ""} d-lg-none`} onClick={() => clearFilter()}>Reset All</span>

            <table className="tableRequests">
                <thead>
                    <tr>
                        <th style={mainStyle}>
                            <span>Name</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Name")} />
                        </th>
                        <th style={mainStyle}>
                            <span>Industry</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Industry")} />
                        </th>
                        <th style={mainStyle}>
                            <span>Location</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Location")} />
                        </th>
                        <th style={mainStyle}>
                            <span>Status</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Status")} />
                        </th>
                        <th style={mainStyle}>
                            <span>Date</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Date")} />
                            <img className="printReport" src={printReport} alt="Prodensa supplier" onClick={() => getTableReport()} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((supplier, index) => (
                        <tr key={index}>
                            <td>
                                <div className={`infoContainer ${supplier.certified ? "withBadge" : ""}`}>
                                    {supplier.certified && <img className="cvpBadge" src={cvpBadge} alt="Prodensa Cattenna" />}
                                    {supplier.entityStatus === "New request" &&
                                        <input className="massiveAction" type="checkbox" onChange={(e) => addSuppliersNewRequest(e.target.checked, supplier.supplierID, supplier.entityID)}
                                            checked={suppliersNewRequest?.find(supplierRequest => supplierRequest.supplierID === supplier.supplierID)?.clientIDs.includes(supplier.entityID) ?? false}
                                        />}
                                    <span onClick={() => supplier.entityStatus === "New request" ? activateModal(supplier.supplierID, supplier.entityID) : navigate("./reviewForAuth/" + supplier.supplierID)}>
                                        {supplier.companyName}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{supplier.industries.map((industry, index) => {
                                        return supplier.industries.length > index + 1 ? industry + ", " : industry
                                    })}</span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{supplier.location}</span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span className={`${supplier.entityStatus === "Ready for review" ? "success" : ""} ${supplier.entityStatus === "Pre-authorized" || supplier.entityStatus === "Review requested" ? "pending" : ""} ${supplier.entityStatus === "New request" ? "new" : ""}  ${supplier.entityStatus === "Uploading documents" ? "uploadingDocs" : ""}`}>
                                        {supplier.entityStatus}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{moment(supplier.updatedAt).format("DD MMM YYYY")}</span>
                                    <img className="viewProfile" src={viewProfile} alt="Prodensa Supplier" onClick={() => supplier.entityStatus === "New request" ? activateModal(supplier.supplierID, supplier.entityID) : navigate("./reviewForAuth/" + supplier.supplierID)} />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="d-lg-none supplierRequests">
                {currentItems.map((supplier, index) => (
                    <div key={index} className="requestRow" onClick={() => supplier.entityStatus === "New request" ? activateModal(supplier.supplierID, supplier.entityID) : navigate("./reviewForAuth/" + supplier.supplierID)}>
                        <div className="requestHeader">
                            {supplier.certified && <img className="cvpBadge" src={cvpBadge} alt="Prodensa Cattenna" />}
                            <h5 className="requestName">
                                {supplier.companyName}
                            </h5>
                            {supplier.entityStatus === "New request" &&
                                <input className="massiveAction" type="checkbox" onClick={(e) => e.stopPropagation()} onChange={(e) => addSuppliersNewRequest(e.target.checked, supplier.supplierID, supplier.entityID)}
                                    checked={suppliersNewRequest?.find(supplierRequest => supplierRequest.supplierID === supplier.supplierID)?.clientIDs.includes(supplier.entityID) ?? false}
                                />}
                        </div>
                        <div className="requestInformation">
                            <span className="requestValue">{supplier.location}</span>
                            <span className="requestTitle">Location</span>
                            <span className={`requestValue ${supplier.entityStatus === "Ready for review" ? "success" : ""} ${supplier.entityStatus === "Pre-authorized" || supplier.entityStatus === "Review requested" ? "pending" : ""} ${supplier.entityStatus === "New request" ? "new" : ""}`}>
                                {supplier.entityStatus}
                            </span>
                            <span className="requestTitle">Status</span>
                            <span className="requestValue">{supplier.industries.map((industry, index) => {
                                return supplier.industries.length > index + 1 ? industry + ", " : industry
                            })}</span>
                            <span className="requestTitle">Industry</span>
                            <span className="requestValue">{moment(supplier.updatedAt).format("DD MMM YYYY")}</span>
                            <span className="requestTitle">Date</span>
                        </div>
                    </div>
                ))}
            </div>

            <div className="addAndPagination">
                <div className="pagination">
                    {Array.from({ length: totalPages }).map((_, index) => (
                        <button key={index} className={`number ${+currentPage === index + 1 ? 'active' : ''}`} onClick={() => paginate(index + 1)}>
                            {index + 1}
                        </button>
                    ))}
                    <img className="nextPage" src={next} alt="Prodensa Supplier" onClick={() => +currentPage < totalPages ? paginate(+currentPage + 1) : undefined} />
                </div>
            </div>
            <span className="tableCount">{currentItems.length < 19 ? currentItems.length : 20 * +currentPage} of {dataTable.length}</span>

            {supplierID && supplierClientID && <NewRequestModal show={showModal} userLogged={userLogged} supplierID={supplierID} handleClose={() => handleCloseModal()} supplierClientID={supplierClientID} />}

        </section>
    )
}