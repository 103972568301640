import "../../assets/css/userConfig/userConfig.css"

import { v4 as uuidv4 } from "uuid";
import { Modal, Dropdown } from "react-bootstrap"
import { useEffect, useState } from "react"
import Axios from "axios"
import Swal from "sweetalert2"

import close from "../../assets/images/close.svg"
import { loadingModal } from "../userConfig/utils.js";
import { checkTheEmailStruct } from "../../utilities/validateEmail.js";

const {
	REACT_APP_URI_USERS: URI_USERS,
} = process.env

export default function AddClientUserModal(props) {
    const { darkLightMode, show, onHide, client, setUsers = null, isAdminClient, havePurchasing = null } = props

	// if setUsers === null, the modal is being used in client profile

	const DROPDOWN_INPUTS = [
		{
			id: uuidv4(),
			label: "Rol",
			accessKey: "rol",
			options: setUsers ? [
				{
					id: uuidv4(),
					label: "Supplier",
					value: "Supplier"
				},
				{
					id: uuidv4(),
					label: "Product Manager",
					value: "Product manager"
				},
				{
					id: uuidv4(),
					label: "Document Manager",
					value: "Document manager"
				}
			] :
			isAdminClient ? [
				{
					id: uuidv4(),
					label: "Admin Client",
					value: "Admin client"
				},
				{
					id: uuidv4(),
					label: "Admin Compliance Manager",
					value: "Admin Compliance manager"
				},
				{
					id: uuidv4(),
					label: "Admin Security Manager",
					value: "Admin Security manager"
				},
			] : [
				{
					id: uuidv4(),
					label: "Client",
					value: "Client"
				},
				{
					id: uuidv4(),
					label: "Compliance Manager",
					value: "Compliance manager"
				},
				{
					id: uuidv4(),
					label: "Security Manager",
					value: "Security manager"
				},
			]
		},
		{
			id: uuidv4(),
			label: "Status",
			accessKey: "disabled",
			options: [
				{
					id: uuidv4(),
					label: "Active",
					value: false
				},
				{
					id: uuidv4(),
					label: "Inactive",
					value: true
				},
			]
		},
		{
			id: uuidv4(),
			label: "Active purchasing",
			accessKey: "activePurchasing",
			options: [
				{
					id: uuidv4(),
					label: "Active",
					value: true
				},
				{
					id: uuidv4(),
					label: "Inactive",
					value: false
				},
			]
		},
	]

    const [newUser, setNewUser] = useState({
        username: "",
        rol: "",
        activePurchasing: false,
        disabled: false,
        dataID: client?._id
    })

    useEffect(() => {
        setNewUser(prev => {
            return {
                ...prev,
                dataID: client?._id
            }
        })
    }, [client])

    const [usernameValidated, setUsernameValidated] = useState(false)

    const handleNewUsername = (property, value) => {
		setNewUser(prev => {
			return {
				...prev,
				[property]: value
			}
		})
	}

    const handleCheckUsername = async () => {
        const email = newUser.username

		if (email === "") return

        try {
            const res = await checkTheEmailStruct(email)

            setNewUser(prev => {
                return {
                    ...prev,
                    username: res
                }
            })

            setUsernameValidated(res ? true : false)
        } catch (error) {
            console.log(error)

            setNewUser(prev => {
                return {
                    ...prev,
                    username: ""
                }
            })

            setUsernameValidated(false)
        }
    }

    const handleAddNewUser = () => {
		loadingModal()

		Axios.post(URI_USERS, newUser).then(res => {
			Swal.fire({
				icon: 'success',
				text: 'User added successfully',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})

			setUsers && setUsers(prev => {
				return [
					...prev,
					res.data
				]
			})

			setNewUser(prev => {
				return {
					...prev,
					username: ""
				}
			})
			
			setUsernameValidated(false)
		}).catch(err => {
			console.log(err)

			Swal.fire({
				icon: 'error',
				text: err.response?.data?.message || "Failed to update users",
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
		})
	}

    return (
		<Modal
			className={`addUserModal ${darkLightMode ? "darkMode" : ""}`}
			size="lg"
			centered
			show={show}
			onHide={onHide}
		>
            <Modal.Title>
                <h4>Add a new user</h4>

				<span>Please provide us with some information about the user</span>

                <img className="closeIcon" src={close} alt="Prodensa Supplier" onClick={onHide}/>
            </Modal.Title>

			<Modal.Body>
				<div className="inputContainer" id="username">
					<label htmlFor="username">Username</label>

					<input 
						value={newUser.username} 
						type="text" 
						placeholder="example@example.com" 
						onChange={(e) => handleNewUsername("username", e.target.value)}
						onFocus={() => setUsernameValidated(false)}
						onBlur={handleCheckUsername}
					/>
				</div>

				{DROPDOWN_INPUTS.map(dropdown => {
					const optSelected = dropdown.options.find(opt => opt.value === newUser[dropdown.accessKey])?.label

					if (dropdown.accessKey === "activePurchasing" && !havePurchasing) return null
					
					return (
						<div key={dropdown.id}>
							<span>{dropdown.label}</span>
							
							<Dropdown>
								<Dropdown.Toggle
								>
									{optSelected ?? "Select an option"}
								</Dropdown.Toggle>

								<Dropdown.Menu>
									{dropdown.options.map(opt => {
										return (
												<Dropdown.Item 
													key={opt.id} 
													onClick={() => handleNewUsername(dropdown.accessKey, opt.value)}
												>
														{opt.label}
												</Dropdown.Item>
											)
									})}
								</Dropdown.Menu>
							</Dropdown>
						</div>
					)
				})}

				<div className="actionsContainer">
					<button className="btnBgWhiteTextBlack" onClick={onHide}>Cancel</button>

					<button className="btnBgBlueTextGradient" onClick={handleAddNewUser} disabled={!usernameValidated || !newUser.rol}>Add new user</button>
				</div>
			</Modal.Body >
		</Modal >
	)
}
