import "../../assets/css/clientAlerts/clientAlerts.css"

import { useEffect, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import axios from "axios"

import HoldPaymentList from "./holdPaymentList.jsx"
import ContractList from "./contractList.jsx"
import Swal from "sweetalert2"
import ActionNeededList from "./actionNeededTab/actionNeededList.jsx"
import { useAuth } from "../../context/auth-user.jsx"

const URI_ALERTS_LIST = process.env.REACT_APP_URI_ALERTS_LIST
const URI_REVIEW_CONTRACT = process.env.REACT_APP_URI_REVIEW_CONTRACT

export default function ClientAlerts({ darkLightMode }) {
    //VARIABLE TO MANAGE WHOS LOG IN
    const { user } = useAuth()
    const userLogged = user?.selectedProfile

    // VARIABLE TO MANAGE THE INFORMATION FROM THE 3 TABS
    const [alertsList, setAlertsList] = useState(null)
    const [alertsListCopy, setAlertsListCopy] = useState(null)
    const [searchValue, setSearchValue] = useState("")

    const [key, setKey] = useState(0)

    useEffect(() => {
        if (!userLogged) return
        
        getAlertListInformation()
    }, [userLogged])

    // RESET THE DATA LIST EVERY TIME A TAB IS CHANGED
    useEffect(() => {
        setAlertsListCopy(alertsList)
        clearSearchFilter()
    }, [key])

    // GET ALL THE INFORMATION NEEDED TO THE 3 TABS
    const getAlertListInformation = () => {
        Swal.fire({
            footer: 'Loading, please wait',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })

        axios.post(URI_ALERTS_LIST, {
            rol: userLogged?.rol,
            dataID: userLogged?.dataID
        }).then(response => {
            setAlertsList(response.data)
            setAlertsListCopy(response.data)
            Swal.close()
        }).catch(err => {
            console.log("Error al obtener la informacion: " + err)
            Swal.close()
        })
    }

    // FUNCTION TO CHANGE THE SPECIFIC CONTRACT STATUS FROM THE SPECIFIC SUPPLIER
    const reviewContract = (supplierID, contractID) => {
        axios.post(URI_REVIEW_CONTRACT, {
            supplierID,
            contractID
        }).then(response => {
            getAlertListInformation()
        }).catch(err => {
            console.log("Error cuando se actualizaba el contrato: " + err)
        })
    }

    // FUNCTION TO FILTER THE DATA CHILD
    const searchFilter = (value) => {
        setSearchValue(value)
        let filteredList = []
        switch (key) {
            // case 0:
            //     filteredList = alertsList.actionNeeded.filter(supplier => supplier?.companyName?.toLowerCase()?.includes(value?.toLowerCase()))
                
            //     setAlertsListCopy(prevState => {
            //         return {
            //             ...prevState,
            //             actionNeeded: filteredList
            //         }
            //     })
            //     break;
            
            case 1:
                filteredList = alertsList.holdPayment.filter(supplier => supplier.companyName.toLowerCase().includes(value.toLowerCase()))

                setAlertsListCopy(prevState => {
                    return {
                        ...prevState,
                        holdPayment: filteredList
                    }
                })
                break;
                
            case 2:
                filteredList = alertsList.contracts.filter(supplier => supplier.companyName.toLowerCase().includes(value.toLowerCase()))

                setAlertsListCopy(prevState => {
                    return {
                        ...prevState,
                        contracts: filteredList
                    }
                })
                break;

            default:
                break;
        }
    }

    // FUNCTION TO CLEAR THE SEARCH INPUT
    const clearSearchFilter = () => {
        const searchElement = document.getElementById("generalSearch")
        searchElement.value = ""
    }

    return (
        <section className={`alerts ${darkLightMode ? "darkMode" : null}`}>
            <div className="titleAndSearch">
                <h3 className="title">Alerts</h3>
                <div className="searchAndSort">
                    <div className={`searchContainer ${darkLightMode ? "darkMode" : null}`}>
                        <input id="generalSearch" value={searchValue} type="text" placeholder="Search by name..."
                            onChange={(e) => searchFilter(e.target.value)} />
                    </div>
                </div>
            </div>

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(+k)}>

                <Tab eventKey={0} title="1 Action needed">
                    {alertsListCopy && <ActionNeededList
                        suppliersActionNeeded={alertsListCopy.actionNeeded}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        userLogged={userLogged}
                        darkLightMode={darkLightMode}
                    />}
                </Tab>
                <Tab eventKey={1} title="2 Hold payment">
                    {alertsListCopy && <HoldPaymentList
                        suppliersHoldPayment={alertsListCopy.holdPayment}
                        darkLightMode={darkLightMode}
                    />}
                </Tab>
                <Tab eventKey={2} title="3 Contracts">
                    {alertsListCopy && <ContractList
                        suppliersContracts={alertsListCopy.contracts}
                        reviewContract={reviewContract}
                        darkLightMode={darkLightMode}
                    />}
                </Tab>
            </Tabs>
        </section>
    )
}