import Axios from "axios"
import { useEffect, useState, useMemo } from "react"
import { Dropdown } from "react-bootstrap"

import trash from "../../../assets/images/delete.svg"
import { getFilterProducts } from "./utils.js"
import { ABLE_TO_DO_ROLS } from "../warehousesTab/utils.js"
import { useAuth } from "../../../context/auth-user.jsx"

export default function Product(props) {
    const {
        product,
        supplierProducts,
        supplierWarehouses,
        selectedInput,
        handleSelectedInput,
        handleUpdateProduct,
        supplierRfc,
    } = props

    const [filteredSupplierProducts, setFilteredSupplierProducts] = useState([])
    const [productWarehouses, setProductWarehouses] = useState([])

    const [searchSpecificProduct, setSearchSpecificProduct] = useState("")
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [selectedWarehouse, setSelectedWarehouse] = useState(null)

    const { user } = useAuth()
    const userLogged = useMemo(() => user?.selectedProfile, [user?.selectedProfile]);
    const ableToDo = ABLE_TO_DO_ROLS.includes(userLogged?.rol)

    useEffect(() => {
        setFilteredSupplierProducts(supplierProducts)
    }, [supplierProducts])

    // SET SPECIFIC PRODUCT AND WAREHOUSE IF THE GENERIC PRODUCT ALREADY HAS ONE
    useEffect(() => {
        if (!product?.product?.specificProductId) {
            setSelectedProduct(null)
            setSelectedWarehouse(null)

            return
        }

        const productAssigned = supplierProducts.find(item => item._id === product.product?.specificProductId)
        setSelectedProduct(productAssigned)

        if (productAssigned) {
            const findSelectedWarehouse = supplierWarehouses.find(wh => wh._id.toString() === product?.product?.warehouseID?.toString())

            setSelectedWarehouse(findSelectedWarehouse)
        }
    }, [product])

    // SET WAREHOUSES AVAILABLE FOR A SPECIFIC SELECTED PRODUCT
    useEffect(() => {
        if (!selectedProduct) return

        const findProdWarehouses = supplierWarehouses.filter(warehouse => warehouse.products.some(prod => prod.barcode === selectedProduct?.barcode))

        setProductWarehouses(findProdWarehouses)
    }, [selectedProduct, supplierWarehouses])

    // FILTER SEARCH FOR A SPECIFIC PRODUCT
    const handleSearchProduct = (e) => {
        const { value } = e.target

        const filteredProds = getFilterProducts(supplierProducts, value)
        setFilteredSupplierProducts(filteredProds)

        setSearchSpecificProduct(value)
    }

    // SELECT A SPECIFIC PRODUCT TO A GENERIC PRODUCT
    const handleSelectProduct = (prod) => {
        setSelectedProduct(prod)
        setSelectedWarehouse(null)
    }

    // SELECT WAREHOUSE AND SEND ASSIGNATION TO PURCHASING
    const handleSelectWarehouse = (wh) => {
        setSelectedWarehouse(wh)

        const selectedProductCopy = { ...selectedProduct }
        selectedProductCopy.warehouseId = wh._id
        selectedProductCopy.specificProductId = selectedProductCopy._id

        const dataToSend = {
            companyId: product?.companyId,
            locationId: product?.locationId,
            locationProductId: product?.product?.locationProductId,
            specificProduct: selectedProductCopy,
            supplierRfc: supplierRfc,
        }

        Axios.post(`${process.env.REACT_APP_PURCHASING_LOCALHOST}supplier/assignSpecificProductToGenericProduct`, dataToSend).then(res => {
            console.log(res.message)
            handleUpdateProduct(product, selectedProduct._id, wh._id)
        }).catch(err => {
            setSelectedWarehouse(null)
            console.log(err)
        })
    }

    // REMOVE ASSIGNATION AND SEND THE REMOVED ASSIGNATION TO PURCHASING
    const handleDeleteSelection = () => {
        if (!selectedWarehouse) {
            setSelectedProduct(null)

            return
        }

        const dataToSend = {
            specificProductIds: [product?.product?.specificProductId],
            warehouseId: product?.product?.warehouseID,
            companyId: product?.companyId,
            locationId: product?.locationId,
            locationProductId: product?.product?.locationProductId
        }

        Axios.put(`${process.env.REACT_APP_PURCHASING_LOCALHOST}supplier/deactivateProducts`, dataToSend).then(res => {
            setSelectedProduct(null)
            setSelectedWarehouse(null)
            setSearchSpecificProduct("")
            handleUpdateProduct(product, null, null)
            console.log(res.data)
        }).catch(err => console.log(err))
    }

    const inputSearchClass = `listOfProducts ${selectedInput === `${product?.product?.locationProductId}-${product?.locationId}` ? "show" : ""}`
    const dropdownclass = selectedProduct && !selectedWarehouse ? "warning" : ""
    const dropdownToggleText = selectedWarehouse?.name || "Select warehouse"

    return (
        <div className="product-row">
            <span className="product-name">{product?.product?.name}</span>
            <span className="product-location">{product?.locationName}</span>

            <div className="product-search">
                <div className="searchContainer">
                    <input
                        type="text"
                        placeholder="Search products"
                        value={selectedProduct?.name || searchSpecificProduct}
                        onChange={handleSearchProduct}
                        onFocus={() => handleSelectedInput(`${product?.product?.locationProductId}-${product?.locationId}`)}
                        onBlur={() => handleSelectedInput(null)}
                        disabled={!ableToDo}
                    />

                    <div className={inputSearchClass}>
                        {filteredSupplierProducts.map(prod => {
                            return (
                                <span
                                    key={prod?.barcode}
                                    onClick={() => handleSelectProduct(prod)}
                                >
                                    {prod.name}
                                </span>
                            )
                        })}
                    </div>
                </div>

                {selectedProduct && ableToDo && <img src={trash} alt="Prodensa Supplier" onClick={handleDeleteSelection} />}
            </div>

            <Dropdown className={dropdownclass}>
                <Dropdown.Toggle disabled={!selectedProduct || !ableToDo}>
                    {dropdownToggleText}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {productWarehouses.map(warehouse => {
                        return (
                            <Dropdown.Item
                                key={warehouse._id}
                                onClick={() => handleSelectWarehouse(warehouse)}
                            >
                                {warehouse?.name}
                            </Dropdown.Item>
                        )
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}
