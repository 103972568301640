import "../../assets/css/navigationMenu/navigationMenu.css"

import moon from "../../assets/images/dark_mode.svg"
import sun from "../../assets/images/wb_sunny.svg"
import logo from "../../assets/images/logo.png"
import iconProdensa from "../../assets/images/iconProdensa.svg"
import complianceSetup from "../../assets/images/complianceSetup.svg"
import myProfile from "../../assets/images/myProfile.svg"
import dashboard from "../../assets/images/dashboard.svg"
import suppliers from "../../assets/images/suppliers.svg"
import industries from "../../assets/images/industries.svg"
import requests from "../../assets/images/requests.svg"
import client from "../../assets/images/clients.svg"
import alert from "../../assets/images/alerts.svg"
import securityStaff from "../../assets/images/securityStaff.svg"
import forward from "../../assets/images/arrowForward.svg"
import menuBlue from "../../assets/images/menuBlue.svg"
import downloadReport from "../../assets/images/downloadReport.svg"
import convertFile from "../../assets/images/convert_to_text.svg"
import book from "../../assets/images/book_5.svg"
import arrow from "../../assets/images/arrowForward.svg"

import { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useCookies } from "react-cookie"
import { useRef } from "react"
import axios from "axios"
import Swal from "sweetalert2"
import { useAuth } from "../../context/auth-user"


const { 
    REACT_APP_URI_UPLOADS: URI_UPLOADS,
    REACT_APP_REPORT_HOLD_PAYMENTS: URI_REPORT_HOLD_PAYMENTS,
    REACT_APP_REPORT_SUPPLIERS: URI_REPORT_SUPPLIERS,
    REACT_APP_REPORT_REFFERALS: URI_REPORT_REFFERALS,
    REACT_APP_CHECK_RFCS: URI_CHECK_RFCS
} = process.env

function MenuButton(props) {
    return (
        props.show
            ? <button className={`btnNav ${props.darkLightMode ? "darkMode" : null} ${props.isActive ? "active" : ""}`} onClick={props.onClick}>
                <img className="iconBtnNav" src={props.image} alt="Prodensa Supplier" />
                {props.title}
                {(props.id !== 10 && props.id !== 11 && props.id !== 12 && props.id !== 13) && <img className="arrowBtnNav" src={forward} alt="Prodensa Supplier" />}
            </button>
            : undefined
    )
}


export default function NavigationMenu(props) {
    // VARIABLE TO KNOW WHOS IS LOGGED
    const { user, updateUser } = useAuth()
    
    const [_cookie, _setCookie, removeCookie] = useCookies(["token"])
    const userLogged = user?.selectedProfile
    
    const url = useLocation();
    const urlPathname = url.pathname;
    const checkRfcsInput = useRef(null)
    
    const MENU_BUTTONS = [
        {
            _id: 0,
            route: "complianceSetup",
            title: "Compliance setup",
            image: complianceSetup,
            isActive: false,
            show: (userLogged?.rol === "Supplier" || userLogged?.rol === "Product manager" || userLogged?.rol === "Document manager") && props.firstTime,
        },
        {
            _id: 1,
            route: "myProfile",
            title: "My profile",
            image: myProfile,
            isActive: false,
            show: (userLogged?.rol === "Supplier" || userLogged?.rol === "Product manager" || userLogged?.rol === "Document manager") && !props.firstTime,
        },
        {
            _id: 2,
            route: "dashboard",
            title: "Dashboard",
            image: dashboard,
            isActive: false,
            show: userLogged?.rol === "Master" || userLogged?.rol === "Super admin" || userLogged?.rol === "Admin client" || userLogged?.rol === "Client"
        },
        {
            _id: 3,
            route: "suppliers",
            title: "Suppliers",
            image: suppliers,
            isActive: false,
            show: userLogged?.rol === "Master" || userLogged?.rol === "Super admin" || userLogged?.rol === "Admin client" || userLogged?.rol === "Client" || userLogged?.rol === "Admin Compliance manager" || userLogged?.rol === "Compliance manager",
        },
        {
            _id: 4,
            route: "industries",
            title: "Industries",
            image: industries,
            isActive: false,
            show: userLogged?.rol === "Master" || userLogged?.rol === "Super admin" || userLogged?.rol === "Admin client" || userLogged?.rol === "Admin Compliance manager",
        },
        {
            _id: 5,
            route: "requests",
            title: "Requests",
            image: requests,
            isActive: false,
            show: userLogged?.rol === "Master" || userLogged?.rol === "Super admin" || userLogged?.rol === "Admin client" || userLogged?.rol === "Client" || userLogged?.rol === "Admin Compliance manager" || userLogged?.rol === "Compliance manager",
        },
        {
            _id: 6,
            route: "clients",
            title: "Clients",
            image: client,
            isActive: false,
            show: userLogged?.rol === "Master" || userLogged?.rol === "Super admin",
        },
        {
            _id: 7,
            route: "alerts",
            title: "Alerts",
            image: alert,
            isActive: false,
            show: userLogged?.rol === "Master" || userLogged?.rol === "Super admin" || userLogged?.rol === "Admin client" || userLogged?.rol === "Client" || userLogged?.rol === "Admin Compliance manager" || userLogged?.rol === "Compliance manager",
        },
        {
            _id: 8,
            route: "securityStaff",
            title: "Security Staff",
            image: securityStaff,
            isActive: false,
            show: userLogged?.rol === "Master" || userLogged?.rol === "Super admin" || userLogged?.rol === "Admin client" || userLogged?.rol === "Client" || userLogged?.rol === "Admin Security manager" || userLogged?.rol === "Security manager",
        },
        {
            _id: 9,
            route: "userConfig",
            title: "User configuration",
            image: book,
            isActive: false,
            show: userLogged?.rol === "Master",
        },
        {
            _id: 10,
            title: "Hold payments Check",
            image: convertFile,
            isActive: false,
            show: userLogged?.rol === "Master" || userLogged?.rol === "Super admin" || userLogged?.rol === "Admin client" || userLogged?.rol === "Admin Compliance manager" || userLogged?.rol === "Client" || userLogged?.rol === "Compliance manager",
        },
        {
            _id: 11,
            title: "Hold payments Report",
            image: downloadReport,
            isActive: false,
            show: userLogged?.rol === "Master" || userLogged?.rol === "Super admin" || userLogged?.rol === "Admin client" || userLogged?.rol === "Admin Compliance manager" || userLogged?.rol === "Client" || userLogged?.rol === "Compliance manager",
        },
        {
            _id: 12,
            title: "Supplier Report",
            image: downloadReport,
            isActive: false,
            show: userLogged?.rol === "Master" || userLogged?.rol === "Super admin" ? true : false,
        },
        {
            _id: 13,
            title: "Refferal Report",
            image: downloadReport,
            isActive: false,
            show: userLogged?.rol === "Master" ? true : false,
        },
        {
            _id: 14,
            route: "myAccount",
            title: "My Account",
            image: client,
            isActive: false,
            show: userLogged?.rol === "Admin client" || userLogged?.rol === "Client", 
        },
        {
            _id: 15,
            route: "myClients",
            title: "My Clients",
            image: client,
            isActive: false,
            show: userLogged?.rol === "Supplier" && !props.firstTime,
        },
        {
            _id: 16,
            route: "myAlerts",
            title: "My Alerts",
            image: alert,
            isActive: false,
            show: (userLogged?.rol === "Supplier" || userLogged?.rol === "Document manager") && !props.firstTime ? true : false,
        },
    ]

    const [menuButtons, setMenuButtons] = useState(MENU_BUTTONS);

    // VARIABLE TO GROW THE NAVIGATION MENU
    const [growMenu, setGrowMenu] = useState(false)

    const [swapProfileOpen, setSwapProfileOpen] = useState(false)

    // REFERENCE TO CLOSE TO SHRINK THE NAVITATION MENU
    const menuRef = useRef(null)

    const navigate = useNavigate()

    const mainStyle = {
        background: user?.selectedProfile?.darkLightMode ? "#131E29" : "white",
        color: user?.selectedProfile?.darkLightMode ? "white" : "inherit"
    }

    const defaultRoutes = {
        "Admin Compliance manager": "suppliers",
        "Admin Security manager": "securityStaff",
        "Compliance manager": "suppliers",
        "Security manager": "securityStaff"
    };

    useEffect(() => {
        if (userLogged?.rol === "Supplier" || userLogged?.rol === "Product manager" || userLogged?.rol === "Document manager") {
            if (props.firstTime) {
                setMenuButtons(menuButtons.map(button => {
                    return {
                        ...button,
                        isActive: button._id === 0
                    }
                }))
                navigate("./complianceSetup")
            } else {
                setMenuButtons(menuButtons.map(button => {
                    const existingRoute = urlPathname.split("/home")[1]?.split("/")[1] || "myProfile"

                    const routeToFind = existingRoute !== "complianceSetup" ? existingRoute : "myProfile"
                    
                    const buttonID = MENU_BUTTONS.find(button => button.route === routeToFind)?._id
                    
                    return {
                        ...button,
                        isActive: button._id === buttonID
                    }
                }))

                if (!(urlPathname.split("/home")[1] || null) || urlPathname.split("/home")[1]?.split("/")[1] === "complianceSetup") {
                    navigate("./myProfile")
                }
            }
        } else {
            setMenuButtons(menuButtons.map(button => {
                const defaultRoute = defaultRoutes[userLogged?.rol] || "dashboard";
                const existingRoute = urlPathname.split("/home")[1]?.split("/")[1] || defaultRoute;
                const buttonID = MENU_BUTTONS.find(button => button.route === existingRoute)?._id;

                return {
                    ...button,
                    isActive: button._id === buttonID
                };
            }))

            if (!(urlPathname.split("/home/")[1] || null)) {
                if (userLogged?.rol === "Admin Compliance manager" || 
                    userLogged?.rol === "Admin Security manager" || 
                    userLogged?.rol === "Compliance manager" || 
                    userLogged?.rol === "Security manager") return navigate(`./${defaultRoutes[userLogged?.rol]}`)

                navigate("./dashboard")
            }
        }

        document.body.addEventListener("click", closeMenu)

        return function cleanUp() {
            document.body.removeEventListener("click", closeMenu)
        }
    }, [])

    const closeMenu = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
            setGrowMenu(false)
        }
    }

    const handleDarkMode = () => {
        axios.patch(process.env.REACT_APP_URI_USERS, { darkLightMode: !user?.selectedProfile?.darkLightMode, username: user?.selectedProfile?.username }).then().catch(err => console.log(err))

        updateUser({
            selectedProfile: {
                ...user.selectedProfile,
                darkLightMode: !user?.selectedProfile?.darkLightMode
            }
        })
    }

    const handleClickMenuButton = (id) => {
        props.setShowMobileMenu(false)

        setMenuButtons(menuButtons.map(button => {
            return {
                ...button,
                isActive: button._id === id ? true : false
            }
        }))

        localStorage.setItem("requestFilters", JSON.stringify({}))
        localStorage.setItem("suppDirFilters", JSON.stringify({}))
        localStorage.setItem("clientFilters", JSON.stringify({}))

        switch (menuButtons.find(state => state._id === id).title) {
            case "Compliance setup":
                navigate("./complianceSetup")
                break;

            case "My profile":
                navigate("./myProfile")
                break;

            case "Dashboard":
                navigate("./dashboard")
                break;

            case "Suppliers":
                navigate("./suppliers")
                break;

            case "Industries":
                navigate("./industries")
                break;

            case "Requests":
                navigate("./requests")
                break;

            case "My Account": 
                navigate("./myAccount")
                break;

            case "User configuration": 
                navigate("./userConfig")
                break;

            case "Clients":
                navigate("./clients")
                break;

            case "Alerts":
                navigate("./alerts")
                break;

            case "Hold payments Check":
                checkRfcsInput.current?.click()
                break;

            case "Hold payments Report":
                const rol = userLogged?.rol
                const clientID = userLogged?.dataID
                
                Swal.fire({
                    footer: 'Loading, please wait',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                })

                axios.post(URI_REPORT_HOLD_PAYMENTS, { rol, clientID }).then(response => {
                    Swal.fire({
                        icon: 'success',
                        text: `${response.data.message}`,
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    })

                    window.open(URI_UPLOADS + response.data.path, "_blank")
                }).catch(err => {
                    Swal.fire({
                        icon: 'error',
                        text: 'Failed to generate the report',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    })
                })
                break;

            case "Supplier Report":
                Swal.fire({
                    footer: 'Loading, please wait',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                })

                axios.get(URI_REPORT_SUPPLIERS).then(response => {
                    Swal.fire({
                        icon: 'success',
                        text: `${response.data.message}`,
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    })
                    window.open(URI_UPLOADS + response.data.path, "_blank")
                }).catch(err => {
                    Swal.fire({
                        icon: 'error',
                        text: 'Failed to generate the report',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    })
                })
                break;

            case "Refferal Report":
                Swal.fire({
                    footer: 'Loading, please wait',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                })

                axios.get(URI_REPORT_REFFERALS).then(response => {
                    Swal.fire({
                        icon: 'success',
                        text: `${response.data.message}`,
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    })
                    window.open(URI_UPLOADS + response.data.path, "_blank")
                }).catch(err => {
                    Swal.fire({
                        icon: 'error',
                        text: 'Failed to generate the report',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    })
                })
                break;

            case "My Clients":
                navigate("./myClients")
                break;

            case "My Alerts":
                navigate("./myAlerts")
                break;

            case "Security Staff":
                navigate("./securityStaff")
                break;

            default:
                break;
        }
    }

    const handleCheckRfcs = async (e) => {
        const allowTypes = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
		const selectedFile = e.target.files[0];

        if (!selectedFile) return

        if (selectedFile && allowTypes !== selectedFile.type) {
			await Swal.fire({
                icon: "error",
                title: "File not supported",
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2500,
                timerProgressBar: true,
			});
			return;
		}

        Swal.fire({
            footer: 'Loading, please wait',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })

        const data = new FormData()
		data.append("rol", userLogged?.rol)
		data.append("dataID", userLogged?.dataID)
		data.append("rfc", "temporal")
		data.append("document", selectedFile)

        axios.post(URI_CHECK_RFCS, data).then(res => {
            Swal.fire({
                icon: 'success',
                text: `${res.data.message}`,
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
            })

            window.open(URI_UPLOADS + res.data.path, "_blank")

            checkRfcsInput.current.value = ""
        }).catch(err => {
            checkRfcsInput.current.value = ""
            
            console.log(err)
            Swal.fire({
                icon: 'error',
                text: 'Failed to generate the report',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
            })
        })
    }
    
    const handleSwapProfile = () => {
        setSwapProfileOpen(prevState => !prevState)
    }

    return (
        <nav style={mainStyle} ref={menuRef} className={`navigationMenu ${user?.selectedProfile?.darkLightMode ? "darkMode" : null} ${props.showMobileMenu ? "show" : ""} ${growMenu ? "grow" : undefined}`}>
            <div className="header">
                <img className="hamburguer" src={menuBlue} alt="Prodensa Supplier" onClick={() => props.setShowMobileMenu(false)} />
                <img className="logo" src={logo} alt="Prodensa Supplier" />
                <img className="iconProdensa" src={iconProdensa} alt="Prodensa Supplier" />
            </div>

            <input type="file"
                accept=".xlsx" 
                ref={checkRfcsInput} 
                style={{display: "none"}} 
                onChange={handleCheckRfcs} 
            />
            
            <div className={`optNavContainer ${swapProfileOpen ? "swapProfileOpen" : ""}`}>
                {menuButtons.map(button => (
                    <MenuButton
                        key={button._id}
                        id={button._id}
                        title={button.title}
                        image={button.image}
                        isActive={button.isActive}
                        show={button.show}
                        onClick={() => handleClickMenuButton(button._id)}
                        darkLightMode={user?.selectedProfile?.darkLightMode}
                    />
                ))}
                <button style={mainStyle} className={`btnNav darkModeIcon`} onClick={handleDarkMode}>
                    <img className="iconBtnNav" src={user?.selectedProfile?.darkLightMode ? sun : moon} alt="Prodensa Supplier" />
                    {user?.selectedProfile?.darkLightMode ? "Light mode" : "Dark mode"}
                </button>
            </div>

            <div className={`profilesContainer ${swapProfileOpen ? "swapProfileOpen" : ""}`}>
                <div className="profilesHeader">
                    <img src={arrow} alt="Prodensa Supplier" onClick={handleSwapProfile}/>

                    <span>Swap profile</span>
                </div>

                <div className="profiles">
                    {user?.userProfiles?.filter(profile => profile._id !== user?.selectedProfile?._id)?.map(profile => {
                        const logo = profile.profileName ? profile.profileName.split(" ").slice(0, 2).map(word => word[0]).join("").toUpperCase() : "N/A"

                        return (
                            <div key={profile._id} className="profileContainer" onClick={() => props.handleSwitchProfile(profile)}>
                                <div id="profileLogo">{logo}</div>
                                <div className="profileInfo">
                                    <span id="profileName">{profile.profileName}</span>
                                    <span id="profileRol">{profile.rol}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className={`iconGrowMenu ${user?.selectedProfile?.darkLightMode ? "darkMode" : null}`} onClick={() => setGrowMenu(prevState => !prevState)}></div>

            <div className={`mobileActionsContainer ${swapProfileOpen ? "swapProfileOpen" : ""}`}>
                {user?.userProfiles?.length > 1 && <button className={`btnBgBlueTextGradient swapProfile`} onClick={handleSwapProfile}>Swap profile</button>}

                <button className={`btnBgBlueTextGradient`} onClick={() => removeCookie("token", { path: "/" })}>Sign Out</button>
            </div>
        </nav>
    )
}