import "../../assets/css/dashboard/suppliersDashboardTable.css"

import starRating from "../../assets/images/starRating.svg"
import sortIcon from "../../assets/images/sort.svg"
import view from "../../assets/images/viewProfile.svg"

import { useAuth } from "../../context/auth-user"

import { useEffect, useMemo, useReducer } from "react"
import { useNavigate } from "react-router-dom"

import PageSelector from "../pageSelector.jsx"

const HEADER_TITLES = [
    {
        title: "Name",
        sortBy: "companyName",
    },
    {
        title: "Industry",
        sortBy: "industries",
    },
    {
        title: "Rating",
        sortBy: "rating",
    },
    {
        title: "Location",
        sortBy: "location",
    }
]

function Header({ dispatch }) {
    function handleSort(sortBy) {
        dispatch({ type: "SET_SORT", payload: sortBy })
    }
    
    return (
        <div className="suppliersDasboardTableHeader">
            {HEADER_TITLES.map(header => (
                <div className="headerContainer" key={header.sortBy}>
                    <span>{header.title}</span>

                    <img className="sort" onClick={() => handleSort(header.sortBy)} src={sortIcon} alt="ProdensaSupplier" />
                </div>
            ))}
        </div>
    )
}

function SuppliersTable({ state, dispatch }) {
    const navigate = useNavigate()

    const totalPages = Math.ceil(state?.data?.length / state?.itemsPerPage)
    
    function handleNavigate(supplier) {
        const supplierProfileOpt = ["Authorized", "Hold payment", "Active"]
        const isSupplierProfile = supplier.clients.some(client => supplierProfileOpt.includes(client.status))
        
        const reviewForAuthOpt = ["Ready for review", "Pre-authorized", "Review requested"]
        const isReviewForAuth = supplier.clients.some(client => reviewForAuthOpt.includes(client.status))
        
        const isNewRequest = supplier.clients.every(client => client.status === "New request")
        
        const routeToNavigate = isSupplierProfile ? "/home/suppliers/supplierProfile/" + supplier.supplierID
        : isReviewForAuth ? "/home/requests/reviewForAuth/" + supplier.supplierID : 
        isNewRequest ? `/home/requests?companyName=${supplier.companyName}` : "/home/dashboard"
        
        navigate(routeToNavigate)
    }
    
    function paginate(pageNumber) {
        if (pageNumber > totalPages) return

        dispatch({ type: "HANDLE_PAGE", payload: pageNumber })
    };
    
    const tableCount = useMemo(() => state.data?.length < state.itemsPerPage - 1 ? state.data?.length : state.itemsPerPage * state.page, [state])
    
    return (
        <div>
            {state.data.filter(supplier => {
                if (state.search === "") return true

                const metCondition = supplier.companyName?.toLowerCase()?.includes(state.search.toLowerCase()) ||
                    supplier.description?.toLowerCase()?.includes(state.search.toLowerCase()) ||
                    supplier.contactInformation?.toLowerCase()?.includes(state.search.toLowerCase()) ||
                    supplier.rfc?.toLowerCase()?.includes(state.search.toLowerCase())

                return metCondition
            }).slice((state.page * state.itemsPerPage) - state.itemsPerPage, state.page * state.itemsPerPage)
            .map(supplier => {
                const ratingLabel = supplier.rating?.$numberDecimal ?? "Unrated"
                const ratingTag = <div id="supplierRating">
                                        <span>{ratingLabel}</span> 

                                        {supplier.rating?.$numberDecimal && 
                                            <img className="starRating" src={starRating} alt="Prodensa Supplier" />
                                        }
                                </div>
                
                return (
                    <div className="supplierContainer" key={supplier.supplierID}>
                        <span id="supplierName" onClick={() => handleNavigate(supplier)} >{supplier.companyName}</span>

                        <span id="supplierIndustries">{supplier.industries.join(", ")}</span>
                        
                        {ratingTag}
                        
                        <span id="supplierLocation">{supplier.location}</span>

                        <img className="reviewed" src={view} alt="Prodensa Supplier" onClick={() => handleNavigate(supplier)} />
                    </div>
                )
            })}

            <PageSelector 
                className="dashboardTablePagination"
                currentPage={state?.page ?? 1}
                data={state?.data ?? []}
                itemsPerPage={state.itemsPerPage}
                onChange={paginate}
            />

            <span className="tableCount">{tableCount} of {state.data?.length}</span>
        </div>
    )
}

function reducer(state, action) {
    switch (action.type) {
        case "SET_SEARCH":
            return { ...state, search: action.payload }
        case "SET_SORT":
            const sortBy = action.payload

            const sortedData = [...state.data].sort((a, b) => {
                if (sortBy === "companyName" || sortBy === "location") {
                    const aValue = a[sortBy] ?? ""
                    const bValue = b[sortBy] ?? ""

                    if (aValue === bValue) return 0
    
                    if (state.order === "ASC") {
                        return aValue > bValue ? 1 : -1
                    } else {
                        return aValue < bValue ? 1 : -1
                    }
                }

                if (sortBy === "industries") {
                    const aValue = a[sortBy]?.join(", ") ?? ""
                    const bValue = b[sortBy]?.join(", ") ?? ""

                    if (aValue === bValue) return 0
                    
                    if (state.order === "ASC") {
                        return aValue > bValue ? 1 : -1
                    } else {
                        return aValue < bValue ? 1 : -1
                    }
                }
                
                if (sortBy === "rating") {
                    const aValue = parseFloat(a[sortBy]?.$numberDecimal ?? 0)
                    const bValue = parseFloat(b[sortBy]?.$numberDecimal ?? 0)

                    if (aValue === bValue) return 0
                    
                    if (state.order === "ASC") {
                        return aValue > bValue ? 1 : -1
                    } else {
                        return aValue < bValue ? 1 : -1
                    }
                }

                // Default return value to avoid undefined behavior
                return 0;
            })

            const sortedState = {
                ...state,
                sortBy,
                order: state.order === "ASC" ? "DSC" : "ASC",
                data: sortedData,
            }
            
            return sortedState
        case "SET_DATA":
            return { 
                ...state, 
                data: action.payload,
            }
        case "HANDLE_PAGE":
            const page = action?.payload

            return {
                ...state,
                page
            }
        default:
            return state
    }
}

export default function SuppliersDasboardTable(props) {
    const { data } = props
    
    const { user } = useAuth()
    const userLogged = user?.selectedProfile

    const [state, dispatch] = useReducer(reducer, {
        data: data ?? [],
        search: "",
        orderBy: "companyName",
        order: "ASC",
        page: 1,
        itemsPerPage: 20,
    })

    useEffect(() => {
        if (!data) return

        dispatch({ type: "SET_DATA", payload: data })
    }, [data])

    function handleSearch(e) {
        const { value } = e.target

        dispatch({ type: "SET_SEARCH", payload: value })
    }

    return (
        <section className={`suppliersDasboardTable ${userLogged?.darkLightMode ? "darkMode" : null}`}>
            <div className="searchContainer">
                <input value={state.search} type="text" placeholder="Search supplier"
                    onChange={handleSearch} />
            </div>
            
            <Header dispatch={dispatch} />

            <SuppliersTable state={state} dispatch={dispatch} />
        </section>
    )
}
