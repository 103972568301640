import Swal from "sweetalert2";
import Axios from "axios"

// FUNCTION TO CHECK THE EMAIL
export const checkTheEmailStruct = (emailRecived) => {
	Swal.fire({
		footer: 'Loading, please wait',
		showConfirmButton: false,
		allowOutsideClick: false,
		allowEscapeKey: false,
		didOpen: () => {
			Swal.showLoading()
		},
	})
	
	// Remove white spaces
	const emailToCheck = emailRecived?.trim() ?? ""

	// Regular expression for validating email address
	var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	// Check if the email input matches the email format
	if (emailRecived !== "" && !emailRegex.test(emailToCheck)) {
		Swal.fire({
			icon: 'error',
			text: 'Please enter just one valid email adress.',
			showConfirmButton: false,
			timer: 5000,
			timerProgressBar: true,
			didOpen: () => {
				Swal.hideLoading()
			},
		})

		return ""; // Prevent form submission
	}

	// Check if there is more than one email address
	if (emailRecived !== "" && emailToCheck.indexOf(',') !== -1) {
		Swal.fire({
			icon: 'error',
			text: 'Please enter only one email address.',
			showConfirmButton: false,
			timer: 5000,
			timerProgressBar: true,
			didOpen: () => {
				Swal.hideLoading()
			},
		})
		
		return ""; // Prevent form submission
	}

	Swal.close()
	
	return emailToCheck
}

export const checkTheEmail = (emailRecived) => {
	return new Promise(resolve => {
		// Remove white spaces
		const emailToCheck = emailRecived?.trim()
	
		// Regular expression for validating email address
		var emailRegex = /^(?!.*\.\.)([^\s@]+)@[^\s@]+\.[^\s@]{2,}$/;
	
		// Check if the email input matches the email format
		if (emailRecived !== "" && !emailRegex.test(emailToCheck)) {
			Swal.fire({
				icon: 'error',
				text: 'Please enter just one valid email adress.',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
				didOpen: () => {
					Swal.hideLoading()
				},
			})
	
			return resolve(""); // Prevent form submission
		}
	
		// Check if there is more than one email address
		if (emailRecived !== "" && emailToCheck.indexOf(',') !== -1) {
			Swal.fire({
				icon: 'error',
				text: 'Please enter only one email address.',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
				didOpen: () => {
					Swal.hideLoading()
				},
			})
			
			return resolve(""); // Prevent form submission
		}

		if (emailRecived === "") return resolve("")
			
		Swal.fire({
			footer: 'Loading, please wait',
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			didOpen: () => {
				Swal.showLoading()
			},
		})
		
		Axios.get(process.env.REACT_APP_URI_USERS_BY_USERNAME + emailToCheck).then(res => {
			if (res.data.exist) {
				Swal.fire({
					icon: 'error',
					text: 'User already exist.',
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
					didOpen: () => {
						Swal.hideLoading()
					},
				})

				return resolve("")
			}
			
			Swal.close()
			return resolve(emailToCheck)
		}).catch(err => {
			Swal.fire({
				icon: 'error',
				text: 'Error verifying if user already exist.',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
				didOpen: () => {
					Swal.hideLoading()
				},
			})
			console.log(err)
			return resolve(""); // Prevent form submission
		})
	})
}