import "../../../assets/css/tabs/warehousesTab.css"

import { useEffect, useState, useMemo } from "react"
import { Modal } from "react-bootstrap"

import { MODAL_INPUTS, ABLE_TO_DO_ROLS } from "./utils.js"
import close from "../../../assets/images/close.svg"
import trash from "../../../assets/images/delete.svg"
import Warehouse from "./warehouse.jsx"
import { useAuth } from "../../../context/auth-user.jsx"


export default function ProductsAssignation(props) {
    const {
        darkLightMode,
        supplier,
        supplierProducts,
        supplierWarehouses,
        handleSaveChanges
    } = props

    const [warehouses, setWarehouses] = useState([])
    const [selectedWarehouse, setSelectedWarehouse] = useState(supplierWarehouses.find(wh => !wh.deletedRegister))
    const [modalInfo, setModalInfo] = useState({
        name: "",
        address: "",
        city: "",
        state: "",
        hours: "",
        supervisor: ""
    })
    const [showModal, setShowModal] = useState({
        show: false,
        button: "Add"
    })

    const { user } = useAuth()
    const userLogged = useMemo(() => user?.selectedProfile, [user?.selectedProfile]);
    const ableToDo = ABLE_TO_DO_ROLS.includes(userLogged?.rol)

    useEffect(() => {
        setWarehouses(supplierWarehouses)
    }, [supplierWarehouses])

    const handleTabClick = (e) => {
        const { id } = e.target

        const selectedWarehouse = warehouses.find(warehouse => warehouse._id === id)
        
        setSelectedWarehouse(selectedWarehouse)
    }

    const handleAddWarehouse = () => {
        setShowModal({
            show: true,
            button: "Add"
        })
    }

    const handleCloseModal = () => {
        setShowModal({
            show: false,
            button: "Add"
        })

        setModalInfo({
            name: "",
            address: "",
            city: "",
            state: "",
            hours: "",
            supervisor: ""
        })
    }

    const handleModalInput = (e) => {
        const { id, value } = e.target
        const modalInfoCopy = { ...modalInfo }

        modalInfoCopy[id] = value

        setModalInfo(modalInfoCopy)
    }

    const handleAddNewWarehouse = (action) => {
        if (!checkEveryInput) return

        let supplierToSave
        if (action === "Add") {
            const newWarehouse = { ...modalInfo, products: [], deletedRegister: false }
    
            supplierToSave = {
                ...supplier,
                warehouses: [...supplier.warehouses, newWarehouse] 
            }
        } else {
            const warehousesCopy = [...warehouses]
            const warehouseIndex = warehousesCopy.findIndex(warehouse => warehouse._id === modalInfo._id) 

            warehousesCopy[warehouseIndex] = modalInfo

            supplierToSave = {
                ...supplier,
                warehouses: warehousesCopy
            }
        }

        handleSaveChanges(supplierToSave)

        setShowModal({
            show: false,
            button: "Add"
        })
    }

    const handleEditWarehouse = () => {
        setModalInfo(selectedWarehouse)
        setShowModal({
            show: true,
            button: "Edit"
        })
    }

    const handleDeleteWarehouse = () => {
        const warehousesCopy = [...warehouses]
        const warehouseIndex = warehousesCopy.findIndex(warehouse => warehouse._id === modalInfo._id) 

        warehousesCopy[warehouseIndex].deletedRegister = true

        const supplierToSave = {
            ...supplier,
            warehouses: warehousesCopy
        }

        handleSaveChanges(supplierToSave)
        
        setShowModal(prev => {
            return {
                ...prev,
                show: false
            }
        })
        setSelectedWarehouse(warehouses.find(wh => !wh.deletedRegister))
    }

    const warehousesExist = warehouses.length > 0
    const checkEveryInput = Object.values(modalInfo).every(item => item)

    return (
        <section className="warehouse-tab">
            {ableToDo && <button className="btnBgBlueTextGradient add d-lg-none" onClick={handleAddWarehouse}>Add warehouse</button>}

            <div className="tabs-container">
                <div className="customTabs">
                    {warehousesExist && warehouses.filter(wh => !wh.deletedRegister).map(warehouse => {
                        const tabId = warehouse._id
                        const tabClass = `customTab ${selectedWarehouse?._id === `${warehouse._id}` ? "selected" : ""}`
                        
                        return (
                            <div id={tabId} className={tabClass} onClick={handleTabClick} key={tabId}>
                                <span>{warehouse.name}</span>
                            </div>
                        )
                    })}
                    
                    {!warehousesExist && 
                        <div className={`customTab selected`}>
                            <span>No warehouses found</span>
                        </div>
                    }
                </div>

                {ableToDo && <button className="btnBgBlueTextGradient add d-none d-lg-flex" onClick={handleAddWarehouse}>Add warehouse</button>}
            </div>

            {selectedWarehouse && <Warehouse
                darkLightMode={darkLightMode}
                supplier={supplier}
                supplierProducts={supplierProducts}
                selectedWarehouse={selectedWarehouse}
                setSelectedWarehouse={setSelectedWarehouse}
                handleEditWarehouse={handleEditWarehouse}
                handleSaveChanges={handleSaveChanges}
            />}

            {!selectedWarehouse && 
                <div className="no-warehouse">
                    <h4>Add new warehouse</h4>
                </div>
            }


            {/* Modal to add warehouse */}
            <Modal className={`document-modal warehouse-modal ${darkLightMode ? "darkMode" : ""}`} centered show={showModal.show} onHide={handleCloseModal} >
                <img className="closeModal" src={close} alt="Catenna Platform" onClick={handleCloseModal}/>
                
                <Modal.Header id="warehouse-header">
                    <Modal.Title>
                        New Warehouse
                    </Modal.Title>
                    {showModal.button === "Edit" && <img src={trash} alt="Prodensa Supplier" id="delete-warehouse" onClick={handleDeleteWarehouse}/>}
                </Modal.Header>

                <Modal.Body>
                    <div className="warehouses-modal-inputs">
                        {MODAL_INPUTS.map(modalInput => {
                            return (
                                <div key={modalInput.property} id={`warehouse-input-${modalInput.property}`} className="warehouse-input-container">
                                    <label htmlFor={modalInput.property}>{modalInput.label}</label>
                                    <input 
                                        id={modalInput.property} 
                                        type="text" 
                                        placeholder={modalInput.placeholder} 
                                        value={modalInfo[modalInput.property]}
                                        onChange={handleModalInput}/>
                                </div>
                            )
                        })}
                    </div>

                    <div className="warehouse-modal-actions">
                        <button className="btnBgTextRed" onClick={handleCloseModal}>Cancel</button>
                        <button id="action-warehouse" className="btnBgBlueTextGradient" onClick={() => handleAddNewWarehouse(showModal.button)} disabled={!checkEveryInput}>{showModal.button}</button>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    )
}
