import "../../assets/css/clientsSupplierView/clientInformation.css"

import profileExample from "../../assets/images/profileExample.png"
import back from "../../assets/images/arrowForward.svg"

import axios from "axios"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import { Modal, Tab, Tabs } from "react-bootstrap"
import ClientContractList from "./clientContractList"
import ClientStaff from "./clientStaff"
// import DocumentComplianceNew from "../tabs/documentCompliance/documentCompliance.jsx"
import { addMonthsToDate, isSameMonth } from "../../utilities/utils"
import { useAuth } from "../../context/auth-user"

const URI_CLIENT = process.env.REACT_APP_URI_CLIENT_INFORMATION_SUPPLIER
const URI_SERVER_DATE = process.env.REACT_APP_URI_SERVER_DATE
const URI_SUPPLIER = process.env.REACT_APP_URI_SUPPLIERS

// MODAL TO SHOW ALL THE CLIENT INDUSTRIES
function SupplierIndustriesModalClientInformation(props) {
    const { show, onHide, clientIndustries } = props
    return (
        <Modal
            className="clientIndustriesModalClientInformation"
            size="lg"
            centered
            show={show}
            onHide={onHide}
        >
            <Modal.Body>
                <h4 className="commentTitle">Industries</h4>

                <div className="industriesContainer">
                    {clientIndustries.map((industry, index) => (
                        <span key={index} className="industry">{industry}</span>
                    ))}
                </div>

                <div className="actionButton">
                    <button className="btnBgBlueTextGradient" onClick={onHide}>Close</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default function ClientInformation() {
    // VARIABLE TO KNOW WHOS IS LOGGED
    const { user } = useAuth()
    const userLogged = user?.selectedProfile

    // VARIABLE TO MANAGE THE SUPPLIER INFO TO SAVE
    const [supplierCopyToSave, setSupplierCopyToSave] = useState(null)

    // VARIABLE TO MANAGE THE SELECTED CLIENT GENERAL INFORMATION
    const [clientGeneralInformation, setClientGeneralInformation] = useState(null)

    // VARIABLE TO MANAGE THE SELECTED CLIENT TAB INFORMATION
    const [clientTabsInformation, setClientTabsInformation] = useState(null)

    // VARIABLE TO SHOW THE INDUSTRIES MODAL
    const [showIndustriesModal, setShowIndustriesModal] = useState(false)

    // VARIABLE TO MANAGE SERVER DATE
    const [serverDate, setServerDate] = useState(null);

    // VARIABLE TO MANAGE THE TABS KEY
    const [key, setKey] = useState(0)

    // VARIABLE TO NAVIGATE THROUGHT ROUTES
    const navigate = useNavigate()

    // VARIABLE TO MANAGE THE ID OF THE CLIENT TO CONSULT
    const { id } = useParams()

    useEffect(() => {
        if (!userLogged) return
        
        getServerDate();
        getClientInformation(id, userLogged?.dataID)
    }, [userLogged])

    // FUNCTION TO GET THE CLIENT AND TABS INFORMATION
    const getClientInformation = (clientID, supplierID) => {
        Swal.fire({
            footer: 'Loading, please wait',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })

        axios.post(URI_CLIENT, { clientID, supplierID }).then(response => {
            setSupplierCopyToSave(response.data.supplierCopyToSave)
            setClientGeneralInformation(response.data.generalInformation)
            setClientTabsInformation(response.data.tabsInformation)
            Swal.close()
        }).catch(err => {
            console.log("Error al obtener la informacion del client: " + err)
            Swal.fire({
                icon: 'error',
                text: 'Failed to fetch the client information',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
            })
        })
    }

    // GET THE SERVER DATE
    const getServerDate = () => {
        axios.get(URI_SERVER_DATE).then(response => {
            setServerDate(response.data)
        }).catch(err => {
            console.log("Error al obtener la fecha del servidor" + err)
        })
    }

    const handleSaveChanges = (updatedSupplier) => {
        updatedSupplier.documentCompliance.clients = supplierCopyToSave.documentCompliance.clients
        const index = updatedSupplier.documentCompliance.clients.findIndex(entity => entity.clientID === id)
        updatedSupplier.documentCompliance.clients[index].clientDocuments = updatedSupplier.documentCompliance.genericDocuments
        updatedSupplier.documentCompliance.genericDocuments = supplierCopyToSave.documentCompliance.genericDocuments

        const formData = new FormData();
        const supplierToSave = updatedSupplier || supplierCopyToSave

        formData.append("rfc", supplierToSave.informationCompliance.generalInformation.rfc) //This is specific to recive the rfc to save files by carpets

        supplierToSave.documentCompliance.clients.forEach(client => {
            client.clientDocuments.general.forEach(document => {
                const docUploadAtValue = document.uploadedAt ? (
                    (document.document && document.document.name) ?
                        `${serverDate.month}/${serverDate.day}/${serverDate.year}` :
                        document.document ?
                            document.uploadedAt :
                            "") :
                    document.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`

                // const stringDateToConvert = isSameMonth(docDueDateValue, document.periodicity)

                // const docDueDateValue = document.dueDate ? 
                //                         document.dueDate : 
                //                         (docUploadAtValue ? 
                //                         addMonthsToDate(stringDateToConvert, document.periodicity) : 
                //                         "")

                document.document && document.document.name && formData.append("document", document.document, `${document.yearCreatedAt}-${document.monthCreatedAt}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`)
                document.uploadedAt = docUploadAtValue
                document.document = document.document && (document.document.name ? `${document.yearCreatedAt}-${document.monthCreatedAt}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : document.document)
                // document.dueDate = docDueDateValue

                if (!document.yearCreatedAt || !document.monthCreatedAt || !document.dayCreatedAt) {
                    document.yearCreatedAt = serverDate.year
                    document.monthCreatedAt = serverDate.month
                    document.dayCreatedAt = serverDate.day
                }
            });

            client.clientDocuments.periodic.forEach(document => {
                const docUploadAtValue = document.uploadedAt ? (
                    (document.document && document.document.name) ?
                        `${serverDate.month}/${serverDate.day}/${serverDate.year}` :
                        document.document ?
                            document.uploadedAt :
                            "") :
                    document.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`

                const stringDateToConvert = docUploadAtValue ? isSameMonth(docUploadAtValue, document.monthCreatedAt) : ""

                const docDueDateValue = document.dueDate ?
                    document.dueDate :
                    (docUploadAtValue ?
                        addMonthsToDate(stringDateToConvert, document.periodicity) :
                        "")

                document.document && document.document.name && formData.append("document", document.document, `${document.yearCreatedAt}-${document.monthCreatedAt}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`)
                document.uploadedAt = docUploadAtValue
                document.document = document.document && (document.document.name ? `${document.yearCreatedAt}-${document.monthCreatedAt}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : document.document)
                document.dueDate = docDueDateValue

                if (!document.yearCreatedAt || !document.monthCreatedAt || !document.dayCreatedAt) {
                    document.yearCreatedAt = serverDate.year
                    document.monthCreatedAt = serverDate.month
                    document.dayCreatedAt = serverDate.day
                }
            });
        });

        formData.append("supplier", JSON.stringify(supplierToSave))

        Swal.fire({
            toast: true,
            footer: 'Loading, please wait',
            showConfirmButton: false,
            // allowOutsideClick: false,
            // allowEscapeKey: false,
            position: "bottom-end",
            didOpen: () => {
                Swal.showLoading()
            },
        })

        axios.put(URI_SUPPLIER + userLogged?.dataID, formData).then(response => {
            Swal.fire({
                toast: true,
                icon: 'success',
                text: 'Information saved successfully',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                position: "bottom-end"
            })
            getClientInformation(id, userLogged?.dataID)
        }).catch(err => {
            Swal.fire({
                toast: true,
                icon: 'error',
                text: 'Failed to save information',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                position: "bottom-end"
            })
            console.log("Error al guardar la informacion del supplier: " + err)
        })
    }

    return (
        <section className="clientInformation">
            <div className="header">
                <div className="generalInformation">
                    <div className="headInformation">
                        <img className="goBack" src={back} alt="Prodensa Supplier" onClick={() => navigate(-1)} />
                        <img className="profilePhoto" src={
                            // clientGeneralInformation && clientGeneralInformation.informationCompliance.generalInformation.profilePhoto.document && !clientGeneralInformation.informationCompliance.generalInformation.profilePhoto.document.name
                            // 	? process.env.REACT_APP_URI_UPLOADS + clientGeneralInformation.informationCompliance.generalInformation.rfc + "/" + clientGeneralInformation.informationCompliance.generalInformation.profilePhoto.document
                            // 	: 
                            profileExample
                        } alt="Prodensa Supplier" />
                        <h3>{clientGeneralInformation && clientGeneralInformation.entityName}</h3>
                        <span className={`fw-bold ${clientGeneralInformation && clientGeneralInformation.clientActive ? "success" : "failed"}`}>
                            {clientGeneralInformation && clientGeneralInformation.clientActive ? "Active" : "Disabled"}
                        </span>
                    </div>
                    <div className="industriesInformation">
                        <div className="industriesContainer">
                            {clientGeneralInformation && clientGeneralInformation.clientIndustries.map((industry, index) => (
                                index < 4 && <span key={index}>{industry}</span>
                            ))}
                        </div>
                        {clientGeneralInformation && clientGeneralInformation.clientIndustries.length > 3 && <button className="btnBgBlueTextGradient" onClick={() => setShowIndustriesModal(true)}>
                            See all ({clientGeneralInformation && clientGeneralInformation.clientIndustries.length})
                        </button>}
                    </div>
                    <p className="description">{clientGeneralInformation && clientGeneralInformation.clientNotes}</p>
                </div>

                <div className="contactInformation">
                    <div className="info">
                        <span className="infoTitle">Address</span>
                        <span className="infoText">
                            {clientGeneralInformation && clientGeneralInformation.entityStreetAndNumber}<br />
                            C.P. {clientGeneralInformation && clientGeneralInformation.entityPostalCode}<br />
                            {clientGeneralInformation && clientGeneralInformation.entityMunicipality + " " + clientGeneralInformation.entityState}

                        </span>
                    </div>
                    <div className="info">
                        <span className="infoTitle">Contact information</span>
                        {clientGeneralInformation && clientGeneralInformation.clientContacts.map((contact, index) => (
                            <span key={index} className="infoText">
                                {contact.contactName}<br />
                                {contact.contactEmail}<br />
                                {contact.contactPhone}
                            </span>
                        ))}
                    </div>
                </div>
            </div>

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
            >
                <Tab eventKey={0} title="1 Contracts">
                    {clientTabsInformation && <ClientContractList
                        supplierRFC={clientTabsInformation.supplierRFC}
                        clientContractList={clientTabsInformation.clientContractList}
                    />}
                </Tab>
                {/* <Tab eventKey={1} title={`2 Specific document compliance per client (50%)`}>
                    {clientTabsInformation && <DocumentComplianceNew
                        documentCompliance={{
                            genericDocuments: clientTabsInformation.clientSpecificDocuments,
                            clients: []
                        }}
                        supplier={supplierCopyToSave}
                        setSupplier={setSupplierCopyToSave}
                        supplierID={supplierCopyToSave._id}
                        serverDate={serverDate}
                        typePerson={supplierCopyToSave.informationCompliance.generalInformation.typePerson}
                        rfc={supplierCopyToSave.informationCompliance.generalInformation.rfc}
                        companyName={supplierCopyToSave.informationCompliance.generalInformation.companyName}
                        handleSaveChanges={handleSaveChanges}
                        asClient
                    />}
                </Tab> */}
            </Tabs>

            {clientGeneralInformation && <SupplierIndustriesModalClientInformation
                clientIndustries={clientGeneralInformation.clientIndustries}
                show={showIndustriesModal}
                onHide={() => setShowIndustriesModal(false)}
            />}
        </section>
    )
}