import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2"

import checkIcon from "../../assets/images/status.svg"
import sortIcon from "../../assets/images/sort.svg"

export const FILTERS = [
    {
        id: uuidv4(),
        title: "Status",
        accessKey: "status",
        options: ["Active", "Inactive"],
        icon: checkIcon
    },
    {
        id: uuidv4(),
        title: "Active purchasing",
        accessKey: "activePurchasing",
        options: ["Active", "Inactive"],
        icon: checkIcon
    }
]

export const TABLE_HEADER_TITLES = [
    {
        id: uuidv4(),
        title: "Username",
        accessKey: "username",
        icon: sortIcon
    },
    {
        id: uuidv4(),
        title: "Role",
        accessKey: "rol",
        icon: sortIcon
    },
    // {
    //     id: uuidv4(),
    //     title: "Active purchasing",
    //     accessKey: "activePurchasing",
    //     icon: sortIcon
    // },
    {
        id: uuidv4(),
        title: "Status",
        accessKey: "status",
        icon: sortIcon
    },
    {
        id: uuidv4(),
        title: "Created at",
        accessKey: "createdAt",
        icon: sortIcon
    },
]

export const PROPERTIES = {
    username: "username",
    rol: "rol",
    status: "disabled",
    activePurchasing: "activePurchasing",
    createdAt: "createdAt"
}

function checkForProperties(user, activeFilter) {
    if (activeFilter === "status") {
        return user[PROPERTIES[activeFilter]] ? "Inactive" : "Active"
    }
    
    if (activeFilter === "activePurchasing") {
        return user[PROPERTIES[activeFilter]] ? "Active" : "Inactive"
    }

    return user[PROPERTIES[activeFilter]]
}

export const ACTIONS = {
    setUsers: "SET_USERS",
    setSearch: "SET_SEARCH",
    handleSort: "HANDLE_SORT",
    handleSortMobile: "HANDLE_SORT_MOBILE",
    setFilterOpen: "SET_FILTER_OPEN",
    setFilterOptions: "SET_FILTER_OPTIONS",
    setActiveFilter:"SET_ACTIVE_FILTER",
    setClearFilter: "SET_CLEAR_FILTER",
    setOptions: "SET_OPTIONS",
    changeSliderProperty: "SET_SLIDER_PROPERTY",
    deleteUser: "DELETE_USER",
    addNewUser: "ADD_NEW_USER",
    handleCurrentPage: "HANDLE_CURRENT_PAGE"
}

export function reducer(state, action) {
    switch (action?.type) {
        case ACTIONS.setUsers:
            
            return {
                ...state,
                users: action?.payload,
                usersCopy: action?.payload
            }

        case ACTIONS.setSearch:
            const searchValue = action?.payload ?? ""

            return {
                ...state,
                currentPage: 1,
                search: searchValue
            }

        case ACTIONS.handleSortMobile:

            return {
                ...state,
                openMobileSort: action?.payload ?? !state?.openMobileSort
            }

        case ACTIONS.handleSort:
            const sortBy = action?.payload
            const sortDirection = state?.sortBy !== sortBy ? "ASC" : state?.sortOrder === "ASC" ? "DSC" : "ASC"

            if (sortBy === PROPERTIES.createdAt || sortBy === PROPERTIES.status || sortBy === PROPERTIES.activePurchasing) {
                const usersSorted = state.usersCopy.sort((a, b) => {
                    const da = sortBy === PROPERTIES.createdAt ? new Date(a.createdAt) : a[sortBy] ?? false
                    const db = sortBy === PROPERTIES.createdAt ? new Date(b.createdAt) : b[sortBy] ?? false

                    return sortDirection === "ASC" ? da - db : db - da
                })

                return {
                    ...state,
                    sortOrder: sortDirection,
                    sortBy,
                    usersCopy: usersSorted
                }
            }

            const usersSorted = state.usersCopy.sort((a, b) => {
                const xa = a[sortBy]?.toLowerCase()
                const xb = b[sortBy]?.toLowerCase()
                
                return sortDirection === "ASC" ? xa?.localeCompare(xb) : xb?.localeCompare(xa)
            })

            return {
                ...state,
                sortOrder: sortDirection,
                sortBy,
                usersCopy: usersSorted
            }

        case ACTIONS.setOptions:
            const optionsToSave = state?.optionsOpen === action?.payload ? null : action?.payload

            return {
                ...state,
                optionsOpen: optionsToSave
            }

        case ACTIONS.changeSliderProperty:
            const { userId, userUpdated } = action.payload

            const newOriginal = state?.users.map(user => user._id === userId ? userUpdated : user)
            const newCopy = state?.usersCopy.map(user => user._id === userId ? userUpdated : user)

            return {
                ...state,
                users: newOriginal,
                usersCopy: newCopy
            }
        
        case ACTIONS.setFilterOpen:
            const filterID = action.payload
            const newFilterOpenValue = state?.openFilter === filterID ? null : filterID

            return {
                ...state,
                openFilter: newFilterOpenValue
            }

        case ACTIONS.setFilterOptions:
            const prevFilterOptions = { ...state.filterOptions}
            const filterProperty = action?.payload.property
            const optionValue = action?.payload.value

            const updatedOptions = prevFilterOptions[filterProperty].includes(optionValue) ?
                                    prevFilterOptions[filterProperty].filter(item => item !== optionValue) :
                                    [...prevFilterOptions[filterProperty], optionValue]

            return {
                ...state,
                filterOptions: {
                    ...prevFilterOptions,
                    [filterProperty]: updatedOptions
                }
            }

        case ACTIONS.setActiveFilter:
            const filterToApply = action?.payload

            if (state.filterOptions?.[filterToApply].length === 0) {
                return {
                    ...state,
                    openFilter: null
                }
            }
            
            const updatedActiveFilters = state.activeFilters.includes(filterToApply) ?
                                        state.activeFilters :
                                        [...state.activeFilters, filterToApply]

            const filteredUsers = state?.users?.filter(user => {
                return updatedActiveFilters.every(activeFilter => {
                    return state.filterOptions?.[activeFilter].includes(checkForProperties(user, activeFilter))
                })
            })
                                        
            return {
                ...state,
                openFilter: null,
                currentPage: 1,
                activeFilters: updatedActiveFilters,
                usersCopy: filteredUsers
            }

        case ACTIONS.setClearFilter:
            const filterToClear = action?.payload
            const newClearActiveFilters = state?.activeFilters.filter(item => item !== filterToClear) 

            const newClearFilteredUsers = state?.users.filter(user => {
                return newClearActiveFilters.every(activeFilter => {
                    return state?.filterOptions?.[activeFilter].includes(checkForProperties(user, activeFilter))
                })
            })

            return {
                ...state,
                openFilter: null,
                currentPage: 1,
                filterOptions: {
                    ...state.filterOptions,
                    [filterToClear]: []
                },
                activeFilters: newClearActiveFilters,
                usersCopy: newClearFilteredUsers
            }
        
        case ACTIONS.deleteUser:
            const userDeletedId = action.payload

            return {
                ...state,
                users: state.users.filter(user => user._id !== userDeletedId),
                usersCopy: state.usersCopy.filter(user => user._id !== userDeletedId)
            }

        case ACTIONS.addNewUser:
            const newUserAdded = action.payload
            const newUsers = [...state.users, newUserAdded]

            if (state?.activeFilters.length > 0) {
                const filteredUsers = newUsers.filter(user => {
                    return state?.activeFilters.every(activeFilter => {
                        return state?.filterOptions?.[activeFilter].includes(checkForProperties(user, activeFilter))
                    })
                })

                return {
                    ...state,
                    users: newUsers,
                    usersCopy: filteredUsers
                }
            }
            
            if (state?.search) {
                const filteredUsers = newUsers.filter(user => user.username.toLocaleLowerCase().includes(searchValue))

                return {
                    ...state,
                    users: newUsers,
                    usersCopy: filteredUsers
                }
            }

            return {
                ...state,
                users: newUsers,
                usersCopy: [...state?.usersCopy, newUserAdded]
            }
            
        case ACTIONS.handleCurrentPage:
            const currentPage = action?.payload

            return {
                ...state,
                currentPage
            }
        default:
            return state
    }
}

export function loadingModal() {
    Swal.fire({
        footer: 'Loading, please wait',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
            Swal.showLoading()
        },
    })
}