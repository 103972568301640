import "../../assets/css/suppliers/suppliers.css"

import client from "../../assets/images/clients.svg"
import industry from "../../assets/images/industries.svg"
import rating from "../../assets/images/rating.svg"
import status from "../../assets/images/status.svg"
import location from "../../assets/images/location.svg"
import certifications from "../../assets/images/certification.svg"
import sortIcon from "../../assets/images/sort.svg"
import printReport from "../../assets/images/printReport.svg"
import starRating from "../../assets/images/starRating.svg"
import viewProfile from "../../assets/images/viewProfile.svg"
import next from "../../assets/images/arrowForward.svg"
import cvpBadge from "../../assets/images/CVPBagdeTable.png"

import close from "../../assets/images/close.svg"

import { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import axios from "axios"
import Swal from "sweetalert2"
import { useAuth } from "../../context/auth-user"

const URI = process.env.REACT_APP_URI_SUPPLIERS_FILTER_BY_STATUS_ARRAY
const URI_CLIENT = process.env.REACT_APP_URI_CLIENT
const URI_REPORT_SUPPLIER_DIRECTORY = process.env.REACT_APP_REPORT_SUPPLIER_DIRECTORY
const URI_UPLOADS = process.env.REACT_APP_URI_UPLOADS

export default function Suppliers({ darkLightMode }) {
    const { user } = useAuth()
    const userLogged = user?.selectedProfile

    // VARIABLE TO CONTROL DATA FROM TABLE
    const [dataTable, setDataTable] = useState([])
    const [dataTableCopy, setDataTableCopy] = useState([])

    const [currentPage, setCurrentPage] = useState(sessionStorage.getItem("currentPageSupplier") ?? 1)

    const totalPages = Math.ceil(dataTableCopy.length / 20);
    const indexOfLastItem = +currentPage * 20;
    const indexOfFirstItem = indexOfLastItem - 20;
    const currentItems = dataTableCopy.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => {
        sessionStorage.setItem("currentPageSupplier", pageNumber)
        setCurrentPage(pageNumber);
    };

    const [searchValue, setSearchValue] = useState(() => {
        const stored = localStorage.getItem("suppDirFilters")

        return JSON.parse(stored)?.searchValue ?? ""
    })

    const [industrySearch, setIndustrySearch] = useState("")
    const [locationSearch, setLocationSearch] = useState("")

    const [openFilter, setOpenFilter] = useState(null)

    // VARIABLE TO CONTROL THE CLIENTS FROM THE DATABASE
    const [clients, setClients] = useState(null)

    // VARAIBLE TO CONTROL THE SORT ORDER
    const [order, setOrder] = useState(true)

    // VARIABLES TO CONTROL SORT MENU SHOW AND HIDE
    const [showSortMenu, setShowSortMenu] = useState(false)

    // VARIABLE TO MANAGE FILTER AND MOBILE SORT MENU
    const sortMenuRef = useRef(null)
    const filterRef = useRef(null)

    // VARIABLE TO MANAGE REF FROM THE FIRST TIME RENDER THE COMPONENT FOR INDUSTRY FILTER
    const firstTimeRenderRef = useRef(true)

    // VARIABLE TO CONTROL THE LIST OF LOCATIONS
    const [locationList, setLocationList] = useState([])

    // VARIABLES TO FILTER DATA
    const [clientFilter, setClientFilter] = useState(() => {
        const stored = localStorage.getItem("suppDirFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.client ?? []
    })
    const [entityFilter, setEntityFilter] = useState(() => {
        const stored = localStorage.getItem("suppDirFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.entity ?? []
    })
    const [industryFilter, setIndustryFilter] = useState(() => {
        const stored = localStorage.getItem("suppDirFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.industry ?? []
    })
    const [ratingFilter, setRatingFilter] = useState(() => {
        const stored = localStorage.getItem("suppDirFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.rating ?? 0
    })
    const [statusFilter, setStatusFilter] = useState(() => {
        const stored = localStorage.getItem("suppDirFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.status ?? []
    })
    const [locationFilter, setLocationFilter] = useState(() => {
        const stored = localStorage.getItem("suppDirFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.location ?? []
    })
    const [certificationsFilter, setCertificationsFilter] = useState(() => {
        const stored = localStorage.getItem("suppDirFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.certification ?? []
    })
    const [cvpFilter, setCvpFilter] = useState(() => {
        const stored = localStorage.getItem("suppDirFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.cvp ?? false
    })

    // VARIABLES TO KNOW IF THE FILTER IS ON
    const [clientIsFiltered, setClientIsFiltered] = useState(() => {
        const stored = localStorage.getItem("suppDirFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.entity ? true : false
    })
    const [entityIsFiltered, setEntityIsFiltered] = useState(() => {
        const stored = localStorage.getItem("suppDirFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.entity ? true : false
    })
    const [industryIsFiltered, setIndustryIsFiltered] = useState(() => {
        const stored = localStorage.getItem("suppDirFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.industry ? true : false
    })
    const [ratingIsFiltered, setRatingIsFiltered] = useState(() => {
        const stored = localStorage.getItem("suppDirFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.rating ? true : false
    })
    const [statusIsFiltered, setStatusIsFiltered] = useState(() => {
        const stored = localStorage.getItem("suppDirFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.status ? true : false
    })
    const [locationIsFiltered, setLocationIsFiltered] = useState(() => {
        const stored = localStorage.getItem("suppDirFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.location ? true : false
    })
    const [certificationsIsFiltered, setCertificationsIsFiltered] = useState(() => {
        const stored = localStorage.getItem("suppDirFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.certification ? true : false
    })
    const [cvpIsFiltered, setCvpIsFiltered] = useState(() => {
        const stored = localStorage.getItem("suppDirFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.cvp ? true : false
    })

    //VARIABLE TO CONTROL THE FILTER DATA AFTER CLEAR A SINGLE FILTER
    const [hardFilterData, setHardFilterReset] = useState(false)

    //VARIABLE TO SHOW RESET ALL FILTERS
    const [resetAll, setResetAll] = useState(false)

    // VARIABLE TO NAVIGATE TO OTHER COMPONENT
    const navigate = useNavigate();

    const locationUrl = useLocation();
    const searchParams = new URLSearchParams(locationUrl.search);
    const viewIndustry = searchParams.get("viewIndustry");
    const viewClient = searchParams.get("viewClient");

    const mainStyle = {
		background: darkLightMode ? "#131E29" : "white",
		color: darkLightMode ? "white" : "inherit"
	}

    // useEffect to add body listener
    useEffect(() => {
        if(!userLogged) return 
        
        getAllAuthorizedSupplier()
        getAllClients()

        window.onbeforeunload = clearSessionStorage;

        document.body.addEventListener("mousedown", closeMenusFilters)

        return function cleanUp() {
            window.onbeforeunload = null; // Cleanup event listener
            document.body.removeEventListener("mousedown", closeMenusFilters)
        }
    }, [userLogged])

    // COMPARATION TO KNOW IF WE ARRIVE FROM INDUSTRIES AND FILTER BY INDUSTRY OR EDIT CLIENT
    useEffect(() => {
        if (viewIndustry && !firstTimeRenderRef.current) {
            industryFilter.push(viewIndustry)
            setIndustryFilter(industryFilter)
            setHardFilterReset(true)
        } else {
            firstTimeRenderRef.current = false
        }

        if (viewClient && !firstTimeRenderRef.current) {
            clientFilter.push(viewClient)
            setClientFilter(clientFilter)
            setHardFilterReset(true)
        } else {
            firstTimeRenderRef.current = false
        }
    }, [dataTable])

    useEffect(() => {
        const savedFilters = Object.values(JSON.parse(localStorage.getItem("suppDirFilters")) ?? {}).filter(item => !(typeof item === "string")).length
        const savedSearchValue = JSON.parse(localStorage.getItem("suppDirFilters") ?? {})?.searchValue ?? ""

        if (dataTable.length > 0 && savedFilters > 0) {
            filterData()
        }

        if (dataTable && savedSearchValue) {
            searchFilter(savedSearchValue)
        }
    }, [dataTable])

    // COMPARATION TO SHOW THE RESET ALL BUTTON
    useEffect(() => {
        if (clientIsFiltered || entityIsFiltered || industryIsFiltered || ratingIsFiltered || statusIsFiltered || locationIsFiltered || certificationsIsFiltered || cvpIsFiltered) {
            setResetAll(true)
        } else {
            setResetAll(false)
        }
    }, [clientIsFiltered, entityIsFiltered, industryIsFiltered, ratingIsFiltered, statusIsFiltered, locationIsFiltered, certificationsIsFiltered, cvpIsFiltered])

    // ACTION TO EXECUTE WHEN YOU CLEAR A SINGLE FILTER TO RESET THE TABLE
    useEffect(() => {
        if (hardFilterData) {
            filterData()
            setHardFilterReset(false)
        }
    }, [hardFilterData])

    // CLEAR SESSION STORAGE ON PAGE REFRESH
    const clearSessionStorage = () => {
        sessionStorage.removeItem('currentPageSupplier');
    };

    // FUNCTION TO CLOSE MENU FILTERS
    const closeMenusFilters = (e) => {
        if (!filterRef?.current?.contains(e.target)) {
            setOpenFilter(null)
        }

        if (!sortMenuRef?.current?.contains(e.target)) {
            setShowSortMenu(false)
        }
    }

    const handleOpenFilter = (e, filterName) => {
        filterRef.current = e.target

        setOpenFilter(prev => {
            if (prev === filterName) return null

            return filterName
        })
    }

    // FUNCTION TO SORT TABLE COLUMN
    const sortTable = (order, sortBy) => {
        switch (sortBy) {
            case "Name":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.companyName.toLocaleLowerCase();
                    let fb = b.companyName.toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))

                setOrder(!order)
                break

            case "Industry":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.industries[0].toLocaleLowerCase();
                    let fb = b.industries[0].toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))
                setOrder(!order)
                break

            case "Rating":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    if (!order) {
                        return a.rating.$numberDecimal - b.rating.$numberDecimal
                    } else {
                        return b.rating.$numberDecimal - a.rating.$numberDecimal
                    }
                }))
                setOrder(!order)
                break

            case "Location":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.location.toLocaleLowerCase();
                    let fb = b.location.toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))
                setOrder(!order)
                break

            case "Status":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.entityStatus.toLocaleLowerCase();
                    let fb = b.entityStatus.toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))

                setOrder(!order)
                break

            case "Documentation":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    if (!order) {
                        return b.compliant - a.compliant
                    } else {
                        return a.compliant - b.compliant
                    }
                }))

                setOrder(!order)
                break

            default:
                break;
        }
    }

    // FUNCTIONS TO ADD FILTERS
    const toggleFilterValue = (setFilter) => (target) => {
        setFilter(prev => {
            const existValue = prev.some(valueToFind => valueToFind === target.value);
    
            if (existValue) {
                return prev.filter(valueToFind => valueToFind !== target.value);
            }
    
            return [...prev, target.value];
        });
    };

    const handleRating = (value) => {
        filterData(value)
    }
    
    const handleCvp = (value) => {
        setCvpFilter(value)
        setCvpIsFiltered(value)
        filterData(null, value)
    }

    // FUNCTION TO GET ALL AUTHORIZED SUPPLIER
    const getAllAuthorizedSupplier = () => {
        Swal.fire({
            footer: 'Loading, please wait',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })

        axios.post(URI, {
            status: ["Authorized", "Rejected", "Active", "Hold payment"],
            rol: userLogged && userLogged.rol,
            clientID: userLogged && userLogged.dataID
        }).then(response => {
            setDataTable(response.data)
            setDataTableCopy(response.data)
            createLocationFilter(response.data)
            Swal.close()
        }).catch(err => {
            Swal.close()
            console.log("Error al obtener todas las nuevas solicitudes" + err)
        })
    }

    // FUNCTION TO GET ALL CLIENTS
    const getAllClients = () => {
        axios.get(URI_CLIENT).then(response => {
            setClients(response.data)
        }).catch(err => {
            console.log("Error al obtener los clientes")
        })
    }

    // FUNTION TO GET THE TABLE REPORT
    const getTableReport = () => {
        Swal.fire({
            footer: 'Loading, please wait',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })

        axios.post(URI_REPORT_SUPPLIER_DIRECTORY, {
            dataTable: dataTableCopy,
            filters: {
                clientFilter: clientFilter,
                entityFilter: entityFilter,
                industryFilter: industryFilter,
                ratingFilter: ratingFilter,
                statusFilter: statusFilter,
                locationFilter: locationFilter,
                certificationsFilter: certificationsFilter,
            }
        }).then(response => {
            Swal.fire({
                icon: 'success',
                text: `${response.data.message}`,
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
            })
            window.open(URI_UPLOADS + response.data.path, "_blank")
        }).catch(err => {
            Swal.fire({
                icon: 'error',
                text: 'Failed to generate the report',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
            })
        })
    }

    // FUNCTION TO FILTER THE SUPPLIER DIRECTORY BY INPUT SEARCH
    const searchFilter = (value) => {
        setSearchValue(value)

        // SAVE VALUE IN LOCAL STORAGE
        const searchValueToLocal = {
            searchValue: value
        }

        localStorage.setItem("suppDirFilters", JSON.stringify(searchValueToLocal))

        setCurrentPage(1)
        let dataFiltered = []
        dataFiltered = dataTable.filter(prevState => {
            const metCondition = prevState.companyName.toLocaleLowerCase().includes(value.toLocaleLowerCase()) || 
            prevState.description.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
            prevState.contactInformation?.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
            prevState.rfc.toLocaleLowerCase().includes(value.toLocaleLowerCase())

            if (metCondition) {
                return prevState
            }

        })

        setDataTableCopy(dataFiltered)
    }

    // FUNCTION TO FILTER THE SUPPLIER DIRECTORY BY FILTER BUTTONS
    const filterData = (ratingValue, cvpValue) => {
        const saveFilters = {}
        
        let dataFiltered = JSON.parse(JSON.stringify(dataTable));

        if (clients && clientFilter.length > 0) {
            const adminClientEntitiesID = clients.filter(client => clientFilter.includes(client._id)).flatMap(client => client.entities.map(entity => entity._id))

            dataFiltered = dataFiltered.filter(supplier => {
                return adminClientEntitiesID.includes(supplier.entityID)
            })

            setClientIsFiltered(true)
            saveFilters.client = clientFilter
        }

        if (entityFilter.length > 0) {
            dataFiltered = dataFiltered.filter(supplier => {
                return entityFilter.includes(supplier.entityID)
            })

            setEntityIsFiltered(true)
            saveFilters.entity = entityFilter
        }

        if (industryFilter.length > 0) {
            dataFiltered = dataFiltered.filter(supplier => {
                return supplier.industries.some(category => {
                    return industryFilter.includes(category)
                })
            })

            setIndustryIsFiltered(true)
            saveFilters.industry = industryFilter
        }
        
        if (ratingValue || ratingFilter) {
            dataFiltered = dataFiltered.filter(supplier => {
                return supplier.rating?.$numberDecimal > ratingFilter
            })

            setRatingFilter(prev => {
                if (ratingValue) return ratingValue

                return prev
            })
            setRatingIsFiltered(true)
            saveFilters.rating = ratingValue || ratingFilter
        }

        if (statusFilter.length > 0) {
            dataFiltered = dataFiltered.filter(supplier => {
                return statusFilter.includes(supplier.entityStatus)
            })

            setStatusIsFiltered(true)
            saveFilters.status = statusFilter
        }

        if (locationFilter.length > 0) {
            dataFiltered = dataFiltered.filter(supplier => {
                return locationFilter.includes(supplier.location)
            })

            setLocationIsFiltered(true)
            saveFilters.location = locationFilter
        }

        if (certificationsFilter.length > 0) {
            dataFiltered = dataFiltered.filter(supplier => {
                return supplier.certifications.some(certification => {
                    return certificationsFilter.includes(certification)
                })
            })

            setCertificationsIsFiltered(true)
            saveFilters.certification = certificationsFilter
        }

        if (cvpValue || (cvpValue === undefined && cvpFilter)) {
            dataFiltered = dataFiltered.filter(supplier => {
                return supplier?.certified
            })

            saveFilters.cvp = cvpValue || cvpFilter
        }

        setSearchValue("")
        setDataTableCopy(dataFiltered)
        localStorage.setItem("suppDirFilters", JSON.stringify(saveFilters))
    }

    // FUNCTION TO CLEAR THE SUPPLIER DIRECTORY BY FILTER BUTTONS AND RESET ALL ON DEFAULT CASE
    const clearFilter = (filter) => {
        setSearchValue("")
        switch (filter) {
            case "client":
                setClientFilter([])
                setClientIsFiltered(false)
                break;

            case "entity":
                setEntityFilter([])
                setEntityIsFiltered(false)
                break;

            case "industry":
                setIndustryFilter([])
                setIndustryIsFiltered(false)
                break;

            case "rating":
                setRatingFilter(0)
                setRatingIsFiltered(false)
                break;

            case "status":
                setStatusFilter([])
                setStatusIsFiltered(false)
                break;

            case "location":
                setLocationFilter([])
                setLocationIsFiltered(false)
                break;

            case "certifications":
                setCertificationsFilter([])
                setCertificationsIsFiltered(false)
                break;

            default:
                clearFilter("client")
                clearFilter("entity")
                clearFilter("industry")
                clearFilter("rating")
                clearFilter("status")
                clearFilter("location")
                clearFilter("certifications")
                setCvpFilter(false)
                setCvpIsFiltered(false)
                break;
        }

        setHardFilterReset(true)
    }

    // FUNCTION TO CREATE THE ENTITY LIST TO SHOW ON FILTER BUTTON
    const createEntityFilter = () => {
        let filterEntity

        switch (userLogged?.rol) {
            case "Master":
            case "Super admin":
                filterEntity = new Set(clients.filter(client => {
                    return clientFilter.includes(client._id)
                }).flatMap(client => client.entities.map(entity => entity)))

                return Array.from(filterEntity)

            case "Admin client":
            case "Admin Compliance manager":
                filterEntity = new Set(clients.filter(client => {
                    return userLogged.dataID === client._id
                }).flatMap(client => client.entities.map(entity => entity)))

                return Array.from(filterEntity)

            default:
                break;
        }
    }

    // FUNCTION TO CREATE THE INDUSTRY LIST TO SHOW ON FILTER BUTTON
    const createIndustryFilter = () => {
        const filterIndustry = new Set(dataTable.flatMap(supplier => {
            return supplier.industries.map(category => category)
        }))

        return Array.from(filterIndustry).sort().filter(item => 
            !industrySearch || item.toLocaleLowerCase().includes(industrySearch.toLocaleLowerCase())
        )
    }

    // FUNCTION TO CREATE THE STATUS LIST TO SHOW ON FILTER BUTTON
    const createStatusFilter = () => {
        let filterStatus = []

        filterStatus = new Set(dataTable.flatMap(supplier => {
            return supplier.entityStatus
        }))

        return Array.from(filterStatus)
    }

    // FUNCTION TO CREATE THE LOCATION LIST TO SHOW ON FILTER BUTTON
    const createLocationFilter = (data) => {
        const filterLocation = new Set(data.map(supplier => {
            return supplier.location
        }))

        const locationList = Array.from(filterLocation).sort((a, b) => {
            if (a.toLocaleLowerCase() > b.toLocaleLowerCase()) {
                return 1
            }

            if (a.toLocaleLowerCase() < b.toLocaleLowerCase()) {
                return -1
            }

            return 0
        })

        setLocationList(locationList)
    }

    // FUNCTION TO CREATE THE CERTIFICATION LIST TO SHOW ON FILTER BUTTON
    const createCertificationFilter = () => {
        const filterCertification = new Set(dataTable.flatMap(supplier => {
            return supplier.certifications.map(certification => certification)
        }))

        return Array.from(filterCertification)
    }

    return (
        <section className={`supplier ${darkLightMode ? "darkMode" : null}`}>
            <div className="titleAndSearch">
                <h3 className="title">Supplier Directory</h3>
                <div className="searchAndSort">
                    <div className={`searchContainer ${darkLightMode ? "darkMode" : null}`}>
                        <input id="generalSearch" type="text" placeholder="Search by name..."
                            onFocus={() => clearFilter()} value={searchValue} onChange={(e) => searchFilter(e.target.value)} />
                    </div>
                    <button ref={sortMenuRef} className="d-lg-none sortMobile" onClick={() => setShowSortMenu(true)}>Sort</button>
                    <div className={`d-lg-none sortDataContainer ${showSortMenu ? "show" : ""}`}>
                        <h5 className="title">Sort</h5>
                        <span onClick={() => { sortTable(false, "Name") }}>Name (ascending)</span>
                        <span onClick={() => { sortTable(true, "Name") }}>Name (descending)</span>
                        <span onClick={() => { sortTable(false, "Industry") }}>Industry (ascending)</span>
                        <span onClick={() => { sortTable(true, "Industry") }}>Industry (descending)</span>
                        <span onClick={() => { sortTable(false, "Rating") }}>Rating (ascending)</span>
                        <span onClick={() => { sortTable(true, "Rating") }}>Rating (descending)</span>
                        <span onClick={() => { sortTable(false, "Location") }}>Location (ascending)</span>
                        <span onClick={() => { sortTable(true, "Location") }}>Location (descending)</span>
                        <span onClick={() => { sortTable(false, "Status") }}>Status (ascending)</span>
                        <span onClick={() => { sortTable(true, "Status") }}>Status (descending)</span>
                        <span onClick={() => { sortTable(false, "Documentation") }}>Documentation (ascending)</span>
                        <span onClick={() => { sortTable(true, "Documentation") }}>Documentation (descending)</span>
                    </div>
                </div>
            </div>
            <div className="filters">
                {userLogged && (userLogged.rol === "Master" || userLogged.rol === "Super admin") && 
                <div className={`filter ${openFilter === "client" ? "show" : null} ${darkLightMode ? "darkMode" : null} ${clientIsFiltered ? "active" : undefined}`} onClick={(e) => handleOpenFilter(e, "client")}>
                    <img src={client} alt="Prodensa Supplier" />
                    <span>Client</span>
                    <div className={`filterOptions ${openFilter === "client" ? "show" : null}`} onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
                        <img src={close} alt="Prodensa Supplier" onClick={() => setOpenFilter(null)} className="closeImg d-lg-none" />
                        <span className="title">
                            <img className="d-lg-none imgMenu" src={client} alt="Prodensa Supplier" />
                            Client
                        </span>
                        <div className="optionsScroll">
                            {clients && clients.map((client, index) => {
                                if (client.generalInformation.clientName !== "Admin Prodensa") {
                                    return (
                                        <div key={"Client" + index} className="option">
                                            <input checked={clientFilter.includes(client?._id?.toString())} className="clientCheck" value={client._id} type="checkbox" id={`client${index}`} onChange={(e) => toggleFilterValue(setClientFilter)(e.target)} />
                                            <label htmlFor={`client${index}`}>{client.generalInformation.clientName}</label>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div className="actions">
                            <button onClick={() => { clearFilter("client"); clearFilter("entity"); }}>Clear</button>
                            <button onClick={() => { filterData(); }}>Apply</button>
                        </div>
                    </div>
                </div>}
                {(clientIsFiltered || (userLogged && (userLogged.rol === "Admin client" || userLogged.rol === "Admin Compliance manager"))) && 
                <div className={`filter ${openFilter === "entity" ? "show" : null} ${darkLightMode ? "darkMode" : null} ${entityIsFiltered ? "active" : undefined}`} onClick={(e) => handleOpenFilter(e, "entity")}>
                    <img src={client} alt="Prodensa Supplier" />
                    <span>Entity</span>
                    <div className={`filterOptions ${openFilter === "entity" ? "show" : null}`} onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
                        <img src={close} alt="Prodensa Supplier" onClick={() => setOpenFilter(null)} className="closeImg d-lg-none" />
                        <span className="title">
                            <img className="d-lg-none imgMenu" src={client} alt="Prodensa Supplier" />
                            Entity
                        </span>
                        <div className="optionsScroll">
                            {clients && createEntityFilter().map((entity, index) => {
                                return (
                                    <div key={"Entity" + index} className="option">
                                        <input checked={entityFilter.includes(entity?._id?.toString())} className="entityCheck" value={entity._id} type="checkbox" id={`entity${index}`} onChange={(e) => toggleFilterValue(setEntityFilter)(e.target)} />
                                        <label htmlFor={`entity${index}`} >{entity.entityName}</label>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="actions">
                            <button onClick={() => clearFilter("entity")}>Clear</button>
                            <button onClick={() => filterData()}>Apply</button>
                        </div>
                    </div>
                </div>}
                <div className={`filter ${openFilter === "industry" ? "show" : null} ${darkLightMode ? "darkMode" : null} ${industryIsFiltered ? "active" : undefined}`} onClick={(e) => handleOpenFilter(e, "industry")}>
                    <img src={industry} alt="Prodensa Supplier" />
                    <span>Industry</span>
                    <div className={`searchContainer ${openFilter === "industry" ? "show" : null}`} onClick={(e) => e.stopPropagation()}>
                        <input value={industrySearch} type="text" placeholder="Search industries"
                            onChange={(e) => setIndustrySearch(e.target.value)} />
                    </div>
                    <div className={`filterOptions location ${openFilter === "industry" ? "show" : null}`} onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
                        <img src={close} alt="Prodensa Supplier" onClick={() => setOpenFilter(null)} className="closeImg d-lg-none" />
                        <span className="title">
                            <img className="d-lg-none imgMenu" src={industry} alt="Prodensa Supplier" />
                            Industry
                        </span>
                        <div className="optionsScroll">
                            {createIndustryFilter().map((industry, index) => {
                                return (
                                    <div key={"Industry" + index} className="option">
                                        <input checked={industryFilter.includes(industry)} className="industryCheck" value={industry} type="checkbox" id={`industry${index}`} onChange={(e) => toggleFilterValue(setIndustryFilter)(e.target)}/>
                                        <label htmlFor={`industry${index}`} >{industry}</label>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="actions">
                            <button onClick={() => clearFilter("industry")}>Clear</button>
                            <button onClick={() => filterData()}>Apply</button>
                        </div>
                    </div>
                </div>
                <div className={`filter ${openFilter === "rating" ? "show" : null} ${darkLightMode ? "darkMode" : null} ${ratingIsFiltered ? "active" : undefined}`} onClick={(e) => handleOpenFilter(e, "rating")}>
                    <img src={rating} alt="Prodensa Supplier" />
                    <span>Rating</span>
                    <div className={`filterOptions rating ${openFilter === "rating" ? "show" : null}`} onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
                        <img src={close} alt="Prodensa Supplier" onClick={() => setOpenFilter(null)} className="closeImg d-lg-none" />
                        <span className="title">Rating</span>
                        <div className="option">
                            <span className="ratingFilter" onClick={() => handleRating(1)}>+ 1.0</span>
                        </div>
                        <div className="option">
                            <span className="ratingFilter" onClick={() => handleRating(2)}>+ 2.0</span>
                        </div>
                        <div className="option">
                            <span className="ratingFilter" onClick={() => handleRating(3)}>+ 3.0</span>
                        </div>
                        <div className="option">
                            <span className="ratingFilter" onClick={() => handleRating(4)}>+ 4.0</span>
                        </div>
                        <div className="actions">
                            <button className="ratingClear" onClick={() => clearFilter("rating")}>Clear</button>
                        </div>
                    </div>
                </div>
                <div className={`filter ${openFilter === "status" ? "show" : null} ${darkLightMode ? "darkMode" : null} ${statusIsFiltered ? "active" : undefined}`} onClick={(e) => handleOpenFilter(e, "status")}>
                    <img src={status} alt="Prodensa Supplier" />
                    <span>Status</span>
                    <div className={`filterOptions ${openFilter === "status" ? "show" : null}`} onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
                        <img src={close} alt="Prodensa Supplier" onClick={() => setOpenFilter(null)} className="closeImg d-lg-none" />
                        <span className="title">
                            <img className="d-lg-none imgMenu" src={status} alt="Prodensa Supplier" />
                            Status
                        </span>
                        <div className="optionsScroll">
                            {clients && createStatusFilter().map((filter, index) => (
                                <div key={"Status" + index} className="option">
                                    <input checked={statusFilter.includes(filter)} className="statusCheck" value={filter} type="checkbox" id={`status${index}`} onChange={(e) => toggleFilterValue(setStatusFilter)(e.target)} />
                                    <label htmlFor={`status${index}`}>{filter}</label>
                                </div>
                            ))}
                        </div>
                        <div className="actions">
                            <button onClick={() => clearFilter("status")}>Clear</button>
                            <button onClick={() => filterData()}>Apply</button>
                        </div>
                    </div>
                </div>
                <div className={`filter ${openFilter === "location" ? "show" : null} ${darkLightMode ? "darkMode" : null} location ${locationIsFiltered ? "active" : undefined}`} onClick={(e) => handleOpenFilter(e, "location")}>
                    <img src={location} alt="Prodensa Supplier" />
                    <span>Location</span>
                    <div className={`searchContainer ${openFilter === "location" ? "show" : null}`} onClick={(e) => e.stopPropagation()}>
                        <input value={locationSearch} type="text" placeholder="Search cities"
                            onChange={(e) => setLocationSearch(e.target.value)} />
                    </div>
                    <div className={`filterOptions location ${openFilter === "location" ? "show" : null}`} onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
                        <img src={close} alt="Prodensa Supplier" onClick={() => setOpenFilter(null)} className="closeImg d-lg-none" />
                        <span className="title">
                            <img className="d-lg-none imgMenu" src={location} alt="Prodensa Supplier" />
                            Location
                        </span>
                        <div className="optionsScroll">
                            {locationList.filter(item => !locationSearch || item.toLocaleLowerCase().includes(locationSearch.toLocaleLowerCase()))
                            .map((filter, index) => (
                                <div key={"Location" + index} className="option">
                                    <input checked={locationFilter.includes(filter)} className="locationCheck" value={filter} type="checkbox" id={`location${index}`} onChange={(e) => toggleFilterValue(setLocationFilter)(e.target)} />
                                    <label htmlFor={`location${index}`} >{filter}</label>
                                </div>
                            ))}
                        </div>
                        <div className="actions">
                            <button onClick={() => clearFilter("location")}>Clear</button>
                            <button onClick={() => filterData()}>Apply</button>
                        </div>
                    </div>
                </div>
                <div className={`filter ${openFilter === "certifications" ? "show" : null} ${darkLightMode ? "darkMode" : null} ${certificationsIsFiltered ? "active" : undefined}`} onClick={(e) => handleOpenFilter(e, "certifications")}>
                    <img src={certifications} alt="Prodensa Supplier" />
                    <span>Certifications</span>
                    <div className={`filterOptions ${openFilter === "certifications" ? "show" : null}`} onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
                        <img src={close} alt="Prodensa Supplier" onClick={() => setOpenFilter(null)} className="closeImg d-lg-none" />
                        <span className="title">
                            <img className="d-lg-none imgMenu" src={certifications} alt="Prodensa Supplier" />
                            Certifications
                        </span>
                        <div className="optionsScroll">
                            {createCertificationFilter().map((certifications, index) => {
                                return (
                                    <div key={"Certification" + index} className="option">
                                        <input checked={certificationsFilter.includes(certifications)} className="certificationsCheck" value={certifications} type="checkbox" id={`certifications${index}`} onChange={(e) => toggleFilterValue(setCertificationsFilter)(e.target)} />
                                        <label htmlFor={`certifications${index}`} >{certifications}</label>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="actions">
                            <button onClick={() => clearFilter("certifications")}>Clear</button>
                            <button onClick={() => filterData()}>Apply</button>
                        </div>
                    </div>
                </div>

                <div className={`checkboxFilter ${darkLightMode ? "darkMode" : ""}`}>
                    <img src={certifications} alt="Prodensa Supplier" />

                    <label htmlFor="cvpCheckbox">CVP</label>

                    <input type="checkbox" id="cvpCheckbox" checked={cvpFilter} onChange={() => handleCvp(!cvpFilter)} />
                </div>
                <span className={`resetAll ${resetAll ? "show" : ""}`} onClick={() => clearFilter()}>Reset All</span>
            </div>
            <span className={`resetAll ${resetAll ? "show" : ""} d-lg-none`} onClick={() => clearFilter()}>Reset All</span>

            <table className="tableSuppliers">
                <thead>
                    <tr>
                        <th style={mainStyle}>
                            <span>Name</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Name")} />
                        </th>
                        <th style={mainStyle}>
                            <span>Industry</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Industry")} />
                        </th>
                        <th style={mainStyle}>
                            <span>Rating</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Rating")} />
                        </th>
                        <th style={mainStyle}>
                            <span>Location</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Location")} />
                        </th>
                        <th style={mainStyle}>
                            <span>Status</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Status")} />
                        </th>
                        <th style={mainStyle}>
                            <span>Documentation</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Documentation")} />
                            <img className="printReport" src={printReport} alt="Prodensa supplier" onClick={() => getTableReport()} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((supplier, index) => (
                        <tr key={index}>
                            <td>
                                <div className={`infoContainer ${supplier.certified ? "withBadge" : ""}`}>
                                    {supplier.certified && <img className="cvpBadge" src={cvpBadge} alt="Prodensa Cattenna" />}
                                    <span onClick={() => navigate("./supplierProfile/" + supplier.supplierID)}>{supplier.companyName}</span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{supplier.industries.map((category, index) => {
                                        return supplier.industries.length > index + 1 ? category + ", " : category
                                    })}</span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{supplier.rating?.$numberDecimal ?? "Unrated"}</span>
                                    {supplier.rating?.$numberDecimal && <img className="starRating" src={starRating} alt="Prodensa Supplier" />}
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{supplier.location}</span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span className={`${supplier.entityStatus === "Active" ? "success" : ""} ${supplier.entityStatus === "Rejected" ? "failed" : ""} ${supplier.entityStatus === "Hold payment" ? "failedAlone" : ""}`}>
                                        {supplier.entityStatus}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span className={`${supplier.compliant ? "success" : "failed"}`}>
                                        {supplier.compliant ? "Compliant" : "Non-Compliant"}
                                    </span>
                                    <img className="viewProfile" src={viewProfile} alt="Prodensa Supplier" onClick={() => navigate("./supplierProfile/" + supplier.supplierID)} />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="d-lg-none supplierRequests">
                {currentItems.map((supplier, indexParent) => (
                    <div key={indexParent} className="supplierRow" onClick={() => navigate("./supplierProfile/" + supplier.supplierID)}>
                        <div className="supplierHeader">
                            {supplier.certified && <img className="cvpBadge" src={cvpBadge} alt="Prodensa Cattenna" />}
                            <h5 className="supplierName">
                                {supplier.companyName}
                            </h5>
                            <span className="rating">
                                {supplier.rating?.$numberDecimal ?? "Unrated"}
                                {supplier.rating?.$numberDecimal && <img className="starRating" src={starRating} alt="Prodensa Supplier" />}
                            </span>
                        </div>
                        <div className="supplierInformation">
                            <span className="supplierValue">{supplier.location}</span>
                            <span className="supplierTitle">Location</span>
                            <span className={`supplierValue ${supplier.entityStatus === "Active" ? "success" : ""} ${supplier.entityStatus === "Rejected" ? "failed" : ""}`}>
                                {supplier.entityStatus}
                            </span>
                            <span className="supplierTitle">Status</span>
                            <span className="supplierValue">{supplier.industries.map((category, index) => {
                                return supplier.industries.length > index + 1 ? category + ", " : category
                            })}</span>
                            <span className="supplierTitle">Industry</span>
                            <span className={`supplierValue ${supplier.compliant ? "success" : "failed"}`}>
                                {supplier.compliant ? "Compliant" : "Non-Compliant"}
                            </span>
                            <span className="supplierTitle">Documentation</span>
                        </div>
                    </div>
                ))}
            </div>

            <div className="addAndPagination">
                <button className="btnBgBlueTextGradient" style={{ opacity: 0, visibility: "hidden" }}>Add supplier</button>
                <div className="pagination">
                    {Array.from({ length: totalPages }).map((_, index) => (
                        <button key={index} className={`number ${+currentPage === index + 1 ? 'active' : ''}`} onClick={() => paginate(index + 1)}>
                            {index + 1}
                        </button>
                    ))}
                    <img className="nextPage" src={next} alt="Prodensa Supplier" onClick={() => +currentPage < totalPages ? paginate(+currentPage + 1) : undefined} />
                </div>
            </div>
            <span className="tableCount">{currentItems.length < 19 ? currentItems.length : 20 * +currentPage} of {dataTable.length}</span>
        </section >
    )
}