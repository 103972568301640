import { loadingModal } from "../components/userConfig/utils"
import logo from "../assets/images/logo.svg"
import undo from "../assets/images/undo.svg"

import React, { useEffect, useMemo, useState } from "react"
import Axios from "axios"
import Swal from "sweetalert2"
import Slider from "react-slick"
import { useAuth } from "../context/auth-user"
import { useCookies } from "react-cookie"

const {
	REACT_APP_URI_SUPPLIERS: URI_SUPPLIERS,
	REACT_APP_URI_CLIENTS: URI_CLIENT
} = process.env

function ProfileSquare (props) {
    const {
        profile,
        profileName,
        handleMouseUp,
        handleMouseMove,
        handleMouseDown,
    } = props

    const rol = profile.rol
    const logo = profileName ? profileName.split(" ").slice(0, 2).map(word => word[0]).join("").toUpperCase() : "N/A"

    return (
        <div className="profile"
        onMouseUp={() => handleMouseUp(profile)}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        >
            <div className="circle">{logo}</div>

            <div className="profileInfo">
                <h5 className="name">{profileName}</h5>
                <span className="role">{rol}</span>
            </div>
        </div>
    )
}

export default function SelectProfile(props) {
    const {
        selectProfile
    } = props

    const { user, updateUser } = useAuth()

    const slickOptions = {
        dots: true,
        slidesToShow: user?.tempUser?.profiles?.length <= 4 ? 4 : 5,
        swipeToSlide: true,
        arrows: true,
        infinite: user?.tempUser?.profiles?.length > 5,
        adaptiveHeight: false,
        customPaging: () => {
            return (
                <div className="custom-dot">
                </div>
            );
        },
    }


    const [_cookie, _setCookie, removeCookie] = useCookies(["token"])
    const [suppliers, setSuppliers] = useState(null)
    const [clients, setClients] = useState(null)

    const [isDarkMode, setIsDarkMode] = useState(false)

    const [isDragging, setIsDragging] = useState(false);

    const handleMouseDown = () => setIsDragging(false);
    const handleMouseMove = () => {
        if (isDragging) return

        setIsDragging(true);
    } 
    const handleMouseUp = (profile) => {
        if (!isDragging) {
            selectProfile(profile);
        }
    };

    useEffect(() => {
		if (!user?.tempUser) return

        setIsDarkMode(user?.tempUser.darkLightMode)

        if (suppliers && clients) return Swal.close()

		loadingModal()

		const getSuppliers = Axios.get(URI_SUPPLIERS)
		const getClients = Axios.get(URI_CLIENT)

		Axios.all([getSuppliers, getClients]).then(Axios.spread((...responses) => {
			const suppliers = responses[0].data
			const clients = responses[1].data

			setSuppliers(suppliers)
			setClients(clients)

			Swal.close()
		})).catch(errors => {
            console.error(errors)
            Swal.fire({
                icon: 'error',
                text: 'Error getting suppliers and clients',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
            })
        })
	}, [user])

    function handleBackToLogin() {
        updateUser({
            tempUser: null
        })

        removeCookie("token")
    }
    
    const showSelectProfile = useMemo(() => user?.tempUser && suppliers && clients, [user, suppliers, clients])
    const supplierRoles = ["Supplier", "Product manager", "Document manager"]

    return (
        <div className={`${isDarkMode ? "darkMode" : ""} selectProfile ${showSelectProfile ? "show" : ""}`.trim()}>
            <header>
                <h5>Welcome</h5>
                <h3>Please select your profile</h3>
            </header>

            <img className="logo" src={logo} alt="Prodensa Supplier" />

            <div className="profilesContainer">
                <Slider {...slickOptions}>
                    {user?.tempUser?.profiles?.filter(profile => !profile.disabled)?.map(profile => {
                        const rol = profile.rol
                        const dataID = profile.dataID
                        const profileName = supplierRoles.includes(rol) ? suppliers?.find(supplier => supplier._id === dataID)?.informationCompliance?.generalInformation?.companyName ?? "N/A" : clients?.find(client => client._id === dataID)?.generalInformation?.clientName ?? "N/A"
                        profile.profileName = profileName
                        
                        return (
                            <div key={dataID}>
                                <ProfileSquare
                                    profile={profile}
                                    profileName={profileName}
                                    handleMouseDown={handleMouseDown}
                                    handleMouseMove={handleMouseMove}
                                    handleMouseUp={handleMouseUp}
                                />
                            </div>
                        )
                    })}
                </Slider>

                <div className="mobileView">
                    {user?.tempUser?.profiles?.filter(profile => !profile.disabled)?.map(profile => {
                        const rol = profile.rol
                        const dataID = profile.dataID
                        const profileName = supplierRoles.includes(rol) ? suppliers?.find(supplier => supplier._id === dataID)?.informationCompliance?.generalInformation?.companyName ?? "N/A" : clients?.find(client => client._id === dataID)?.generalInformation?.clientName ?? "N/A"
                        
                        return (
                            <ProfileSquare
                                key={dataID}
                                profile={profile}
                                profileName={profileName}
                                handleMouseDown={handleMouseDown}
                                handleMouseMove={handleMouseMove}
                                handleMouseUp={handleMouseUp}
                            />
                        )
                    })}
                </div>
            </div>

            <button className="btnBgBlueTextGradient goBack" onClick={handleBackToLogin}> <img src={undo} alt="Prodensa Supplier" /> Back to login</button>
        </div>
    )
}
