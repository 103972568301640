import "../../assets/css/suppliers/usersTab.css"

import { loadingModal, TABLE_HEADER_TITLES, PROPERTIES } from "../userConfig/utils"
import moreDots from "../../assets/images/moreDots.svg"

import { useEffect, useMemo, useState } from "react"
import moment from "moment"
import Swal from "sweetalert2"
import Axios from "axios"

const {
    REACT_APP_URI_ALL_USERS,
    REACT_APP_URI_RESET_PASSWORD,
} = process.env

export default function UsersTab(props) {
    const { users, setUsers, supplierId } = props

    const [optionsOpen, setOptionsOpen] = useState(null)
    const [sortManagement, setSortManagement] = useState({
        sortBy: PROPERTIES.createdAt,
        sortOrder: "ASC",
    })

    useEffect(() => {
        document.body.addEventListener("click", closeMoreOptions)

        return function cleanUp() {
            document.body.removeEventListener("click", closeMoreOptions)
        }
    }, [])

    const closeMoreOptions = (e) => {
        if (!e.target.classList.contains("moreDots")) {
            setOptionsOpen(null)
        }
    }

    const handleSort = (sortBy) => {
        const sortDirection = sortManagement.sortBy !== sortBy ? "ASC" : sortManagement.sortOrder === "ASC" ? "DSC" : "ASC"

        if (sortBy === PROPERTIES.createdAt || sortBy === PROPERTIES.status || sortBy === PROPERTIES.activePurchasing) {
            setSortManagement({
                sortOrder: sortDirection,
                sortBy,
            })
            
            return setUsers(prev => {
                return prev.sort((a, b) => {
                    const da = sortBy === PROPERTIES.createdAt ? new Date(a.createdAt) : a[sortBy]
                    const db = sortBy === PROPERTIES.createdAt ? new Date(b.createdAt) : b[sortBy]

                    return sortDirection === "ASC" ? da - db : db - da
                })
            })
        }

        setSortManagement({
            sortOrder: sortDirection,
            sortBy,
        })
        
        setUsers(prev => {
            return prev.sort((a, b) => {
                const xa = a[sortBy]?.toLowerCase()
                const xb = b[sortBy]?.toLowerCase()
                
                return sortDirection === "ASC" ? xa?.localeCompare(xb) : xb?.localeCompare(xa)
            })
        })
    }

    const handleSliders = (propertyToChange, userId, newValue, profileId) => {
        loadingModal()

        Axios.patch(REACT_APP_URI_ALL_USERS + userId, { propertyToChange, newValue, profileId }).then(res => {
            const userUpdated = res.data
            
            setUsers(prev => {
                return prev.map(user => {
                    return user._id === userId ? userUpdated : user
                })
            })

            Swal.close()
        }).catch(err => {
            console.log(err.response.data.message || "Error al actualizar el usuario")

            Swal.fire({
				icon: 'error',
				text: 'Something went wrong while updating the user',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
        })
    }
    
    const handleMoreOptions = (id) => {
        setOptionsOpen(prev => {
            return prev === id ? null : id
        })
    }

    const handleResetPassword = (username) => {
        Swal.fire({
			title: "Password reset",
			text: `Are you sure you want to reset ${username} password?`,
			icon: "warning",
			showCancelButton: true,
			reverseButtons: true,
			confirmButtonText: "Yes, reset password",
		}).then((result) => {
			if (result.isConfirmed) {
                loadingModal()
                
                Axios.put(REACT_APP_URI_RESET_PASSWORD, {
                    email: username,
                }).then(response => {
                    if (response.data.founded) {
                        Swal.fire({
                            icon: 'success',
                            text: 'Password successfully reset',
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: "User not found",
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        })
                    }
        
                }).catch(err => {
                    console.log("Error al resetear la contraseña: " + err)
                    Swal.fire({
                        icon: 'error',
                        text: 'Error when resetting password',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    })
                })
			}
		});
        
    }

    const handleDeleteUser = (user, profileId) => {
        const { _id, username } = user
        
        Swal.fire({
			title: "Delete user",
			text: `Are you sure you want to delete ${username} account?`,
			icon: "warning",
			showCancelButton: true,
			reverseButtons: true,
			confirmButtonText: "Yes, delete user",
		}).then((result) => {
            if (result.isConfirmed) {
                loadingModal()

                const deleteProfile = true
                
                Axios.patch(REACT_APP_URI_ALL_USERS + _id, { profileId, deleteProfile }).then(res => {
                    setUsers(prev => {
                        return prev.filter(user => user._id !== _id)
                    })

                    Swal.fire({
                        icon: 'success',
                        text: res.data,
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    })
                }).catch(err => {
                    console.log("Error al borrar el usuario: " + err)

                    Swal.fire({
                        icon: 'error',
                        text: err.data.message || 'Error when deleting user',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    })
                })
            }
        })
    }

    const moreThanOneAdmin = useMemo(() => users?.filter(user => user.profiles?.some(profile => profile.rol === "Supplier"))?.length > 1, [users])

    return (
        <div className="supplierUserTable">
            <div className="tableHeader tableRow">
                {TABLE_HEADER_TITLES.map(headerCol => {
                    if (headerCol.accessKey === "activePurchasing") return null
                    
                    return (
                        <div key={headerCol.id} className="headerCol">
                            <span>{headerCol.title}</span>
                            <img src={headerCol.icon} alt="Prodensa automation" onClick={() => handleSort(PROPERTIES[headerCol.accessKey])} />
                        </div>
                    )
                })}
            </div>

            {users.map(user => {
                const profile = user.profiles?.find(profile => profile.dataID.toString() === supplierId.toString())
                const userRole = profile?.rol

                return (
                    <div key={user._id} className="tableRow">
                        <div id="usernameCol" className="userInfo">
                            <span>{user.username}</span>
                        </div>
            
                        <div id="rolCol" className="userInfo">
                            <span>{userRole}</span>
            
                            <span className="mobileLabel">Role</span>
                        </div>
                        
                        <div id="statusCol" className="userInfo">
                            <label className="switch">
                                <input type="checkbox" checked={!profile?.disabled} 
                                    onChange={() => handleSliders(PROPERTIES.status, user._id, !profile.disabled, profile?._id)}
                                />
                                <span className={`slider round ${!profile?.disabled ? "isChecked" : ""}`}></span>
                            </label>
            
                            <span className="mobileLabel">Status</span>
                        </div>
            
                        <div id="createdAtCol" className="userInfo">
                            <span>{moment(user.createdAt)?.format("DD MMMM YYYY")}</span>
            
                            <span className="mobileLabel">Created at</span>
                        </div>
            
                        <div className="userInfo dots">
                            <img 
                                className="moreDots" 
                                onClick={() => handleMoreOptions(user._id)}
                                src={moreDots} 
                                alt="Prodensa Supplier" 
                            />
            
                            <div className={`moreOptions ${optionsOpen === user._id ? "show" : ""}`}>
                                <span onClick={() => handleResetPassword(user.username)} >
                                    Reset password
                                </span>
            
                                {(userRole !== "Supplier" || moreThanOneAdmin) && <span className="deleteOption" onClick={() => handleDeleteUser(user, profile?._id)} >
                                    Delete
                                </span>}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
